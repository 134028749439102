import React from 'react';
import { MediaAssetInner } from './MediaAsset';

export const BenefitsGrid = (props) => {
  const { benefitItem } = props;
  return (
    <div className="container grid gap-3 lg:gap-6 md:grid-cols-2 ">
      {benefitItem?.map((item, i) => (
        <div key={`benefitItem${i}`} className="bg-darkNavy text-white w-full py-[21px] px-6 lg:px-10">
          <div className="items-center justify-between h-full md:space-x-6 sm:flex md:block lg:flex">
            <MediaAssetInner
              className="w-full max-w-[125px] sm:max-w-[175px] mx-auto sm:mx-0"
              type="image"
              image={item?.image}
            />
            <div className="max-w-[259px] mx-auto sm:mx-0 text-center sm:text-left">
              <div className="t-34 font-space leading-[1.05] mt-4 sm:mt-0 md:mt-2 lg:mt-0">{item?.heading}</div>
              <div className="t-17 font-space mt-2 leading-[1.76] text-strokeGrey">{item?.content}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
