import React, { useEffect } from 'react';
import { MaybeLink, ArrowLink } from '~/components/elements/Link';
import arrowDown from '~/assets/icons/down-arrow.svg';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import axios from 'axios';

export const CareerListings = ({ heading, link }) => {
  const { departments, items } = {
    departments: ['Departments', 'Departments', 'Departments', 'Departments'],
    items: [
      {
        field: 'Business Operation',
        title: 'IT System Administrator',
      },
      {
        field: 'Business Operation',
        title: 'IT System Administrator',
      },
      {
        field: 'Business Operation',
        title: 'IT System Administrator',
      },
      {
        field: 'Business Operation',
        title: 'IT System Administrator',
      },
      {
        field: 'Business Operation',
        title: 'IT System Administrator',
      },
    ],
  };

  /*
  useEffect(() => {
    if (typeof window === 'undefined') return;
    axios
      .get('https://api.lever.co/v1/postings', {
        auth: {
          username: 't9KKrcWfEHTgEaHiXLVk',
        },
      })
      .then(function (res) {
        console.log(res);
      });
  }, []);
  */

  return (
    <div id="roles" className="container">
      <InViewAnimator
        className="space-y-12 md:space-y-20"
        inner={(controls) => (
          <>
            <div className="flex flex-col md:flex-row justify-between md:items-center md:space-x-6">
              <div className="t-42">{heading}</div>
              <div className="relative md:w-[183px] mt-4 md:mt-0">
                <img className="absolute right-1 top-3" src={arrowDown} alt="icon" />
                <select className="appearance-none rounded-[0px] t-17 w-full border-b pb-2.5 border-[#9FA0AD] my-auto appearance-none after:content-['']">
                  {departments.map((department, i) => {
                    return (
                      <option key={i} className="t-17">
                        {department}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div>
              {items.map((item, i) => (
                <InViewAnimatorBasicItem
                  key={`career-item-${i}`}
                  controls={controls}
                  delay={i / 6}
                  duration={1.5}
                  prop="x"
                >
                  <CareerListingsItem item={item} />
                </InViewAnimatorBasicItem>
              ))}
            </div>
          </>
        )}
      />
      <div className="mt-6 md:mt-12">
        <MaybeLink link={link}>
          <ArrowLink link={link} />
        </MaybeLink>
      </div>
    </div>
  );
};

export const CareerListingsItem = ({ item }) => {
  return (
    <MaybeLink link={{ url: '#' }} className="mt-4 md:mt-8">
      <div className="t-20 opacity-80">
        {item.field}
        <span className="md:hidden"> / MosaicHQ</span>
      </div>
      <div className="mt-1 md:mt-[-3px] flex flex-col md:flex-row justify-between md:items-center border-b border-opacity-50 transition-colors duration-500 group-hover:border-opacity-100 border-[#B3B3B3] group-hover:border-black pb-4 md:pb-8">
        <div className="t-24">{item.title}</div>
        <div className="hidden relative lg:top-[-5px] md:flex mt-3 md:mt-0 md:flex-row md:items-center space-x-[41.5px]">
          <div className="mr-auto md:mr-0 t-17">MosaicHQ</div>
          <div className="t-16 bg-primaryText text-white py-3 px-[22px] cursor-pointer group-hover:bg-primaryBlue transition-colors">
            Apply Now
          </div>
        </div>
        <div className="md:hidden pt-2 pb-2">
          <ArrowLink link={{ title: 'Apply Now' }} />
        </div>
      </div>
    </MaybeLink>
  );
};
