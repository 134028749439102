import React from 'react';
import { Quote } from '~/components/elements/Icon';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const Testimonial = ({ selectedTestimonial }) => {
	const { image, content, name, jobTitle, icon } = selectedTestimonial.postTypeTestimonial;
	return (
		<InViewAnimator
			className="testimonial text-center md:text-left container md:flex space-y-6 md:space-y-0 md:space-x-8 max-w-[900px] items-center"
			inner={(controls) => (
				<>
					{image && (
						<div className="max-w-full w-[180px] md:w-[290px] mx-auto md:mx-0 md:w-[32%] md:mr-12">
							<InViewAnimatorBasicItem controls={controls} delay={0} duration={1} prop="x" start={50}>
								<div className="border-default-grid p-4 md:py-7 md:px-10">
									<MediaAssetInner type="image" image={image} />
								</div>
							</InViewAnimatorBasicItem>
						</div>
					)}
					<div className="flex-1 relative">
						<InViewAnimatorBasicItem controls={controls} delay={0.2} duration={1.25} prop="x" start={-50}>
							<div className="absolute hidden md:block top-1.5 left-[-30px]">
								<Quote />
							</div>
							<h2 className={`${image ? 'max-w-[520px] t-24' : 'max-w-[800px] t-36-quote'} font-medium`}>{content}</h2>
							<div className="md:flex items-center space-y-2 md:space-y-0 md:space-x-8 pt-6 md:pt-8">
								<div className="inline-block border-strokeGreyAlt border py-3.5 px-4">
									<MediaAssetInner type="image" image={icon} />
								</div>
								<div>
									<strong className="t-20-heading">{name}</strong>
									<span className="block t-17 opacity-80">{jobTitle}</span>
								</div>
							</div>
						</InViewAnimatorBasicItem>
					</div>
				</>
			)}
		/>
	);
};
