import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export const useInViewStart = () => {
	const { ref, inView } = useInView();
	const [started, set_started] = useState(false);

	useEffect(() => {
		if (started) return;
		if (inView) {
			set_started(true);
		}
	}, [inView, started]);

	return [ref, started];
};
