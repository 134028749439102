import React, { useEffect } from 'react';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { MaybeLink } from '~/components/elements/Link';
import { Logo } from '~/components/elements/Icon';
import demoBackground from '~/assets/icons/demo-background.svg';
import { HubspotForm } from '~/components/elements/Hubspot';

export const RequestDemo = (page) => {
	return (
		<InViewAnimator
			className="overflow-hidden xl:border-l-[5px] border-primaryBlue pt-6 md:pt-7 min-h-[100vh]"
			inner={(controls) => (
				<>
					<MaybeLink link={{ url: '/' }} className="relative header-container lg:left-[-2px]">
						<Logo />
					</MaybeLink>
					<div className="container lg:flex h-full space-y-6 lg:space-y-0 lg:space-x-24">
						<div className="text-center lg:text-left relative lg:w-[58%]">
							<InViewAnimatorBasicItem
								controls={controls}
								delay={0}
								prop="y"
								start={50}
								className="relative mx-auto lg:mx-0 max-w-[575px] pt-10 md:pt-12 pb-6 lg:py-20"
							>
								<RequestDemoMain {...page.demoRequest} />
							</InViewAnimatorBasicItem>
						</div>
						<div className="relative flex-1 flex flex-col">
							<div className="bg-tertiaryGrey absolute top-0 left-[-200%] right-[-200%] bottom-[-200%] lg:left-[-18%] lg:top-[-100px] lg:bottom-[-100px] lg:right-[-500%]"></div>
							<img
								className="hidden lg:block absolute right-[-410px] top-[-460px] max-w-none opacity-60"
								src={demoBackground}
								alt=""
							/>
							<RequestDemoClients controls={controls} {...page.demoRequest.clients} />
						</div>
					</div>
				</>
			)}
		/>
	);
};

export const RequestDemoMain = ({ heading, content }) => {
	return (
		<>
			<h1 className="t-36">{heading}</h1>
			<div className="blue-child-links prose t-20 pt-3 pb-6 lg:pb-10" dangerouslySetInnerHTML={{ __html: content }} />
			<HubspotForm formId="457ee83d-3499-4d6f-982f-20250fb72356" />
		</>
	);
};

export const RequestDemoClients = ({ heading, gallery, image, controls }) => (
	<div className="relative text-center max-w-[410px] mx-auto py-10 md:py-20 mt-auto flex flex-col">
		<InViewAnimatorBasicItem controls={controls} delay={0.3} prop="y" start={50}>
			<h2 className="t-36 max-w-[300px] mx-auto pb-8 lg:pb-16 lg:pt-32">{heading}</h2>
			<div className="grid grid-cols-3 gap-8 lg:gap-5 lg:gap-y-10 pb-12 lg:pb-16">
				{gallery.map((logo, i) => (
					<InViewAnimatorBasicItem
						key={`logo-${i}`}
						controls={controls}
						delay={0.3 + i / 6}
						prop="y"
						start={-50}
						className="flex flex-col justify-center"
						key={i}
					>
						<MediaAssetInner className="mx-auto" type="image" image={logo} />
					</InViewAnimatorBasicItem>
				))}
			</div>
		</InViewAnimatorBasicItem>
		<MediaAssetInner
			className="relative left-[10%] lg:left-[40%] max-w-none w-[110%] lg:w-[140%]"
			type="image"
			image={image}
		/>
	</div>
);
