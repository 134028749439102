import React from 'react';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { MaybeLink, Button, ArrowLink } from '~/components/elements/Link';
import logo from '~/assets/icons/logo-career.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import mastheadBackground from '~/assets/icons/career-masthead-background.svg';

export const MastheadCareers = (props) => {
  return (
    <div>
      <MastheadCareersHeader {...props.mastheadContent} />
      <MastheadCareersImages {...props.imageContent} />
      <MastheadCareersValues {...props.valuesContent} />
    </div>
  );
};

export const MastheadCareersHeader = ({ subheading, heading, content, image, link }) => {
  return (
    <InViewAnimator
      inner={(controls) => (
        <>
          <div className="bg-darkNavy pt-8 lg:pt-24 xl:pt-40 overflow-hidden relative">
            <div className={`container section-header-padding`}>
              <div className="relative pb-[39%]">
                <img
                  className="hidden md:block absolute bottom-[58px] right-[-120px]"
                  src={mastheadBackground}
                  alt=""
                />
                <div className="lg:flex flex-row gap-2.5 lg:gap-[65px]">
                  <InViewAnimatorBasicItem
                    controls={controls}
                    delay={0}
                    prop="y"
                    start={50}
                    className="max-w-[538.75px] lg:min-w-[538.75px]"
                  >
                    <h2 className="t-17 mb-2.5  lg:mb-[18px] text-torqouise font-semibold uppercase">{subheading}</h2>
                    <h1 className="t-54  mb-5 lg:mb-[36px] text-white">{heading}</h1>
                    <MaybeLink link={link} className="max-w-[167px]">
                      <Button link={link} buttonStyle="primary" />
                    </MaybeLink>
                  </InViewAnimatorBasicItem>
                  <InViewAnimatorBasicItem
                    controls={controls}
                    delay={0.3}
                    prop="y"
                    start={25}
                    className="max-w-[588px] mt-7 lg:mt-14"
                  >
                    <div
                      className="t-20 text-white space-y-5 decoration-primaryBlue"
                      dangerouslySetInnerHTML={{ __html: content }}
                    />
                  </InViewAnimatorBasicItem>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <InViewAnimatorBasicItem
              controls={controls}
              delay={0.55}
              prop="y"
              start={50}
              className="relative mt-[-28%] border-dark-grid"
            >
              <MediaAssetInner type="image" image={image} />
            </InViewAnimatorBasicItem>
          </div>
        </>
      )}
    />
  );
};

export const MastheadCareersImages = ({ mediaLeft, mediaRight }) => (
  <InViewAnimator
    className="container mt-5 lg:mt-8"
    inner={(controls) => (
      <div className="flex gap-5 lg:gap-8">
        <InViewAnimatorBasicItem className="w-[45%]" controls={controls} prop="x" start={25} delay={0.2} duration={1.5}>
          <div className="border-dark-grid">
            <MediaAssetInner type="image" image={mediaLeft} />
          </div>
        </InViewAnimatorBasicItem>
        <InViewAnimatorBasicItem className="flex-1" controls={controls} prop="x" start={25} delay={0.5} duration={1.5}>
          <div className="border-dark-grid">
            <MediaAssetInner type="image" image={mediaRight} />
          </div>
        </InViewAnimatorBasicItem>
      </div>
    )}
  />
);

export const MastheadCareersValues = ({ heading, image, link }) => {
  return (
    <InViewAnimator
      className="container text-center mt-16 lg:mt-40 pb-8"
      inner={(controls) => (
        <>
          <div className="space-y-6 lg:space-y-[26px]">
            <InViewAnimatorBasicItem controls={controls} delay={0.2} prop="y" start={60}>
              <img className="mb-[-15px] md:mb-0 scale-[0.65] md:scale-[1] mx-auto" src={logo} alt="Mosaic logo" />
            </InViewAnimatorBasicItem>

            <InViewAnimatorBasicItem controls={controls} delay={0.5} prop="y" start={40}>
              <div
                className="t-52-quote mx-auto max-w-[1020px] pb-6 md:pb-8 md:pt-8"
                dangerouslySetInnerHTML={{ __html: heading }}
              ></div>
              <MaybeLink link={link}>
                <ArrowLink link={link} />
              </MaybeLink>
            </InViewAnimatorBasicItem>
          </div>
          <div className="border-dark-grid mt-12 md:mt-[142px]">
            <MediaAssetInner type="image" image={image} />
          </div>
        </>
      )}
    />
  );
};
