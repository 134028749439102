import React from 'react';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const Leadership = ({ items }) => {
  return (
    <InViewAnimator
      className="text-center md:text-left container"
      inner={(controls) => (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 gap-y-8 md:gap-y-20 lg:gap-y-20 lg:gap-10">
          {items.map((item, i) => (
            <LeadershipItem controls={controls} key={i} i={i} {...item} />
          ))}
        </div>
      )}
    />
  );
};

export const LeadershipItem = ({ image, name, jobTitle, controls, i }) => (
  <InViewAnimatorBasicItem
    key={`leadership-item-${i}`}
    controls={controls}
    delay={i / 6}
    duration={1.5}
    prop="x"
    className="space-y-3 md:space-y-7"
  >
    <div className="bg-white border-default-grid p-[28px]">
      <div className="border border-secondaryBlue">
        <MediaAssetInner className="mx-auto" type="image" image={image} />
      </div>
    </div>
    <div className="bg-white border border-strokeGrey px-[28px] py-4">
      <strong className="t-20-heading font-medium block">{name}</strong>
      <span className="t-17 block">{jobTitle}</span>
    </div>
  </InViewAnimatorBasicItem>
);
