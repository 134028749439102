import React from 'react';
import { ContentRenderer } from '~/components/layouts/ContentRenderer';

export const Section = ({
	prefix,
	innerSpacing,
	backgroundColour,
	previousBackground,
	nextBackground,
	removeBottomPadding,
	size,
	classname,
	content = [],
	components = [],
}) => {
	if (!content.length) content = components;

	const classes = ['section'];

	if (previousBackground !== backgroundColour) {
		classes.push('section-top');
	}
	if (nextBackground !== backgroundColour) {
		classes.push('section-bot');
	}

	if (backgroundColour === 'white') {
	} else if (backgroundColour === 'darkNavy') {
		classes.push('bg-darkNavy text-white');
	} else if (backgroundColour === 'primaryBlue') {
		classes.push('bg-primaryBlue text-white overflow-hidden');
	} else if (backgroundColour === 'lightGrey') {
		classes.push('bg-lightGrey');
	} else if (backgroundColour === 'fadedBlue') {
		classes.push('bg-fadedBlue');
	} else if (backgroundColour === 'headerBlue') {
		classes.push('bg-headerBlue');
	} else if (backgroundColour === 'fadedYellow') {
		classes.push('bg-fadedYellow');
	} else if (backgroundColour === 'fadedGreen') {
		classes.push('bg-fadedGreen');
	} else if (backgroundColour === 'fadedPurple') {
		classes.push('bg-headerPurple');
	}

	if (innerSpacing === '40') {
		classes.push('space-y-12 md:space-y-16 lg:space-y-24 xl:space-y-40');
	} else if (innerSpacing === '12') {
		classes.push('space-y-8 md:space-y-10 xl:space-y-12');
	} else if (innerSpacing === '2') {
		classes.push('space-y-2');
	} else {
		classes.push('space-y-8 md:space-y-12 xl:space-y-20');
	}

	if (size === '0' || size === null) {
		classes.push('section-0');
	}
	if (removeBottomPadding) {
		classes.push('!pb-0');
	}

	let layouts = 'multiple';
	if (content && content.length === 1) {
		layouts = content[0].fieldGroupName.replace(prefix, '');
	}

	if (classname) {
		classes.push(classname);
	}

	return (
		<section data-layouts={layouts} data-background={backgroundColour} className={classes.join(' ')}>
			<ContentRenderer prefix={prefix} content={content} />
		</section>
	);
};
