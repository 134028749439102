import React, { useEffect, useState, useRef } from 'react';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { AnimatePresence, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useInViewStart } from '~/hooks/useInViewStart';
import { useAutoProgressTimeout } from '~/hooks/useAutoProgressTimeout';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TextContent } from '~/components/flexible/TextContent';
import { ChevronDown } from '~/components/elements/Icon';
import { HeightFixer } from '~/components/elements/Animator';
import 'swiper/css';

export const AccordionCarousel = ({ items, imagePosition, header }) => {
	const [ref, started] = useInViewStart();
	const frameLength = 7;
	const [active, set_active] = useAutoProgressTimeout(started, frameLength, items.length - 1);
	const gridClasses = ['md:flex items-center xl:items-stretch space-y-4 md:space-y-0'];
	const imageClasses = ['md:w-[40%] xl:w-[50%]'];
	if (imagePosition === 'right') {
		gridClasses.push('md:flex-row-reverse');
		imageClasses.push('md:ml-10 xl:ml-28');
	} else {
		imageClasses.push('md:mr-10 xl:mr-28');
	}
	return (
		<div className="container center-swiper">
			{header && header.heading && (
				<div className="block lg:hidden pb-8">
					<TextContent innerOnly={true} centered={false} headingSize="36" {...header} />
				</div>
			)}
			<div ref={ref} className={gridClasses.join(' ')}>
				<div className={imageClasses.join(' ')}>
					<CarouselSwiper items={items} active={active} set_active={set_active} />
				</div>
				<div className="flex-1 flex-col flex">
					{header && (
						<div className="hidden lg:block">
							<TextContent innerOnly={true} centered={false} headingSize="36" {...header} />
						</div>
					)}
					<HeightFixer className="pt-4 lg:pt-12">
						{items.map((item, i) => {
							return (
								<CarouselItem
									key={i}
									onClick={() => {
										set_active(i);
									}}
									isActive={i === active}
									item={item}
									frameLength={frameLength}
								/>
							);
						})}
					</HeightFixer>
				</div>
			</div>
		</div>
	);
};

export const CarouselSwiper = ({ items, active, set_active }) => {
	const [swiper, set_swiper] = useState({});

	useEffect(() => {
		if (swiper.on) {
			swiper.on('slideChange', function () {
				set_active(swiper.activeIndex);
			});
		}
	}, [swiper]);

	useEffect(() => {
		if (swiper.slideTo && swiper.activeIndex !== active) {
			swiper.slideTo(active);
		}
	}, [swiper, active]);

	return (
		<div className="relative overflow-hidden">
			<Swiper
				onInit={(ev) => {
					set_swiper(ev);
				}}
				spaceBetween={0}
				slidesPerView={1}
			>
				{items.map((item, i) => (
					<SwiperSlide key={i}>
						<MediaAssetInner {...item.image} />
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export const CarouselItem = ({ item, isActive, onClick, frameLength }) => {
	const { heading, content } = item.main;
	return (
		<div className="pl-12 md:pl-14 mb-4 md:mb-8 relative cursor-pointer group" onClick={onClick}>
			{isActive && (
				<div className="ml-12 md:ml-14 bg-lightGrey h-[1px] absolute bottom-0 left-0 right-0">
					<AnimatePresence>
						{isActive && (
							<motion.div
								initial={{ width: 0, opacity: 1 }}
								animate={{ width: '100%', opacity: 1 }}
								exit={{ opacity: 0, duration: 0.5 }}
								transition={{
									width: {
										duration: frameLength,
									},
									opacity: {
										duration: 1,
									},
								}}
								className={`bg-primaryBlue h-full top-[100%] left-0`}
							></motion.div>
						)}
					</AnimatePresence>
				</div>
			)}
			<div className="w-[38px] md:w-[45px] absolute top-[-8px] left-0">
				<MediaAssetInner {...item.icon} />
			</div>
			<h4 className="relative t-18 font-medium mb-1 transition-color duration-500 flex items-center space-x-3 xl:space-x-5">
				<span>{heading}</span>
				<span className={`hidden md:inline-block transition-transform duration-500 ${isActive ? 'rotate-180' : ''}`}>
					<ChevronDown />
				</span>
			</h4>
			<AnimatePresence>
				{isActive && (
					<motion.div
						transition={{ type: 'tween' }}
						initial={{ height: 0, opacity: 0 }}
						animate={{ height: 'auto', opacity: 1, duration: 1.5 }}
						exit={{ height: 0, opacity: 0, duration: 0.5 }}
					>
						<p className=" relative t-17 opacity-80 pt-1 md:pt-3 pb-4 md:pb-7">{content}</p>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};
