import React from 'react';
import { MaybeLink, ArrowLink } from '~/components/elements/Link';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';

export const UpNext = ({ subheading, heading, content, mediaPosition, link, asset }) => (
	<div className="container pt-6 md:pt-0">
		<MaybeLink
			link={link}
			className="text-center md:text-left bg-darkNavy hover:bg-black duration-500 transition-color text-white md:flex overflow-hidden"
		>
			<div
				className={`flex-1 p-[20px] pt-[32px] md:p-[30px] lg:p-[70px] lg:py-[64px] ${
					mediaPosition === 'right' ? 'xl:py-[100px]' : ''
				}`}
			>
				<h6 className="t-17 font-semibold text-torqouise">{subheading}</h6>
				<h3 className="t-36 pt-3 lg:pt-3">{heading}</h3>
				<p className="t-20 py-3 lg:py-6 max-w-[500px]">{content}</p>
				{link && (
					<div>
						<ArrowLink inverted={true} link={link} />
					</div>
				)}
			</div>
			{mediaPosition === 'right' ? (
				<div className="md:w-[35%] lg:w-[53%] mt-auto ml-auto pl-6 md:pl-0 pt-3 md:pt-12 mb-[-10%] lg:my-[-6.5%] lg:mr-[-17%] xl:relative">
					<MediaAssetInner {...asset} />
				</div>
			) : (
				<div className="md:w-[35%] xl:w-[48%] mt-auto ml-auto pl-6 md:pl-0 pt-3 md:pt-12 pr-6 md:pr-12 xl:relative top-16">
					<MediaAssetInner {...asset} />
				</div>
			)}
		</MaybeLink>
	</div>
);
