import React from 'react';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const Statements = ({ items }) => (
	<InViewAnimator
		className="container text-center grid md:grid-cols-3 gap-6 gap-y-3"
		inner={(controls) => (
			<>
				{items.map((item, i) => (
					<InViewAnimatorBasicItem
						key={i}
						controls={controls}
						delay={i / 6}
						prop="y"
						start={50}
						className="max-w-[520px]"
					>
						<StatementsItem {...item} />
					</InViewAnimatorBasicItem>
				))}
			</>
		)}
	/>
);

export const StatementsItem = ({ text }) => {
	return (
		<div className="flex flex-col justify-center h-full t-20-heading py-3 px-4 md:px-6 lg:px-10 bg-white border-small-grid">
			<span>{text}</span>
		</div>
	);
};
