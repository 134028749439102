import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useState } from 'react';

export const Faqs = (props) => {
  const { heading, questions } = props;

  const [active, setActive] = useState(0);

  return (
    <div className="container ">
      <div className="max-w-[588px] mx-auto">
        <h2 className="t-52">{heading}</h2>
        <div className="mt-10 space-y-5 md:space-y-10">
          {questions?.map((q, i) => (
            <FaqItem key={`q${i}`} onClick={() => setActive(i === active ? null : i)} {...q} active={active === i} />
          ))}
        </div>
      </div>
    </div>
  );
};

const FaqItem = ({ question, answer, active, onClick }) => {
  return (
    <div className="overflow-hidden">
      <div className="bg-strokeGrey w-full h-[2px] bg-opacity-20"></div>

      <div className="mt-5">
        <div className="flex justify-between cursor-pointer " onClick={onClick}>
          <h4 className="font-medium t-22">{question}</h4>
          <div className={`flex-none `}>
            <FaqDropdown active={active} />
          </div>
        </div>
        <AnimatePresence exitBeforeEnter initial={false}>
          {active && (
            <motion.div
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { height: 'auto', opacity: 1, marginTop: 5 },
                collapsed: { height: 0, opacity: 0, marginTop: 0 },
              }}
              transition={{ ease: [0.04, 0.62, 0.23, 0.98] }}
              className="t-18"
            >
              {answer}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export const FaqDropdown = ({ active }) => {
  return (
    <svg
      className={`${active ? 'rotate-[135deg]' : 'rotate-0'} transition-transform duration-150 ease-in-out`}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="5" width="12" height="2" fill="#1A2024" />
      <rect x="5" y="12" width="12" height="2" transform="rotate(-90 5 12)" fill="#1A2024" />
    </svg>
  );
};
