import React from 'react';
import { MaybeLink, ArrowLink } from '~/components/elements/Link';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';

export const ArticleCta = ({ image, title, link, flushToBottom }) => {
	return (
		<div className="article-cta-margin text-center md:text-left bg-darkNavy text-white py-12 md:py-0 overflow-x-hidden">
			<div
				className={`container md:flex items-center ${
					flushToBottom ? 'space-y-0' : 'space-y-3'
				} md:space-y-0 md:space-x-6`}
			>
				<div className={`flex-1 lg:ml-12 ${flushToBottom ? 'mt-auto' : ''}`}>
					<MediaAssetInner className="mx-auto md:mx-0 max-w-[400px]" {...image} />
				</div>
				<MaybeLink link={link} className="relative md:w-[50%] xl:w-[54%]">
					<div className="hidden md:block border-dashed-article-vertical absolute top-0 bottom-0 left-0"></div>
					<div
						className={`border-dashed-article absolute ${
							flushToBottom ? 'top-0 md:top-7' : 'top-7'
						} left-[-20px] right-[-20px] md:right-auto md:left-1 md:w-[500%]`}
					></div>
					<div className="hidden md:block border-dashed-article absolute bottom-7 left-0 md:left-1 w-full md:w-[500%]"></div>
					<div className="relative pt-16 md:py-14 md:pl-10 lg:pl-14 max-w-[600px]">
						<h4 className="t-36 pb-5">{title}</h4>
						<ArrowLink link={link} size="x-large" primaryBlue={true} />
					</div>
				</MaybeLink>
			</div>
		</div>
	);
};
