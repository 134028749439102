import React from 'react';
import { TextContent } from '~/components/flexible/TextContent';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const MastheadPlatform = (props) => {
	const { asset } = props;
	return (
		<InViewAnimator
			className="section-header-padding"
			inner={(controls) => (
				<div className="container md:flex md:flex-row-reverse md:justify-between items-center">
					<InViewAnimatorBasicItem
						className="md:w-[35%] lg:w-[45%] xl:w-[55%] md:pl-6"
						controls={controls}
						delay={0.2}
						prop="y"
						start={80}
					>
						<MediaAssetInner className="mx-auto md:ml-auto" {...asset} />
					</InViewAnimatorBasicItem>
					<InViewAnimatorBasicItem
						className="flex-1 max-w-[550px] mx-auto md:mx-0 pt-6 pb-16 md:pt-12 lg:pt-24 lg:pb-24 xl:pt-32 xl:pb-36"
						controls={controls}
						delay={0}
						prop="y"
						start={50}
					>
						<TextContent
							innerOnly={true}
							headingSize="52"
							centered={false}
							isMasthead={true}
							linkStyle="button"
							subheadingStyle="plain"
							centered={false}
							{...props}
						/>
					</InViewAnimatorBasicItem>
				</div>
			)}
		/>
	);
};
