import React, { useState, useEffect } from 'react';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { CarouselArrowButton } from '~/components/elements/Link';
import { Swiper, SwiperSlide } from 'swiper/react';
import { MaybeLink, ArrowLink } from '~/components/elements/Link';
import { AnimatePresence, motion } from 'framer-motion';
import 'swiper/css';

export const TeamCarousel = ({ items }) => {
  const [active, set_active] = useState(0);
  return (
    <div className="container border-d">
      <div className="relative h-auto">
        <div className="md:flex md:space-x-12 lg:space-x-32">
          <div className="mx-auto md:mx-0 md:w-[320px] lg:w-[415px] pb-12 md:pb-0">
            <div className="border-default-grid p-3.5 md:p-[30px] bg-white">
              <div className="overflow-hidden border border-[#C0D9FF]">
                <TeamCarouselSwiper items={items} active={active} set_active={set_active} />
              </div>
            </div>
          </div>
          <div className="flex-1 md:max-w-[588px] md:mt-[30px]">
            <div className="flex flex-row items-center justify-between pb-4 md:pb-[33px]">
              <h4 className="t-15 px-2.5 py-1.5 bg-primaryText text-white font-semibold uppercase">In the Spotlight</h4>
              <TeamCarouselArrowNav items={items} active={active} set_active={set_active} />
            </div>
            <AnimatePresence>
              {items.map((item, i) => {
                if (i != active) return null;
                return (
                  <motion.div
                    key={`team-card-${i}`}
                    transition={{ type: 'tween' }}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0, duration: 0.75 }}
                  >
                    <TeamCarouselSwiperItem slide={item} />
                  </motion.div>
                );
              })}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
};

export const TeamCarouselSwiper = ({ items, active, set_active }) => {
  const [swiper, set_swiper] = useState({});

  useEffect(() => {
    if (swiper.on) {
      swiper.on('slideChange', function () {
        set_active(swiper.activeIndex);
      });
    }
  }, [swiper]);

  useEffect(() => {
    if (swiper.slideTo && swiper.activeIndex !== active) {
      swiper.slideTo(active);
    }
  }, [swiper, active]);

  return (
    <Swiper
      onInit={(ev) => {
        set_swiper(ev);
      }}
      slidesPerView={1}
      spaceBetween={50}
    >
      {items.map((slide, i) => (
        <SwiperSlide key={i}>
          <MediaAssetInner type="image" image={slide.profilePicture} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export const TeamCarouselSwiperItem = ({ slide }) => {
  return (
    <div className="space-y-8">
      <h4 className="t-24-carousel font-medium leading-[1.24]">{slide.quote}</h4>
      <span className="inline-block">
        <strong className="font-medium">{slide.name},</strong> {slide.jobTitle}
      </span>
      <MaybeLink link={slide.teamlink}>
        <ArrowLink link={slide.teamlink} />
      </MaybeLink>
    </div>
  );
};

export const TeamCarouselArrowNav = ({ items, active, set_active }) => {
  return (
    <div className="space-x-1">
      <CarouselArrowButton
        isSmallArrow={true}
        flip={true}
        active={active !== 0}
        onClick={() => {
          set_active(active - 1);
        }}
      />
      <CarouselArrowButton
        isSmallArrow={true}
        active={active !== items.length - 1}
        onClick={() => {
          set_active(active + 1);
        }}
      />
    </div>
  );
};
