import { useTransform, useViewportScroll } from 'framer-motion';
import React, { useRef } from 'react';

const ProgressWindow = ({
  title = 'System message',
  loadingText = 'Strategy in progress',
  buttonLabel = 'Cancel',
  progressStart = 0,
  progressEnd = 20000,
  className = '',
  overallProgressPercent,
  completeLabel = 'Updated',
}) => {
  const barProgress = useTransform(overallProgressPercent, [progressStart, progressEnd], [0, 18]);

  return (
    <div
      key={`${title}`}
      className={`max-w-[296px] relative bg-[#0368FE] w-full rounded p-[7px] border border-[#2B90FF] progress-window ${className}`}
    >
      <div className="absolute top-[0.5px] right-[7px]  border border-[#2B90FF] cursor-pointer hover:bg-[#d14923] transition duration-150 ease-in-out bg-[#E24E24] rounded-b p-[6px] pb-[7px] px-[21px] ">
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L10.5467 10.5466" stroke="white" />
          <path d="M10.5469 1L1.00025 10.5467" stroke="white" />
        </svg>
      </div>
      <div className="text-white text-[13px] pb-[7px]">{title}</div>

      <div className="bg-white w-full p-[20px]">
        <div className="text-[15px]">{loadingText}</div>

        <div className="mt-[11px] w-full bg-[#EEF1F7] h-[37px] progress-window-bar p-[5px] flex space-x-[2px] ">
          {[...Array(18)].slice(0, barProgress.current).map((un, i) => {
            return <div key={`bar${title}${i}`} className="h-full bg-[#0368FE] flex-1 max-w-[11px]"></div>;
          })}
        </div>
      </div>

      <div className="w-full bg-[#EEF1F7] p-[8px]  cursor-pointer">
        <div className="ml-auto bg-white max-w-[104px] flex justify-center border border-black progress-window-button ">
          <div className="text-[15px] px-[28px] py-[7px]">
            {barProgress.current === 18 ? completeLabel : buttonLabel}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressWindow;
