import React from 'react';

export const StagingIndex = (page) => {
  return (
    <section className="section section-top section-bot">
      <div className="container relative text-center section-header-padding">
        <h4 className="mt-8 t-36">Staging Index</h4>
        <ul className="mt-6 space-y-1 t-17 text-primaryBlue">
          <li>
            <a href="/home/">Homepage</a>
          </li>
          <li>&nbsp;</li>
          <li>
            <a href="/strategic-finance/">Strategic Finance</a>
          </li>
          <li>&nbsp;</li>
          <li>
            <a href="/products/mosaic-analytics/">Analytics</a>
          </li>
          <li>
            <a href="/products/mosaic-planning/">Planning</a>
          </li>
          <li>&nbsp;</li>
          <li>
            <a href="/collaboration/">Collaboration</a>
          </li>
          <li>
            <a href="/data-management/">Data Management</a>
          </li>
          <li>
            <a href="/security/">Security</a>
          </li>
          <li>&nbsp;</li>
          <li>
            <a href="/about-us/">About Us</a>
          </li>
          <li>&nbsp;</li>
          <li>
            <a href="/four-oh-four/">404</a>
          </li>
          <li>
            <a href="/newsroom/">Newsroom</a>
          </li>
          <li>
            <a href="/careers/">Careers</a>
          </li>
          <li>&nbsp;</li>
          <li>
            <a href="/request-a-demo/">Request a demo</a>
          </li>
          <li>
            <a href="/contact-us/">Contact us</a>
          </li>
          <li>&nbsp;</li>
          <li>
            <a href="/use-cases/finance-teams">Use Case - Finance Teams & CFOs</a>
          </li>
          <li>
            <a href="/use-cases/founders-ceos">Use Case - Founders & CEOs</a>
          </li>
          <li>
            <a href="/use-cases/investors">Use Case - Investors & Board</a>
          </li>
          <li>
            <a href="/use-cases/accounting-firms">Use Case - Business Operations</a>
          </li>
          <li>
            <a href="/use-cases/sales-and-marketing">Use Case - Sales & Marketing</a>
          </li>
          <li>
            <a href="/use-cases/human-resources">Use Case - Human Resources</a>
          </li>
          <li>&nbsp;</li>
          <li>
            <a href="/workflow/financial-analysis">Workflow - Financial Analysis</a>
          </li>
          <li>
            <a href="/workflow/financial-modeling">Workflow - Financial Modeling</a>
          </li>
          <li>
            <a href="/workflow/collaborative-budgeting">Workflow - Collaborative Budgeting</a>
          </li>
          <li>
            <a href="/workflow/cross-functional-insights">Workflow - Cross Functional Insights</a>
          </li>
          <li>
            <a href="/workflow/close-consolidation">Workflow - Close & Consolidation</a>
          </li>
          <li>
            <a href="/workflow/investor-and-board-reporting">Workflow - Investor & Board Reporting</a>
          </li>
          <li>&nbsp;</li>
          <li>
            <a href="/privacy-policy/">Privacy Policy</a>
          </li>
          <li>&nbsp;</li>
          <li>
            <a href="/customers/">Customers</a>
          </li>
          <li>
            <a href="/customers/forage/">Customers - Single</a>
          </li>
          <li>&nbsp;</li>
          <li>
            <a href="/integrations/">Integrations</a>
          </li>
          <li>
            <a href="/integrations/quickbooks/">Integrations - Single</a>
          </li>
          <li>&nbsp;</li>
          <li>
            <a href="/financial-metrics/">Metrics</a>
          </li>
          <li>
            <a href="/financial_metrics/net-and-gross-dollar-retention/">Metrics - Single</a>
          </li>
          <li>&nbsp;</li>
          <li>
            <a href="/resources/">Resources</a>
          </li>
          <li>&nbsp;</li>
          <li>
            <a href="/plans/">Plans</a>
          </li>
          {/* <li>&nbsp;</li>
					<li>
						<a href="/resources/">Resources</a>
					</li>
					<li>
						<a href="/category/ebook">Resource Category</a>
					</li>
					<li>
						<a href="/the-cfos-path-to-a-strategic-seat-at-the-table/">Resource Article</a>
					</li>
					<li>&nbsp;</li>
					<li>
						<a href="/customers/">Customers</a>
					</li>
					<li>
						<a href="/customers/revenue/">Customer Story</a>
					</li>*/}
        </ul>
      </div>
    </section>
  );
};
