import { graphql, useStaticQuery } from 'gatsby';

export const useIntegrationsCategories = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpIntegrationCategory {
        nodes {
          taxonomyIntegrationCategory {
            title
            shortName
            description
            color
            benefits {
              heading
              content
            }
          }
          integrations {
            nodes {
              link
              integration {
                gridViewLogo {
                  ...Image
                }
                shortDescription
                name
              }
            }
          }
        }
      }
    }
  `);

  return data.allWpIntegrationCategory?.nodes;
};
