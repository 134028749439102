import React, { useState, Fragment } from 'react';
import { Link } from 'gatsby';
import Scroll from 'react-scroll';
import {
  SmallArrowRight,
  PaginationArrowRight,
  ArrowLinkIcon,
  PlayIcon,
  SmallPlayIcon,
  PaginationArrowUp,
  DownloadIcon,
} from '~/components/elements/Icon';
import { LinkModal } from '~/components/elements/Modal';

const ScrollLink = Scroll.Link;

export const MaybeLink = ({
  link = {},
  external,
  modal,
  className = 'block',
  children,
  style = null,
  onMouseEnter = null,
}) => {
  const [open, set_open] = useState(false);
  let videoId = false;
  if (link && link.type === 'video') {
    modal = true;
    videoId = link.videoId;
  }
  external = link && link.external ? link.external : false;
  if (link && link.link) link = link.link;
  const isLink = link && link.url && !modal ? true : false;
  let Tag;
  let extraProps = {};
  if (isLink) {
    if (external) {
      Tag = 'a';
      extraProps = {
        target: '_blank',
      };
      link.url = external.url;
    } else if (link.url && link.url[0] === '#') {
      Tag = ScrollLink;
      extraProps = {
        to: link.url.replace('#', ''),
        smooth: true,
        offset: -80,
        duration: 500,
      };
    } else {
      Tag = 'a';
    }
  } else {
    Tag = 'div';
  }
  return (
    <Tag
      onMouseEnter={onMouseEnter}
      onClick={
        modal
          ? () => {
              set_open(true);
            }
          : null
      }
      href={link ? link.url : null}
      className={`block${isLink ? ' group' : ''}${modal ? ' cursor-pointer' : ''} ${className}`}
      style={style}
      {...extraProps}
    >
      {children}
      {modal && <LinkModal open={open} set_open={set_open} videoId={videoId} />}
    </Tag>
  );
};

export const ArrowLink = ({ link, inverted, primaryBlue, size }) => {
  if (link.link) link = link.link;
  const arrowBoxClasses = ['shrink-0 relative overflow-hidden transition-color duration-500'];
  const linkClasses = ['font-medium inline-flex items-center'];
  if (primaryBlue) {
    arrowBoxClasses.push('bg-primaryBlue group-hover:bg-[#000]');
  } else if (inverted) {
    arrowBoxClasses.push('bg-torqouise group-hover:bg-white');
  } else {
    arrowBoxClasses.push('bg-primaryText group-hover:bg-primaryBlue');
  }
  if (size === 'small') {
    arrowBoxClasses.push('h-[18px] w-[18px]');
    linkClasses.push('space-x-2 md:space-x-4 t-15');
  } else {
    linkClasses.push('space-x-3 md:space-x-5');
    arrowBoxClasses.push('h-[22px] w-[22px] md:h-[26px] md:w-[26px]');
    if (size === 'x-large') {
      linkClasses.push('t-20-heading');
    } else {
      linkClasses.push('t-18');
    }
  }
  return (
    <span className={linkClasses.join(' ')}>
      <span>{link.title}</span>
      <div className={arrowBoxClasses.join(' ')}>
        <ArrowLinkIconWrapper inverted={inverted} className="group-hover:translate-x-[100%]" />
        <ArrowLinkIconWrapper inverted={inverted} className="translate-x-[-100%] group-hover:translate-x-[0]" />
      </div>
    </span>
  );
};

export const ArrowLinkIconWrapper = ({ inverted, className }) => (
  <ArrowLinkIcon
    inverted={inverted}
    className={`absolute top-0 left-0 w-full h-full transition-transform duration-500 ${className}`}
  />
);

export const DownloadLink = ({ link }) => {
  if (link.link) link = link.link;
  const linkClasses = [
    'font-medium flex w-full justify-center space-x-3 items-center space-x transition-opacity duration-200 ease-in-out hover:opacity-50',
  ];

  return (
    <span className={linkClasses.join(' ')}>
      <span className="my-[-4px]">
        <DownloadIcon />
      </span>
      <span className="block">{link.title}</span>
    </span>
  );
};

export const UnderlineLink = ({ link, type }) => {
  const linkClasses = ['t-17 link-underline is-blue inline-flex space-x-3 items-center'];
  let icon = null;
  if (type === 'video') {
    icon = <SmallPlayIcon />;
  }

  return (
    <span className={linkClasses.join(' ')}>
      {icon && <span className="relative top-[-1px]">{icon}</span>}
      <span>{link.title}</span>
    </span>
  );
};

export const Button = ({ link, buttonStyle, size, icon }) => {
  if (link.type === 'video') {
    icon = 'play';
  }
  if (link.link) link = link.link;
  const classes = ['button flex md:inline-flex justify-center items-center space-x-3'];
  let iconColor = 'white';
  if (buttonStyle === 'primary') {
    classes.push('button-primary');
  } else if (buttonStyle === 'outline') {
    classes.push('button-outline');
    iconColor = 'blue';
  } else if (buttonStyle === 'white') {
    classes.push('button-white');
  } else if (buttonStyle === 'black') {
    classes.push('button-black');
  } else if (buttonStyle === 'white-outline') {
    classes.push('button-white-outline');
  }
  if (size === 'header') {
    classes.push('button-header');
  }
  if (size === 'tight') {
    classes.push('button-tight');
  }
  if (size === 'fullWidth') {
    classes.push('w-full');
  }
  return (
    <span className={classes.join(' ')}>
      {icon === 'play' && (
        <span className="my-[-4px]">
          <PlayIcon color={iconColor} />
        </span>
      )}
      <span>{link.title}</span>
    </span>
  );
};

export const CarouselArrowButton = ({ flip, active, onClick, isSmallArrow }) => {
  return (
    <button
      disabled={!active}
      onClick={active ? onClick : null}
      className={`${
        isSmallArrow ? 'px-[12.1px] py-[4.78px]' : 'px-[21px] py-[16px]'
      }  transition-color duration-500 bg-darkNavy${!active ? ' opacity-50' : ' group-hover:bg-primaryBlue'}`}
    >
      <span className={`inline-block${flip ? ' rotate-180' : ''}${isSmallArrow ? ' relative top-[-1.5px]' : ''}`}>
        {isSmallArrow ? <SmallArrowRight /> : <PaginationArrowRight />}
      </span>
    </button>
  );
};

export const CarouselArrowButtonUp = ({ flip, active, onClick, bgColor }) => {
  return (
    <div className="relative">
      <button
        disabled={!active}
        onClick={active ? onClick : null}
        className={`w-[35.97px] h-[35.97px] transition-color duration-500 opacity-50 ${bgColor} ${
          !active ? 'opacity-25' : 'group-hover:bg-primaryBlue'
        }`}
      ></button>
      <div
        onClick={active ? onClick : null}
        className={`absolute top-[12.5px] left-[12.5px] cursor-pointer inline-block${flip ? ' rotate-180' : ''}`}
      >
        <PaginationArrowUp />
      </div>
    </div>
  );
};
