import { graphql, useStaticQuery } from 'gatsby';

export const useMetrics = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpFinancialMetric {
        nodes {
          ...GeneratedWpFinancialMetric
        }
      }
      allWpMetricsCategory {
        nodes {
          taxonomyMetricsCategory {
            tabTitle
            pageTitle
            pageDescription
            longDescription
          }
          slug
        }
      }
    }
  `);

  let categories = data.allWpMetricsCategory?.nodes;
  const metrics = data.allWpFinancialMetric?.nodes;

  categories = categories.map((_cat) => {
    const cat = {
      slug: _cat.slug,
      metrics: [],
      ..._cat.taxonomyMetricsCategory,
    };
    metrics.map((metric) => {
      const slug = metric.financialMetricsImported.categoryReference;
      if (
        slug === cat.slug &&
        metric.financialMetricsImported.description &&
        metric?.financialMetricsImported?.graphs
      ) {
        cat.metrics.push(metric);
      }
    });
    return cat;
  });

  return {
    categories,
    metrics,
  };
};
