import React from 'react';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { MaybeLink, ArrowLink } from '~/components/elements/Link';

export const WorkflowNextUp = ({ featuredWorkflow, remainingWorkflows }) => {
  return (
    <div className="container overflow-hidden">
      <h2 className="t-36 mb-8 relative">
        <div className="absolute h-[1px] top-[0.75em] left-0 right-0 bg-[#eee]"></div>
        <span className="relative bg-white inline-block pr-4 md:pr-12">Next Up</span>
      </h2>
      <div className="md:flex md:space-x-6 lg:space-x-12">
        <MaybeLink
          link={{ url: featuredWorkflow.link }}
          className="bg-darkNavy overflow-hidden duration-400 transition-color text-white pl-6 md:pl-8 md:flex md:flex-row"
        >
          <div className="xl:w-[55%] pt-6 md:pt-8 py-8 flex">
            <div className="hidden md:block w-[28px] mr-6">
              <MediaAssetInner {...featuredWorkflow.postTypePagePreview.icon} />
            </div>
            <div className="w-full max-w-[460px] xl:w-[460px] pr-6 md:pr-0">
              <h4 className="t-24 mb-4">{featuredWorkflow.title}</h4>
              <p className="xl:w-[120%]">{featuredWorkflow.postTypePagePreview.shortDescription}</p>
              <div className="relative flex mt-6">
                <h4 className="t-17">Explore</h4>
                <ArrowLink link={featuredWorkflow.link} size="x-large" primaryBlue={true} />
              </div>
            </div>
          </div>
          <div className="mr-[-2%] w-auto h-[90%] max-h-[250px] md:max-h-[none] ml-auto mt-auto overflow-hidden">
            <MediaAssetInner
              className="w-full h-full object-cover object-left-top"
              {...featuredWorkflow.postTypePagePreview.image}
            />
          </div>
        </MaybeLink>
        <div className="space-y-4 divide-[#eee] divide-y">
          {remainingWorkflows.map((item, i) => (
            <MaybeLink
              key={i}
              link={{ url: item.link }}
              className="relative hover:opacity-50 duration-500 transition-opacity flex space-x-4 pt-4"
            >
              <MediaAssetInner {...item.postTypePagePreview.invertedIcon} />
              <h4 className="t-17 !font-medium">{item.title}</h4>
            </MaybeLink>
          ))}
        </div>
      </div>
    </div>
  );
};
