import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { MediaAssetInner } from './MediaAsset';

export const PlansCalculator = (props) => {
  const { heading, image } = props;

  const [averageFinance, setAverageFinance] = useState(120000);
  const [teamSize, setTeamSize] = useState(3);

  const totalEfficiencySavings = averageFinance * 0.7 * teamSize || 0;
  const implementationSavings = 145800 || 0;
  const toplineGains = teamSize * 112 * 350 || 0;
  const totalSavings = totalEfficiencySavings + implementationSavings + toplineGains || 0;

  return (
    <div className="container bg-tertiaryGrey pt-14 md:pt-[4.5rem] relative pb-[36px]  ">
      <div className="absolute flex w-full justify-center md:block md:w-auto  top-[-2rem] md:top-[-2.5rem] right-[0] md:right-[11rem]">
        <MediaAssetInner className="w-full max-w-[85px] md:max-w-[130px]" type="image" image={image} />
      </div>

      <h2 className="text-center t-42">{heading}</h2>

      <div className="flex mt-12">
        <div className="justify-center w-full space-y-4 sm:space-y-0 sm:flex sm:space-x-6">
          <CalculatorInput
            value={averageFinance}
            setValue={setAverageFinance}
            heading="Average finance fully loaded salary"
            currency
          />
          <CalculatorInput value={teamSize} setValue={setTeamSize} heading="Team size" />
        </div>
      </div>

      <div className="sm:mt-[68px] mt-9 max-w-[1126px] mx-auto text-white bg-darkNavy px-6 sm:px-[65px] sm:pt-[55px] sm:pb-[43px] py-8 ">
        <div className="justify-between w-full space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
          <CalculatorOutput value={totalEfficiencySavings} heading="Total efficiency savings*" />
          <CalculatorOutput value={implementationSavings} heading="Implementation savings*" />
          <CalculatorOutput value={toplineGains} heading="Topline gains*" />
        </div>

        <div className=" mt-[51px] flex justify-center w-full items-end text-center">
          <Dividers />
          <div className="px-[38px]">
            <h5 className="font-medium t-20">Total savings</h5>
            <div className="mt-1">
              <h2 className="t-42">${numberWithCommas(totalSavings)}</h2>
            </div>
          </div>
          <Dividers />
        </div>
      </div>
    </div>
  );
};

const Dividers = () => (
  <div className="w-full pb-[17px]">
    <div className="w-full bg-white bg-opacity-10 h-[2px]"></div>
    <div className="w-full bg-white bg-opacity-10 h-[2px] mt-[8px]"></div>
  </div>
);

const CalculatorInput = ({ heading, currency, value, setValue }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="sm:max-w-[384px] w-full mx-auto sm:mx-0">
      <div className="relative mx-auto max-w-max sm:mx-0 ">
        <div className="absolute right-[-17px] top-[9px]">
          <div className="relative pointer-events-none tooltip ">
            <div
              className={`absolute top-[28px]  md:top-[-19px] z-[100] right-[-65px] md:right-[-292px] bg-primaryBlue tooltiptext w-[150px] md:w-[270px] p-3 md:pt-4 md:p-5 text-white t-14 ${
                showTooltip ? 'opacity-100' : 'opacity-0'
              }  transition-opacity duration-200`}
            >
              This is how many people you employ and are on your payroll.
            </div>
          </div>
          <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7 0.591553C3.1423 0.591553 0 3.73385 0 7.59155C0 11.4493 3.1423 14.5916 7 14.5916C10.8577 14.5916 14 11.4493 14 7.59155C14 3.73385 10.8577 0.591553 7 0.591553ZM7 1.99155C10.1011 1.99155 12.6 4.49047 12.6 7.59155C12.6 10.6926 10.1011 13.1916 7 13.1916C3.89891 13.1916 1.4 10.6926 1.4 7.59155C1.4 4.49047 3.89891 1.99155 7 1.99155ZM6.3 4.09155V5.49155H7.7V4.09155H6.3ZM6.3 6.89155V11.0916H7.7V6.89155H6.3Z"
              fill="#9FA0AD"
            />
          </svg>
        </div>
        <div onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
          <div className="t-20">
            {heading} <span className="inline-block"></span>
          </div>
          <div className="mt-[4px] border-t border-dashed border-[#181A3D] w-full"></div>
        </div>
      </div>
      <div className="relative">
        {currency && <div className="absolute top-[12px] left-[13px] font-medium">$</div>}
        <input
          onChange={(event) => setValue(parseInt(event.target.value))}
          defaultValue={value}
          className={`flex w-full t-17 mt-3 sm:mt-6 flex-auto bg-white border border-[#9FA0AD] h-[49px] ${
            currency ? 'pl-[31px]' : 'pl-[15px]'
          } `}
          type="number"
        />
      </div>
    </div>
  );
};

const CalculatorOutput = ({ heading, value = 0 }) => {
  return (
    <div className="sm:max-w-[260px] w-full text-center flex flex-col content-between mx-auto sm:mx-0">
      <h5 className="mb-2 font-medium sm:mb-4 t-20">{heading}</h5>
      <div className=" mt-auto border border-white border-opacity-30 bg-[#F3F5F8] bg-opacity-[0.11] h-[49px] w-full flex items-center justify-center">
        <span className="inline-block mr-2 ">$</span> {numberWithCommas(value)}
      </div>
    </div>
  );
};

function numberWithCommas(x) {
  return parseInt(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
