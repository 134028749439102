import React from 'react';
import NewsletterIllustration from '~/assets/icons/newsletter-illustration.svg';
import NewsletterIllustrationAlt from '~/assets/icons/newsletter-illustration-alt.svg';
import { HubspotForm } from '~/components/elements/Hubspot';

export default function Newsletter({ grid = false }) {
  return (
    <section className="my-12 lg:mt-24 !overflow-visible">{grid ? <NewsletterGrid /> : <NewsletterStandard />}</section>
  );
}

export const NewsletterGrid = () => {
  return (
    <div className="hubspot-inline border border-strokeGrey bg-fadedBlue relative">
      {/* <div className="absolute bottom-[-5px] right-[-5px] w-2.5 h-2.5 bg-strokeGrey z-10"></div> */}
      <div className="px-5 md:px-10 lg:pl-32 lg:pr-20 relative">
        <div className="absolute top-10 left-0 right-0 border-t border-strokeGrey border-opacity-30 border-dashed"></div>
        <div className="absolute bottom-12 md:bottom-[72px] left-0 right-0 border-t border-strokeGrey border-opacity-30 border-dashed"></div>
        <div className="absolute bottom-[90px] md:bottom-[121px] left-0 right-0 border-t border-strokeGrey border-opacity-30 border-dashed"></div>

        <div className="space-y-8 border-r border-strokeGrey border-opacity-30 border-dashed relative z-[1]">
          <div className="border-l xl:border-r max-w-[553px] border-strokeGrey border-opacity-30 border-dashed py-12 md:py-[72px] space-y-8 ">
            <h3 className="t-36">The latest Mosaic Insights, straight to your inbox</h3>
            <HubspotForm appendToId="-cta" formId="1f256645-635c-47ca-8659-d91e14c0361a" />
          </div>
        </div>
      </div>
      <img className="w-[152px] absolute -top-8 right-20 hidden xl:block" src={NewsletterIllustration} alt="" />
    </div>
  );
};

export const NewsletterStandard = () => {
  return (
    <div className="hubspot-inline border-default-grid bg-fadedBlue max-w-[820px] mx-auto relative">
      <div className="pt-8 pb-12 md:pt-16 md:pb-20 px-8 relative">
        <div className="max-w-[488px] mx-auto text-center space-y-8 ">
          <h3 className="t-36">The latest Mosaic Insights, straight to your inbox</h3>

          <HubspotForm appendToId="-cta" formId="1f256645-635c-47ca-8659-d91e14c0361a" />
        </div>
      </div>
      <img className="w-[140px] absolute -top-8 right-8 hidden xl:block" src={NewsletterIllustrationAlt} alt="" />
    </div>
  );
};
