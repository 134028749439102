import React from 'react';
import { Section } from '~/components/layouts/Section';
import { LandingPage } from '~/components/layouts/LandingPage';

export const LayoutRenderer = ({ sections, prefix, startBg, endBg }) => {
	if (!sections) return null;
	return (
		<>
			{sections.map((section, i) => {
				let previousBackground = getSectionBackroundColor(sections, i - 1, true);
				if (!previousBackground) previousBackground = startBg;
				let nextBackground = getSectionBackroundColor(sections, i + 1, false);
				if (!nextBackground) nextBackground = endBg;
				if (section.fieldGroupName.includes('ReusableBlock') && section.reusableBlock) {
					return (
						<LayoutRenderer
							key={i}
							sections={section.reusableBlock.postTypePage.sections}
							prefix="ReusableBlock_Posttypepage_Sections_Section_Components_"
							startBg={previousBackground}
							nextBg={nextBackground}
						/>
					);
				}
				if (section.fieldGroupName.includes('LandingPage')) {
					return <LandingPage key={i} {...section} />;
				}
				return (
					<Section
						prefix={prefix}
						key={i}
						{...section}
						previousBackground={previousBackground}
						nextBackground={nextBackground}
					/>
				);
			})}
		</>
	);
};

export const getSectionBackroundColor = (sections, i, isBefore) => {
	if (!sections[i]) return null;
	if (sections[i].backgroundColour) return sections[i].backgroundColour;
	const section = sections[i];
	if (section.fieldGroupName.includes('ReusableBlock') && section.reusableBlock) {
		const layouts = section.reusableBlock.postTypePage.sections;
		const layout = layouts[isBefore ? layouts.length - 1 : 0];
		return layout.backgroundColour;
	}
	return null;
};
