import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { MaybeLink, ArrowLink, Button } from '~/components/elements/Link';
import { Submenus } from '~/components/global/Submenus';

export const MobileMenu = ({ menu, links }) => {
	const [openMenu, set_openMenu] = useState(null);
	return (
		<AnimatePresence>
			<motion.div
				initial={{ opacity: 0, y: -15 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.25, ease: 'easeInOut' }}
				className="absolute top-[70px] left-0 right-0 h-[100vh] pb-[100px] bg-white overflow-auto p-6"
			>
				<HeaderMenu links={menu} openMenu={openMenu} set_openMenu={set_openMenu} />
				<HeaderCtas links={links} />
			</motion.div>
		</AnimatePresence>
	);
};

export const HeaderMenu = ({ links, openMenu, set_openMenu }) => (
	<ul className="space-y-5">
		{links.map(({ link, showSubmenu }, i) => {
			if (showSubmenu) {
				return (
					<li
						key={i}
						onClick={() => {
							if (i === openMenu) {
								set_openMenu(null);
							} else {
								set_openMenu(i);
							}
						}}
					>
						<span className="t-24">{link.title}</span>
						<span className="nav-divider-line"></span>
						{openMenu === i && <Submenus mobile={true} menu={links} openMenu={openMenu} />}
					</li>
				);
			} else {
				return (
					<li key={i}>
						<a className="t-24 block" href={link.url}>
							{link.title}
						</a>
						<span className="nav-divider-line"></span>
					</li>
				);
			}
		})}
	</ul>
);

export const HeaderCtas = ({ links }) => (
	<div className="text-center">
		{links.map(({ link, type }, i) => {
			if (type === 'default') {
				return (
					<a href={link.url} key={i} className="block my-3 py-2 t-17 font-medium ml-auto" href="#">
						{link.title}
					</a>
				);
			} else {
				return (
					<a href={link.url} key={i}>
						<Button link={{ title: link.title }} size="header" buttonStyle="primary" />
					</a>
				);
			}
		})}
	</div>
);
