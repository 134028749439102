import { graphql, useStaticQuery } from 'gatsby';

export const useCustomerStories = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpCustomerStory {
        nodes {
          title
          postTypeCustomerStory {
            name
            jobTitle
            logo {
              ...Image
            }
            mastheadHeading
            previewBackground
            profilePicture {
              ...Image
            }
            summary
            image {
              ...Image
            }
            title
          }
          uri
        }
      }
    }
  `);

  return data.allWpCustomerStory?.nodes;
};
