import React, { useRef } from 'react';
import { MaybeLink, Button } from '~/components/elements/Link';
import ctaGraphBook from '~/assets/icons/cta-graph-book.svg';
import ctaAirplane from '~/assets/icons/cta-airplane.svg';
import ctaGrid1 from '~/assets/icons/cta-grid-1.svg';
import ctaGrid2 from '~/assets/icons/cta-grid-2.svg';
import { Parallax } from '~/components/elements/Parallax';
import Typewriter from 'typewriter-effect';

export const PrimaryCta = ({ headingStart, headingEnd, headingWords, links }) => {
	const parallaxRef = useRef(null);

	return (
		<div ref={parallaxRef} className="container">
			<div className="relative py-8">
				<PrimaryCtaFloatingAssets parallaxRef={parallaxRef} />
				<div className="relative mx-auto max-w-[520px] text-center">
					<h3 className="t-52 mb-8">
						<div>
							<span>{headingStart}&nbsp;</span>
							<PrimaryCtaWords words={headingWords} />
						</div>
						{headingEnd}
					</h3>
					<div className="grid grid-cols-1 gap-3 md:block md:space-x-6">
						{links.map((link, i) => (
							<MaybeLink key={i} className="inline-block" link={link} modal={i == 1 ? 'video' : null}>
								<Button link={link} buttonStyle={i == 0 ? 'white' : 'white-outline'} />
							</MaybeLink>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export const PrimaryCtaWords = ({ words }) => (
	<span className="inline-block relative ml-[0.1em]">
		<span className="cursor-select-cta bg-darkNavy absolute top-0 left-[-0.2em] bottom-[-0.05em] right-0"></span>
		<span className="inline-block relative">
			<Typewriter
				options={{
					autoStart: true,
					loop: true,
				}}
				onInit={(typewriter) => {
					words.map(({ word }, i) => {
						typewriter.typeString(word);
						typewriter.pauseFor(700);
						typewriter.deleteAll();
						typewriter.pauseFor(700);
					});
					typewriter.start();
				}}
			/>
		</span>
		<span className="inline-block relative my-[-0.75em]">&nbsp;</span>
	</span>
);

export const PrimaryCtaFloatingAssets = ({ parallaxRef }) => {
	return (
		<>
			<div className="absolute bottom-[95%] md:bottom-[90%] left-[86%]">
				<img className="max-w-none" src={ctaGrid1} alt="" />
				<div className="hidden md:block absolute top-[35%] right-[70%]">
					<Parallax parallaxRef={parallaxRef} properties={{ x: [-30, 30] }}>
						<img className="max-w-none" src={ctaGraphBook} alt="" />
					</Parallax>
				</div>
			</div>
			<div className="absolute top-[100%] right-[70%] md:top-[60%] md:right-[90%]">
				<img className="max-w-none" src={ctaGrid2} alt="" />
				<div className="hidden md:block absolute bottom-[72%] left-[60%]">
					<Parallax parallaxRef={parallaxRef} properties={{ x: [30, -30], y: [30, -30] }}>
						<img className="max-w-none" src={ctaAirplane} alt="" />
					</Parallax>
				</div>
			</div>
		</>
	);
};
