import React, { useRef, useState, useEffect } from 'react';
import Image from '~/components/elements/Image';
import { Player } from '@lottiefiles/react-lottie-player';

import { useInView } from 'react-intersection-observer';

export const MediaAsset = (props) => {
  return (
    <div className="media-asset overflow-hidden">
      <div className="container">
        {props.useMobileAlternative ? (
          <>
            <div className="hidden md:block">
              <MediaAssetInner className="w-full" {...props} />
            </div>
            <div className="md:hidden">
              <MediaAssetInner className="w-full" {...props.mobileMedia} />
            </div>
          </>
        ) : (
          <MediaAssetInner className="mx-auto" {...props} />
        )}
      </div>
    </div>
  );
};

export const MediaAssetInner = (props) => {
  const { type, tempSize = '40%', tempColour = 'bg-primaryBlue' } = props;
  if (type === 'image') {
    return <MediaAssetImage {...props} />;
  } else if (type === 'lottie') {
    return <MediaAssetLottie {...props} />;
  }
  return <div className={`image ${tempColour}`} style={{ paddingBottom: tempSize }}></div>;
};

export const MediaAssetImage = ({ image, className = 'relative', adjustment, mobileScale }) => {
  if (mobileScale !== 'default' && mobileScale) {
    className += ' mobile-scale-' + mobileScale.replace('.', '-');
  }
  return <Image image={image} className={className} {...getAdjustmentStyles(adjustment)} />;
};

export const MediaAssetLottie = ({ lottie, activation, hovered = false, loopFrequency = 1000, loopDelay = 0 }) => {
  const lottieRef = useRef();
  const { ref, inView } = useInView();

  loopFrequency = 16000;

  function playOrQueue() {
    if (lottieRef.current?.state?.instance?.isLoaded) {
      lottieRef.current.play();
    } else {
      window.setTimeout(function () {
        playOrQueue();
      }, 250);
    }
  }

  useEffect(() => {
    if (typeof window === undefined) return;
    if (activation === 'hover') return;
    if (activation === 'once') return;
    let timeout;
    const loop = () => {
      if (!lottieRef.current) return;
      lottieRef.current.setSeeker(0);
      playOrQueue();
      timeout = window.setTimeout(loop, parseInt(loopFrequency));
    };
    window.setTimeout(() => {
      loop();
    }, parseInt(loopDelay ? loopDelay : 100));
    return () => {
      if (timeout) window.clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (hovered) {
      if (!lottieRef.current) return;
      lottieRef.current.setSeeker(0);
      playOrQueue();
    }
  }, [hovered]);

  useEffect(() => {
    if (inView) {
      if (activation === 'once' || activation === 'auto') {
        playOrQueue();
      }
    }
  }, [inView]);

  return (
    <div ref={ref}>
      <Player
        keepLastFrame={activation === 'once'}
        looping={activation === 'auto'}
        ref={lottieRef}
        src={lottie?.localFile?.publicURL}
      />
    </div>
  );
};

export const getAdjustmentStyles = (adjustment) => {
  if (!adjustment) return {};
  const adjustBy = {};
  let adjusted = false;
  for (let key in adjustment) {
    if (key === 'fieldGroupName') continue;
    if (!adjustment[key]) continue;
    adjusted = true;
    adjustBy[key] = adjustment[key];
    if (key === 'width') {
      adjustBy.maxWidth = 'none';
    }
  }
  if (adjusted) {
    adjustBy.position = 'relative';
  }
  return adjusted ? { style: adjustBy } : {};
};
