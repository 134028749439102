import React from 'react';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';

export default function Footer({ data }) {
  if (!data) return null;
  const footer = data.footerOptions.footer;
  return (
    <footer className="container pt-[10px] pb-[35px] lg:py-[35px] lg:pb-[60px]" role="contentinfo">
      <div className="default-link-hovers md:flex lg:justify-between flex-wrap lg:flex-wrap">
        {footer.columns.map((column, i) => {
          if (column.type === 'wide') {
            return <FooterColumnWide key={i} {...column} />;
          } else {
            return <FooterColumn key={i} {...column} last={i === footer.columns.length - 1} />;
          }
        })}
      </div>
      <CopyrightBar {...footer} />
    </footer>
  );
}

const FooterColumn = ({ heading, size, last, links }) => {
  const columnClasses = [];
  if (size === '2') {
    columnClasses.push('md:w-[40%] lg:w-[210px] xl:lg:w-[250px]');
  } else {
    columnClasses.push('md:w-[40%] lg:w-[100px]');
  }
  if (!last) {
    columnClasses.push('md:pr-8 xl:pr-12');
  }
  return (
    <div className={columnClasses.join(' ')}>
      <FooterColumnHeading heading={heading} />
      <ul className="grid md:block grid-cols-2 gap-x-4 t-17-list">
        <ListItems items={links} />
      </ul>
    </div>
  );
};

const FooterColumnWide = ({ heading, subColumns }) => (
  <div className="md:pr-8 xl:pr-12 md:w-[60%] lg:flex-1">
    <FooterColumnHeading heading={heading} />
    <div className="grid grid-cols-2 gap-4">
      {subColumns.map(({ subheading, links }, i) => (
        <ul key={i} className="t-17-list">
          <li>
            <strong>{subheading}</strong>
          </li>
          <ListItems items={links} />
        </ul>
      ))}
    </div>
  </div>
);

const FooterColumnHeading = ({ heading }) => (
  <div className="pt-[30px] md:pt-[40px] overflow-hidden">
    <h6 className="t-20-heading">{heading}</h6>
    <span className="divider-line"></span>
  </div>
);

const ListItems = ({ items }) => (
  <>
    {items.map(({ link }, i) => (
      <li key={i}>
        <a href={link.url}>{link.title}</a>
      </li>
    ))}
  </>
);

const CopyrightBar = ({ copyright, socialMenu, legalMenu }) => (
  <div className="pt-12 md:pt-24 md:flex items-center text-center md:text-left">
    <div className="flex-1 t-15">
      <strong dangerouslySetInnerHTML={{ __html: copyright }} />
      <div className="pt-2 space-x-3 xl:space-x-5">
        {legalMenu.map(({ link }, i) => (
          <a href={link.url} key={i} className="opacity-50 hover:opacity-100 transition-opacity duration-500" href="#">
            {link.title}
          </a>
        ))}
      </div>
    </div>
    <div className="pt-4 md:pt-0 md:w-[150px] space-x-5 ml-auto md:text-right">
      {socialMenu.map(({ link, icon }, i) => (
        <a
          href={link.url}
          key={i}
          className="inline-block opacity-100 hover:opacity-50 transition-opacity duration-500"
          href="#"
        >
          <img src={icon.localFile.publicURL} alt={link.title} />
        </a>
      ))}
    </div>
  </div>
);

const SocialIcon = () => (
  <span className="inline-block w-[15px]">
    <MediaAssetInner tempSize="100%" />
  </span>
);
