import React from 'react';
import { MaybeLink, ArrowLink } from '~/components/elements/Link';
import Marquee from 'react-fast-marquee';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';

export const LinkMarquee = (props) => {
  const repeats = [0, 1, 2, 3, 4, 5];
  const { link, size, color } = props;
  const wrapperClasses = ['my-8 md:my-16 text-white transition-colors duration-500'];
  if (size === 'small') {
    wrapperClasses.push('');
  } else {
    wrapperClasses.push('py-4 md:py-9');
  }
  if (color === 'primaryBlue') {
    wrapperClasses.push('bg-primaryBlue hover:bg-darkNavy');
  } else {
    wrapperClasses.push('bg-darkNavy hover:bg-primaryBlue');
  }
  return (
    <MaybeLink link={link} className={wrapperClasses.join(' ')}>
      <Marquee gradient={false} speed={40}>
        {repeats.map((_, i) => (
          <LinkMarqueeInner key={i} {...props} />
        ))}
      </Marquee>
    </MaybeLink>
  );
};

export const LinkMarqueeInner = ({ link, icon, color, size, extraText }) => {
  link = link.link;
  return (
    <div className="flex items-center space-x-4 t-20-heading">
      {icon && <MediaAssetInner {...icon} />}
      {size !== 'small' && (
        <>
          <span>{link.title}</span>
          <DividerLine />
        </>
      )}
      {extraText && (
        <>
          <ArrowLink primaryBlue={color !== 'primaryBlue'} link={{ title: extraText }} />
          <DividerLine />
        </>
      )}
      <span>
        <ArrowLink primaryBlue={color !== 'primaryBlue'} link={{ title: size === 'small' ? link.title : 'Read Now' }} />
      </span>
      <DividerLine />
      <span></span>
    </div>
  );
};

export const DividerLine = () => (
  <span className="pt-[6px] px-[10px] lg:px-[14px]">
    <span className="marquee-divider-line"></span>
  </span>
);
