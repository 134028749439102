import React from 'react';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';

export const WorkflowTwoColumns = ({ subheading, heading, contentA, contentB, image }) => {
  return (
    <div className="text-center md:text-left container">
      <div className="space-y-4 md:space-y-8 px-6 md:px-10 py-8 md:py-10 lg:px-16 lg:py-16 block bg-tertiaryGrey border-default-grid">
        <div className="flex flex-col-reverse md:flex-row md:justify-between md:items-start">
          <div className="max-w-[680px] space-y-5">
            <h4 className="t-subheading">{subheading} </h4>
            <h2 className="t-42">{heading}</h2>
          </div>
          <div className="hidden md:block pb-6 md:pb-0 mx-auto md:mr-0 w-[150px] ml-auto md:relative md:top-[-10px] md:right-[-10px] lg:top-[-25px] lg:right-[-20px]">
            <MediaAssetInner className="w-full max-w-none" {...image} />
          </div>
        </div>

        <div className="opacity-90 t-17 md:flex md:flex-row space-y-6 md:space-y-0 md:space-x-6 lg:space-x-8 xl:space-x-16">
          <div className="md:max-w-[440px]" dangerouslySetInnerHTML={{ __html: contentA }} />
          <div className="md:max-w-[402px]" dangerouslySetInnerHTML={{ __html: contentB }} />
        </div>
      </div>
    </div>
  );
};
