import React from 'react';
import { MaybeLink, ArrowLink, Button } from '~/components/elements/Link';

export const TextContent = ({
  visualControls,
  centered = true,
  innerOnly = false,
  subheading,
  heading,
  headingSize,
  subheadingStyle,
  isMasthead,
  content,
  link,
  linkStyle,
  links,
  contentOpacity = 80,
  visibleFields = ['heading', 'subheading', 'content', 'link'],
}) => {
  if (!visualControls) visualControls = { restrictWidth: {} };
  if (link && !link.type) link = { link };

  const classes = {
    outerContainer: [],
    container: ['text-center space-y-3 lg:space-y-5'],
    subheading: [],
    heading: ['pt-1'],
    content: ['t-20'],
  };

  if (contentOpacity === 80) {
    classes.content.push('opacity-80');
  } else if (contentOpacity === 90) {
    classes.content.push('opacity-90');
  }

  if (!innerOnly) {
    classes.outerContainer.push('container');
  }

  if (centered) {
    classes.container.push('mx-auto');
    if (!visualControls.restrictWidth?.main) {
      classes.container.push('max-w-[900px]');
    }
    if (visualControls.restrictWidth?.content) {
      classes.content.push('mx-auto');
    }
    if (visualControls.restrictWidth?.heading) {
      classes.heading.push('mx-auto');
    }
  } else {
    classes.container.push('md:text-left');
    if (visualControls.restrictWidth?.main) {
      classes.container.push('mx-auto md:mx-0');
    }
    if (visualControls.restrictWidth?.content) {
      classes.content.push('mx-auto md:mx-0');
    }
    if (visualControls.restrictWidth?.heading) {
      classes.heading.push('mx-auto md:mx-0');
    }
  }

  if (headingSize === '60') {
    classes.heading.push('t-60');
    classes.container.push('xl:space-y-6');
  } else if (headingSize === '54') {
    classes.heading.push('t-54');
  } else if (headingSize === '52') {
    classes.heading.push('t-52');
  } else if (headingSize === '36') {
    classes.heading.push('t-36');
  } else if (headingSize === '42-small') {
    classes.heading.push('t-42-small');
  } else {
    classes.heading.push('t-42');
  }

  if (subheadingStyle === 'plain') {
    classes.subheading.push('is-plain');
  }

  let HeadingTag = 'h3';
  let SubheadingTag = 'h4';
  if (isMasthead) {
    HeadingTag = 'h1';
    SubheadingTag = 'h2';
  } else if (headingSize === '60') {
    HeadingTag = 'h2';
    SubheadingTag = 'h3';
  }

  if (subheadingStyle === 'plain') {
    classes.subheading.push('t-subheading-plain');
  } else if (subheadingStyle === 'light') {
    classes.subheading.push('t-subheading is-grey');
  } else {
    classes.subheading.push('t-subheading');
  }
  return (
    <TextContentWrapper innerOnly={innerOnly} className={classes.outerContainer.join(' ')}>
      <div className={classes.container.join(' ')} {...maxWidthProps(visualControls.restrictWidth?.main)}>
        {subheading && visibleFields.includes('subheading') && (
          <SubheadingTag className={classes.subheading.join(' ')}>{subheading}</SubheadingTag>
        )}
        {heading && visibleFields.includes('heading') && (
          <HeadingTag
            {...maxWidthProps(visualControls.restrictWidth?.heading)}
            className={classes.heading.join(' ')}
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        )}
        {content && visibleFields.includes('content') && (
          <div
            {...maxWidthProps(visualControls.restrictWidth?.content)}
            className={classes.content.join(' ')}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
        {link && visibleFields.includes('link') && link.link != null && (
          <MaybeLink link={link} className="pt-2">
            {linkStyle === 'button' ? <Button buttonStyle="primary" link={link} /> : <ArrowLink link={link} />}
          </MaybeLink>
        )}
        {links && (
          <div className="grid grid-cols-1 gap-3 pt-3 md:block md:space-x-6 md:pt-0">
            {links.map((link, i) => (
              <MaybeLink key={i} className="inline-block" link={link} modal={link.video}>
                <Button link={link} buttonStyle={i == 0 ? 'primary' : 'outline'} icon={i == 1 ? 'play' : null} />
              </MaybeLink>
            ))}
          </div>
        )}
      </div>
    </TextContentWrapper>
  );
};

export const maxWidthProps = (setting) => {
  if (!setting) return {};
  return {
    style: {
      maxWidth: `${setting}px`,
    },
  };
};

export const TextContentWrapper = ({ innerOnly, className, children, style = null }) =>
  innerOnly ? (
    children
  ) : (
    <div className={className} style={style}>
      {children}
    </div>
  );
