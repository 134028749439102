import React from 'react';
import { MaybeLink, ArrowLink } from '~/components/elements/Link';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const ImageGrid = ({ items }) => {
	return (
		<InViewAnimator
			className="container text-center"
			inner={(controls) => (
				<div className="grid grid-cols-1 md:grid-cols-2 gap-6 gap-y-12">
					{items.map((item, i) => (
						<InViewAnimatorBasicItem key={`image-grid-${i}`} controls={controls} delay={i / 6} duration={1.5}>
							<ImageGridItem key={i} {...item} />
						</InViewAnimatorBasicItem>
					))}
				</div>
			)}
		/>
	);
};

export const ImageGridItem = ({ main, image }) => {
	const { heading, content, link } = main;
	return (
		<MaybeLink className="space-y-3 xl:space-y-5" link={link}>
			<div className="w-full">
				<MediaAssetInner className="mx-auto" {...image} />
			</div>
			<h3 className="t-24 pt-4 xl:pt-9">{heading}</h3>
			<p className="t-17 opacity-80 max-w-[500px] mx-auto">{content}</p>
			{link && link.link && (
				<div>
					<ArrowLink link={link} />
				</div>
			)}
		</MaybeLink>
	);
};
