import React, { useState, useEffect } from 'react';
import { TextContent } from '~/components/flexible/TextContent';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { MaybeLink, ArrowLink } from '~/components/elements/Link';
import mastheadBackground from '~/assets/icons/about-masthead-background.svg';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { AnimatePresence, motion } from 'framer-motion';
import { HeightFixer } from '~/components/elements/Animator';

export const MastheadAbout = (props) => {
  return (
    <div>
      <MastheadAboutHead {...props.mastheadContent} />
      <MasthedAboutImages {...props.imageContent} />
      <div className="section section-top section-bot">
        <MasthedAboutHistory {...props.historyContent} />
        <MasthedAboutFinal {...props.finalContent} />
      </div>
    </div>
  );
};

export const MastheadAboutHead = ({ subheading, heading, content }) => (
  <InViewAnimator
    className="relative pt-8 bg-darkNavy lg:pt-24 xl:pt-40"
    inner={(controls) => (
      <>
        <img className="absolute bottom-0 left-0 hidden md:block" src={mastheadBackground} alt="" />
        <div className={`relative container section-header-padding`}>
          <div className="lg:flex flex-row md:gap-10 xl:gap-20 pb-[24%] lg:pb-[26%]">
            <InViewAnimatorBasicItem controls={controls} delay={0} prop="y" start={50} className="max-w-[539px]">
              <h2 className="font-semibold uppercase t-17 text-torqouise">{subheading}</h2>
              <h1 className="pt-4 text-white t-54">{heading}</h1>
            </InViewAnimatorBasicItem>
            <InViewAnimatorBasicItem
              controls={controls}
              delay={0.3}
              prop="y"
              start={25}
              className="max-w-[588px] mt-6 md:mt-10"
            >
              <div className="prose text-white t-20" dangerouslySetInnerHTML={{ __html: content }} />
            </InViewAnimatorBasicItem>
          </div>
        </div>
      </>
    )}
  />
);

export const MasthedAboutImages = ({ main, popupA, popupB }) => (
  <InViewAnimator
    className="container"
    inner={(controls) => (
      <div className="max-w-[920px] mx-auto relative shadow-largeAsset mt-[-10%] lg:mt-[-15%]">
        <InViewAnimatorBasicItem controls={controls} delay={0.4} prop="y" start={50} className="relative">
          <MediaAssetInner type="image" image={main} />
        </InViewAnimatorBasicItem>
        <InViewAnimatorBasicItem
          controls={controls}
          delay={0.95}
          prop="y"
          start={80}
          className="absolute bottom-[-20px] md:bottom-[-40px] left-[-120px] md:left-[-20px] xl:left-[-80px]"
        >
          <div class="scale-[0.3] md:scale-100">
            <MediaAssetInner type="image" image={popupA} />
          </div>
        </InViewAnimatorBasicItem>
        <InViewAnimatorBasicItem
          controls={controls}
          delay={0.75}
          prop="y"
          start={80}
          className="absolute top-[90px] right-[-80px] md:right-[-20px] xl:right-[-130px]"
        >
          <div class="scale-[0.3] md:scale-100">
            <MediaAssetInner type="image" image={popupB} />
            <div className="absolute top-[43.5%] left-[47.5%] w-[3.75%] h-[14%] bg-[#EEF1F7] blinker blink-c"></div>
            <div className="absolute top-[43.5%] left-[43.5%] w-[3.75%] h-[14%] bg-[#EEF1F7] blinker blink-b"></div>
            <div className="absolute top-[43.5%] left-[39.75%] w-[3.75%] h-[14%] bg-[#EEF1F7] blinker blink-a"></div>
          </div>
        </InViewAnimatorBasicItem>
      </div>
    )}
  />
);

export const MasthedAboutHistory = ({ items, heading, content }) => {
  const [active, setActive] = useState(0);
  useEffect(() => {
    if (typeof window == 'undefined') return null;
    const historyItems = document.querySelectorAll('.history-items');
    const scroll = () => {
      if (!historyItems || historyItems.length === 0) return null;
      const scrollTop = window.scrollY;
      const offsetValue = window.outerHeight / 4;
      let nextActive = 0;
      for (let index in historyItems) {
        const el = historyItems[index];
        if (!el.getBoundingClientRect) continue;
        const offsetTop = el.getBoundingClientRect().top + document.documentElement.scrollTop;
        if (offsetTop - offsetValue < scrollTop) {
          nextActive = parseInt(index) + 1;
        }
      }
      if (nextActive > items.length - 1) nextActive = items.length - 1;
      setActive(nextActive);
    };
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);
  return (
    <HeightFixer extraHeight={60} className="container">
      <TextContent heading={heading} content={content} visualControls={{ restrictWidth: { content: '770' } }} />

      <div className="mt-12 lg:mt-28 space-y-4 max-w-[780px] mx-auto">
        {items.map((item, i) => (
          <HistoryItem key={i} {...item} isLast={i === items.length - 1} isActive={active === i} />
        ))}
      </div>
    </HeightFixer>
  );
};

export const HistoryItem = ({ heading, description, year, highLight, isLast, isActive }) => {
  return (
    <div className={`history-items transition-opacity dumasration-500 ${isActive ? '' : 'opacity-50'}`}>
      <div className="flex">
        <div className="hidden md:block w-[55px] t-24">{year}</div>
        <div className={`block mx-3 lg:mx-6 xl:mx-12 pt-0.5 lg:pt-1.5 relative`}>
          <div className="flex flex-row items-center justify-center w-5 h-5 border">
            <div
              className={`w-2 h-2 bg-primaryBlue transition-opacity duration-500 ${
                isActive ? 'opacity-100' : 'opacity-0'
              }`}
            ></div>
          </div>
          {!isLast && (
            <div className="absolute top-12 left-[9px] bottom-0 border-l-2 border-dashed border-black opacity-40" />
          )}
        </div>
        <div className="flex-1 pb-8 md:pb-16 lg:pb-32">
          <h4 className="pb-2 md:hidden t-20-heading">{year}</h4>
          <h3 className="pb-2 t-24 md:pb-4">{heading}</h3>
          <AnimatePresence>
            {isActive && (
              <motion.div
                transition={{ type: 'tween' }}
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1, duration: 1.5 }}
                exit={{ height: 0, opacity: 0, duration: 0.5 }}
                className="max-w-[588px]"
              >
                <p className="t-20">{description}</p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};
export const MasthedAboutFinal = ({ content, articleHeading, articleLink, articleLogo }) => (
  <div className="container pt-2 text-center md:pt-8 lg:pt-16">
    <div
      className="t-20 opacity-90 mx-auto max-w-[775px] mb-20 lg:mb-32"
      dangerouslySetInnerHTML={{ __html: content }}
    />
    <MaybeLink
      className="border-default-grid bg-fadedBlue max-w-[588px] mx-auto p-8 lg:p-16 space-y-[22px]"
      link={articleLink}
    >
      <MediaAssetInner className="mx-auto" type="image" image={articleLogo} />
      <div className="t-36">{articleHeading}</div>
      <ArrowLink link={articleLink} />
    </MaybeLink>
  </div>
);
