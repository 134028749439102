import React from 'react';
import { MaybeLink, ArrowLink } from '~/components/elements/Link';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';

export const ContactCta = (props) => {
  const { media, heading, address, link, image, backgroundImage } = props;
  return (
    <div className="container">
      <div className="flex flex-col lg:flex-row justify-between gap-6">
        <div className="lg:flex-1">
          <MaybeLink link={link} className="bg-[#FEF5E7] border border-default-grid">
            <div className="relative overflow-hidden p-6 md:p-12 xl:py-16">
              <MediaAssetInner
                className="absolute max-h-full right-[-20%] xs:right-[-5%] top-[10%] md:right-0 md:top-0 xl:top-4 lg:right-[-25%] xl:right-[-6%]"
                type="image"
                image={backgroundImage}
              />
              <div className="relative space-y-5">
                <h4 className="t-24">{heading}</h4>
                <div className="t-20 whitespace-pre">{address}</div>
                <ArrowLink link={link} />
              </div>
            </div>
          </MaybeLink>
        </div>
        <div className="lg:w-[50%] border-dark-grid">
          <MediaAssetInner type="image" image={image} />
        </div>
      </div>
    </div>
  );
};
