import React from 'react';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { MaybeLink, Button, ArrowLink } from '~/components/elements/Link';
import { TextContent } from '~/components/flexible/TextContent';
import { Link } from 'react-scroll';
import trophyLeft from '~/assets/icons/trophy-left.svg';
import trophyRight from '~/assets/icons/trophy-right.svg';

export const Newsroom = (page) => {
	const { awards, contactBlock, header, newsBlockA, newsBlockB, tabs } = page.newsroom;
	const _newsBlockB = newsBlockB.concat(newsBlockB).concat(newsBlockB);
	return (
		<div className="overflow-hidden">
			<NewsroomHeader {...header} />
			<NewsroomTabs tabs={tabs} />
			<div id="recent-news">
				<NewsroomNewsGrid items={newsBlockA} />
			</div>
			<NewsroomAwards {...awards} />
			<NewsroomNewsGrid items={_newsBlockB} />
			<NewsroomContact items={contactBlock} />
		</div>
	);
};

export const NewsroomHeader = ({ heading, content, asset, link }) => (
	<InViewAnimator
		className="relative container section-header-padding"
		inner={(controls) => (
			<div className="relative md:flex items-center py-4 md:py-8 lg:py-16 xl:py-24">
				<InViewAnimatorBasicItem
					controls={controls}
					delay={0.1}
					prop="y"
					start={50}
					className="w-[80%] mx-auto md:ml-0 md:w-[30%] lg:w-[42%] md:mr-20 xl:mr-40"
				>
					<MediaAssetInner {...asset} />
				</InViewAnimatorBasicItem>
				<InViewAnimatorBasicItem controls={controls} delay={0.4} prop="y" start={50} className="flex-1 py-8">
					<TextContent
						isMasthead={true}
						headingSize="54"
						centered={false}
						heading={heading}
						content={content}
						link={link}
						visualControls={{
							restrictWidth: {
								content: 500,
							},
						}}
					/>
				</InViewAnimatorBasicItem>
			</div>
		)}
	/>
);

export const NewsroomTabs = ({ tabs }) => (
	<div className="container grid grid-cols-2 gap-3 md:gap-0 md:flex md:space-x-4 lg:space-x-7 justify-center text-center py-8 lg:py-16">
		{tabs.map((tab, i) => (
			<Link
				className="cursor-pointer t-17 font-medium border border-strokeGrey hover:border-primaryBlue hover:text-white hover:bg-primaryBlue transition-colors duration-500 md:w-[180px] lg:w-[195px] px-1 py-3"
				key={i}
				to={tab.link.url.replace('#', '')}
				smooth={true}
				offset={-80}
				duration={500}
			>
				{tab.link.title}
			</Link>
		))}
	</div>
);

export const NewsroomNewsGrid = ({ items }) => (
	<InViewAnimator
		className="text-center md:text-left container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-12 md:gap-10 md:gap-y-20 xl:gap-16 xl:gap-y-28 py-8 lg:py-16"
		inner={(controls) => (
			<>
				{items.map((item, i) => (
					<InViewAnimatorBasicItem key={`news-grid-${i}`} controls={controls} delay={i / 6} duration={1.5}>
						<NewsroomNewsGridItem key={i} {...item} />
					</InViewAnimatorBasicItem>
				))}
			</>
		)}
	/>
);

export const NewsroomNewsGridItem = ({ heading, image, link }) => (
	<MaybeLink className="space-y-5" link={link}>
		<div class="border-default-grid p-[15px]">
			<div className="border border-lightBlue">
				<MediaAssetInner type="image" image={image} />
			</div>
		</div>
		<h3 className="t-20-heading pt-1">{heading}</h3>
		<ArrowLink link={link} />
	</MaybeLink>
);

export const NewsroomAwards = ({ heading, awards }) => (
	<div id="awards" className="text-center bg-darkNavy text-white my-8 lg:my-24 py-16 lg:py-32">
		<InViewAnimator
			className="container"
			inner={(controls) => (
				<div className="border-primary-grid">
					<div class="border-b border-primaryBlue flex justify-center items-center md:space-x-12 lg:space-x-28 py-8 md:py-12 lg:py-20 px-10 lg:px-20">
						<InViewAnimatorBasicItem
							className="hidden md:block"
							controls={controls}
							delay={0.6}
							duration={1.5}
							prop="x"
							start="80"
						>
							<img src={trophyLeft} alt="" />
						</InViewAnimatorBasicItem>
						<InViewAnimatorBasicItem controls={controls} delay={0.3} duration={1.5}>
							<h2 className="t-42">{heading}</h2>
						</InViewAnimatorBasicItem>
						<InViewAnimatorBasicItem
							className="hidden md:block"
							controls={controls}
							delay={0.6}
							duration={1.5}
							prop="x"
							start="-80"
						>
							<img className="hidden md:block" src={trophyRight} alt="" />
						</InViewAnimatorBasicItem>
					</div>
					<div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
						{awards.map((item, i) => (
							<NewsroomAwardsItem key={i} controls={controls} delay={0.6 + i / 6} {...item} />
						))}
					</div>
				</div>
			)}
		/>
	</div>
);

export const NewsroomAwardsItem = ({ image, heading, year, isLast, controls, delay }) => (
	<div
		className={`p-6 lg:p-8 py-6 md:py-12 lg:py-20 border-r border-primaryBlue border-b lg:border-b-0 mb-[-1px] lg:mb-0 mr-[-1px]`}
	>
		<InViewAnimatorBasicItem controls={controls} delay={delay} duration={1.5}>
			<MediaAssetInner className="h-[100px] md:h-auto mx-auto" type="image" image={image} />
			<h3 className="t-24 pt-6 lg:pt-10 pb-2">{heading}</h3>
			<h4 className="t-17 opacity-60">{year}</h4>
		</InViewAnimatorBasicItem>
	</div>
);

export const NewsroomContact = ({ items }) => (
	<div id="contact" className="">
		<InViewAnimator
			className="text-center md:text-left container grid grid-cols-1 md:grid-cols-2 gap-6 mb-16 mt-12 lg:mt-24 md:mb-32"
			inner={(controls) => (
				<>
					{items.map((item, i) => (
						<InViewAnimatorBasicItem key={`news-grid-${i}`} controls={controls} delay={i / 6} duration={1.5}>
							<NewsroomContactItem key={i} {...item} />
						</InViewAnimatorBasicItem>
					))}
				</>
			)}
		/>
	</div>
);

export const NewsroomContactItem = ({ heading, content, link }) => (
	<MaybeLink
		className="bg-fadedBlue hover:bg-fadedBlueHover transition-colors duration-400 p-[35px] md:p-[40px] lg:p-[60px] flex flex-col h-full xl:min-h-[300px]"
		link={link}
	>
		<h3 className="t-24">{heading}</h3>
		<p className="t-17 opacity-80 py-3 lg:py-6">{content}</p>
		<div className="mt-auto">
			<ArrowLink link={link} />
		</div>
	</MaybeLink>
);
