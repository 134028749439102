import React from 'react';
import { TextContent } from '~/components/flexible/TextContent';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import Marquee from 'react-fast-marquee';
import { MaybeLink, Button } from '~/components/elements/Link';
import homeGrid1 from '~/assets/icons/home-grid-1.svg';
import homeGrid2 from '~/assets/icons/home-grid-2.svg';
import dashboardImage from '~/assets/images/dashboard.png';
import { Player } from '@lottiefiles/react-lottie-player';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const MastheadHome = (props) => {
	const { heading, content, links, clientsHeading, logos, link, asset, imageLeft, imageRight } = props;
	return (
		<InViewAnimator
			className="overflow-hidden section-header-padding"
			inner={(controls) => (
				<>
					<div className="absolute top-0 bottom-40 md:bottom-96 left-0 right-0 bg-tertiaryGrey"></div>
					<div className="container relative">
						<div className="relative md:flex items-center">
							<InViewAnimatorBasicItem
								controls={controls}
								delay={0.2}
								prop="y"
								start={-50}
								className="pt-3 pt-12 lg:pt-20 md:pb-12 xl:pb-20 xl:pt-44 md:w-[65%] xl:w-[58%] shrink-0"
							>
								<TextContent
									visualControls={{ restrictWidth: { main: '700' } }}
									isMasthead={true}
									centered={false}
									{...props}
									link={null}
									headingSize="60"
									contentOpacity={100}
								/>
							</InViewAnimatorBasicItem>
							<InViewAnimatorBasicItem
								controls={controls}
								delay={0.5}
								prop="x"
								start={150}
								className="flex-1 md:ml-12 lg:ml-20 pt-8 xl:pt-12"
							>
								<MediaAssetInner
									className="w-[70%] mx-auto md:mx-0 md:w-[555px] md:max-w-none md:mt-[0] xl:mt-[-50px] md:mb-[-140px]"
									type="image"
									image={imageRight}
								/>
							</InViewAnimatorBasicItem>
						</div>
						<InViewAnimatorBasicItem controls={controls} delay={0.3} prop="y" start={-50}>
							<MastheadHomeLogos heading={clientsHeading} logos={logos} />
						</InViewAnimatorBasicItem>
						<MastheadHomeVideo link={link} asset={asset} />
					</div>
				</>
			)}
		/>
	);
};

export const MastheadHomeLogos = ({ heading, logos }) => {
	logos = logos.concat(logos);
	return (
		<div className="pt-8 pb-20 lg:py-24 t-20-heading text-center">
			<h6>{heading}</h6>
			<div className="pt-3 relative marquee-gradient">
				<Marquee gradient={false} speed={40}>
					{logos.map((logo, i) => (
						<div key={i} className="w-[150px] md:w-[185px] mx-[-8px] md:mx-0 py-0 px-0 md:py-3 md:px-2">
							<MediaAssetInner type="image" image={logo} />
						</div>
					))}
				</Marquee>
			</div>
		</div>
	);
};

export const MastheadHomeVideo = ({ link, asset }) => {
	return (
		<div className="pb-24">
			<div className="max-w-[920px] mx-auto relative shadow-largeAsset">
				<div className="absolute top-[-41px] left-[-51px]">
					<img className="max-w-none" src={homeGrid1} alt="" />
				</div>
				<div className="absolute bottom-[-32px] right-[-75px]">
					<img className="max-w-none" src={homeGrid2} alt="" />
				</div>
				<div className="relative">
					<MediaAssetInner {...asset} />
					{false && <Player src="/HomepageAnimation.json" autoplay={true} />}
					<MaybeLink
						className="absolute w-[210px] md:w-auto top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 inline-block"
						link={link}
					>
						<Button link={link} buttonStyle="primary" />
					</MaybeLink>
				</div>
			</div>
		</div>
	);
};
