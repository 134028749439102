import React, { useState, useEffect } from 'react';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { CarouselArrowButton, MaybeLink } from '~/components/elements/Link';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

export const CustomerStories = ({ items }) => {
	const [active, set_active] = useState(0);

	return (
		<div>
			<div className="container">
				<div className="relative customer-stories-swiper">
					<CustomerStoriesArrowNav items={items} active={active} set_active={set_active} />
				</div>
			</div>
			<div className="overflow-hidden">
				<div className="container">
					<div className="pt-6 pb-6 lg:pt-12 lg:pb-16 xl:pb-20">
						<CustomerStoriesSwiper items={items} active={active} set_active={set_active} />
					</div>
					<CustomerStoriesLogoNav items={items} active={active} set_active={set_active} />
				</div>
			</div>
		</div>
	);
};

export const CustomerStoriesSwiper = ({ items, active, set_active }) => {
	const [swiper, set_swiper] = useState({});

	useEffect(() => {
		if (swiper.on) {
			swiper.on('slideChange', function () {
				set_active(swiper.activeIndex);
			});
		}
	}, [swiper]);

	useEffect(() => {
		if (swiper.slideTo && swiper.activeIndex !== active) {
			swiper.slideTo(active);
		}
	}, [swiper, active]);

	return (
		<Swiper
			onInit={(ev) => {
				set_swiper(ev);
			}}
			breakpoints={{
				0: {
					spaceBetween: 10,
					slidesPerView: 1.1,
				},
				768: {
					spaceBetween: 20,
					slidesPerView: 1.45,
				},
			}}
		>
			{items.map((slide, i) => (
				<SwiperSlide key={i}>
					<CustomerStoriesSwiperItem {...slide} active={active === i} />
				</SwiperSlide>
			))}
		</Swiper>
	);
};

export const CustomerStoriesSwiperItem = ({ link, postTypeCustomerStory, active }) => {
	if (!postTypeCustomerStory) return null;

	const { logo, profilePicture, name, jobTitle, quote } = postTypeCustomerStory;
	return (
		<MaybeLink
			link={{ url: link }}
			className={`h-full case-study-box transition-color duration-1000 ${active ? ' is-active' : ''}`}
		>
			<div className="relative">
				<div className="w-[120px] h-[45px] py-2 px-2 md:py-0 md:px-0 md:w-[153px] md:h-[68px] bg-white border-profile-logo flex flex-col justify-center">
					<MediaAssetInner className="mx-auto" type="image" image={logo} />
				</div>
				<h4 className="t-24-carousel mt-4 md:mt-6 mb-4 lg:mt-16 lg:mb-11 lg:min-h-[135px]">{quote}</h4>
				<div className="space-y-2 md:space-y-0 md:flex items-center md:space-x-3">
					<div className="w-[22px]">
						<MediaAssetInner type="image" image={profilePicture} />
					</div>
					<span className="inline-block">
						<strong>{name},</strong> {jobTitle}
					</span>
				</div>
			</div>
		</MaybeLink>
	);
};

export const CustomerStoriesLogoNav = ({ items, active, set_active }) => {
	return (
		<div className="text-center space-x-4 lg:space-x-8 xl:space-x-12 flex items-center justify-center">
			{items.map((slide, i) => {
				if (!slide.postTypeCustomerStory) return null;
				const { logo } = slide.postTypeCustomerStory;
				const isActive = i === active;
				const classes = ['w-[50px] lg:w-[120px] transition-opacity duration-500 cursor-pointer'];
				if (!isActive) {
					classes.push('opacity-40 hover:opacity-80');
				}
				return (
					<div key={i} onClick={() => set_active(i)} className={classes.join(' ')}>
						<MediaAssetInner className="mx-auto" type="image" image={logo} />
					</div>
				);
			})}
		</div>
	);
};

export const CustomerStoriesArrowNav = ({ items, active, set_active }) => {
	return (
		<div className="hidden lg:block absolute lg:top-[-80px] xl:top-[-120px] right-0 space-x-1.5">
			<CarouselArrowButton
				flip={true}
				active={active !== 0}
				onClick={() => {
					set_active(active - 1);
				}}
			/>
			<CarouselArrowButton
				active={active !== items.length - 1}
				onClick={() => {
					set_active(active + 1);
				}}
			/>
		</div>
	);
};
