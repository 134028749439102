import React from 'react';
import { TextContent } from '~/components/flexible/TextContent';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';

export const TwoColumns = (props) => {
  const { flipColumns, asset, columnA, columnB, reverseMobile } = props;

  console.log(reverseMobile);

  const classes = {
    container: ['container md:flex lg:items-center space-y-10 md:space-y-0'],
    column_a: ['md:w-[30%] lg:w-[46.5%] xl:w-[50%]'],
    column_b: ['flex-1'],
    column_b_inner: [],
  };

  // Margin and column order
  if (flipColumns) {
    classes.container.push('md:flex-row-reverse');
    classes.column_a.push('md:ml-12 lg:ml-12 xl:ml-12');
  } else {
    classes.column_a.push('md:mr-12 lg:mr-12 xl:mr-24');
  }

  if (reverseMobile) {
    classes.container.push('flex flex-col flex-col-reverse space-y-0');
    classes.column_a.push('mt-12  md:mt-0');
  } else {
    classes.column_a.push('');
  }

  // Inner classes for text position
  let restrictWidth = '500';
  if (flipColumns) {
    classes.column_b.push('lg:ml-[30px]');
    restrictWidth = '460';
  }

  return (
    <div className="overflow-hidden">
      <div className={classes.container.join(' ')}>
        <div className={classes.column_a.join(' ')}>
          <MediaAssetInner className="w-full mx-auto md:mx-0" {...columnB} />
        </div>
        <div className={classes.column_b.join(' ')}>
          <TextContent
            visualControls={{
              restrictWidth: {
                main: restrictWidth,
              },
            }}
            innerOnly={false}
            {...columnA}
            centered={false}
            headingSize={columnA?.headingSize ? columnA?.headingSize : '36'}
          />
        </div>
      </div>
    </div>
  );
};
