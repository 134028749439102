import React from 'react';
import { TextContent } from '~/components/flexible/TextContent';
import { MediaAsset } from '~/components/flexible/MediaAsset';
import { TwoColumns } from '~/components/flexible/TwoColumns';
import { Carousel } from '~/components/flexible/Carousel';
import { ResourcesGrid } from '~/components/flexible/ResourcesGrid';
import { FeaturedIntegrations } from '~/components/flexible/FeaturedIntegrations';
import { PrimaryCta } from '~/components/flexible/PrimaryCta';
import { CustomerStories } from '~/components/flexible/CustomerStories';
import { MastheadHome } from '~/components/flexible/MastheadHome';
import { IconGrid } from '~/components/flexible/IconGrid';
import { PagesCta } from '~/components/flexible/PagesCta';
import { UpNext } from '~/components/flexible/UpNext';
import { Testimonials } from '~/components/flexible/Testimonials';
import { MetricsMarquee } from '~/components/flexible/MetricsMarquee';
import { ImageGrid } from '~/components/flexible/ImageGrid';
import { TwoColumnPullout } from '~/components/flexible/TwoColumnPullout';
import { LinkMarquee } from '~/components/flexible/LinkMarquee';
import { ArticleCta } from '~/components/flexible/ArticleCta';
import { Testimonial } from '~/components/flexible/Testimonial';
import { MastheadStats } from '~/components/flexible/MastheadStats';
import { AccordionCarousel } from '~/components/flexible/AccordionCarousel';
import { Statements } from '~/components/flexible/Statements';
import { StatementsCarousel } from '~/components/flexible/StatementsCarousel';
import { MastheadTeams } from '~/components/flexible/MastheadTeams';
import { Leadership } from '~/components/flexible/Leadership';
import { Logos } from '~/components/flexible/Logos';
import { MastheadPlatform } from '~/components/flexible/MastheadPlatform';
import { MastheadStrategicFinance } from '~/components/flexible/MastheadStrategicFinance';
import { MastheadAbout } from '~/components/flexible/MastheadAbout';
import { WorkflowTwoColumns } from '~/components/flexible/WorkflowTwoColumns';
import { WorkflowNextUp } from '~/components/flexible/WorkflowNextUp';
import { WorkflowConnection } from '~/components/flexible/WorkflowConnection';
import { ContactCta } from '~/components/flexible/ContactCta';
import { CareerListings } from '~/components/flexible/CareerListings';
import { TeamCarousel } from '~/components/flexible/TeamCarousel';
import { ListGridWithImage } from '~/components/flexible/ListGridWithImage';
import { MastheadCareers } from '~/components/flexible/MastheadCareers';
import { MastheadPlans } from '~/components/flexible/MastheadPlans';
import { ProductsTable } from '../flexible/PlansTable';
import { PlansCalculator } from '../flexible/PlansCalculator';
import { BenefitsGrid } from '../flexible/BenefitsGrid';
import { Faqs } from '../flexible/Faqs';

export const ContentRenderer = ({ prefix, content = [] }) => {
  return (
    <>
      {content.map((layout, i) => {
        const layoutName = layout.fieldGroupName.replace(prefix, '');
        if (layoutName === 'TextContent') {
          return <TextContent key={i} {...layout} />;
        } else if (layoutName === 'MediaAsset') {
          return <MediaAsset key={i} {...layout} />;
        } else if (layoutName === 'TwoColumns') {
          return <TwoColumns key={i} {...layout} />;
        } else if (layoutName === 'Carousel') {
          return <Carousel key={i} {...layout} />;
        } else if (layoutName === 'ResourcesGrid') {
          return <ResourcesGrid key={i} {...layout} />;
        } else if (layoutName === 'FeaturedIntegrations') {
          return <FeaturedIntegrations key={i} {...layout} />;
        } else if (layoutName === 'PrimaryCta') {
          return <PrimaryCta key={i} {...layout} />;
        } else if (layoutName === 'CustomerStories') {
          return <CustomerStories key={i} {...layout} />;
        } else if (layoutName === 'MastheadHome') {
          return <MastheadHome key={i} {...layout} />;
        } else if (layoutName === 'IconGrid') {
          return <IconGrid key={i} {...layout} />;
        } else if (layoutName === 'PagesCta') {
          return <PagesCta key={i} {...layout} />;
        } else if (layoutName === 'UpNext') {
          return <UpNext key={i} {...layout} />;
        } else if (layoutName === 'Testimonials') {
          return <Testimonials key={i} {...layout} />;
        } else if (layoutName === 'MetricsMarquee') {
          return <MetricsMarquee key={i} {...layout} />;
        } else if (layoutName === 'ImageGrid') {
          return <ImageGrid key={i} {...layout} />;
        } else if (layoutName === 'TwoColumnPullout') {
          return <TwoColumnPullout key={i} {...layout} />;
        } else if (layoutName === 'LinkMarquee') {
          return <LinkMarquee key={i} {...layout} />;
        } else if (layoutName === 'ArticleCta') {
          return <ArticleCta key={i} {...layout} />;
        } else if (layoutName === 'Testimonial') {
          return <Testimonial key={i} {...layout} />;
        } else if (layoutName === 'MastheadStats') {
          return <MastheadStats key={i} {...layout} />;
        } else if (layoutName === 'AccordionCarousel') {
          return <AccordionCarousel key={i} {...layout} />;
        } else if (layoutName === 'Statements') {
          return <Statements key={i} {...layout} />;
        } else if (layoutName === 'StatementsCarousel') {
          return <StatementsCarousel key={i} {...layout} />;
        } else if (layoutName === 'MastheadTeams') {
          return <MastheadTeams key={i} {...layout} />;
        } else if (layoutName === 'Leadership') {
          return <Leadership key={i} {...layout} />;
        } else if (layoutName === 'Logos') {
          return <Logos key={i} {...layout} />;
        } else if (layoutName === 'MastheadPlatform') {
          return <MastheadPlatform key={i} {...layout} />;
        } else if (layoutName === 'MastheadStrategicFinance') {
          return <MastheadStrategicFinance key={i} {...layout} />;
        } else if (layoutName === 'MastheadAbout') {
          return <MastheadAbout key={i} {...layout} />;
        } else if (layoutName === 'WorkflowTwoColumns') {
          return <WorkflowTwoColumns key={i} {...layout} />;
        } else if (layoutName === 'WorkflowNextUp') {
          return <WorkflowNextUp key={i} {...layout} />;
        } else if (layoutName === 'WorkflowConnection') {
          return <WorkflowConnection key={i} {...layout} />;
        } else if (layoutName === 'ContactCta') {
          return <ContactCta key={i} {...layout} />;
        } else if (layoutName === 'CareerListings') {
          return <CareerListings key={i} {...layout} />;
        } else if (layoutName === 'TeamCarousel') {
          return <TeamCarousel key={i} {...layout} />;
        } else if (layoutName === 'ListGridWithImage') {
          return <ListGridWithImage key={i} {...layout} />;
        } else if (layoutName === 'MastheadCareers') {
          return <MastheadCareers key={i} {...layout} />;
        } else if (layoutName === 'MastheadPlans') {
          return <MastheadPlans key={i} {...layout} />;
        } else if (layoutName === 'ProductsTable') {
          return <ProductsTable key={i} {...layout} />;
        } else if (layoutName === 'PlansCalculator') {
          return <PlansCalculator key={i} {...layout} />;
        } else if (layoutName === 'BenefitsGrid') {
          return <BenefitsGrid key={i} {...layout} />;
        } else if (layoutName === 'Faqs') {
          return <Faqs key={i} {...layout} />;
        } else {
          console.log('IMPLEMENT ', layoutName, layout);
        }
        return null;
      })}
    </>
  );
};
