import React from 'react';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';

export const ListGridWithImage = ({ items, image }) => {
  return (
    <section className="text-center md:text-left overflow-hidden lg:mb-[-100px] lg:!mt-[0px]">
      <div className="container">
        <div className="flex flex-col items-center lg:flex-row">
          <InViewAnimator
            className="lg:flex-1"
            inner={(controls) => (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-[38px]">
                {items.map((item, i) => (
                  <ListGridWithImageItem controls={controls} key={i} i={i} isLast={i === items.length - 1} {...item} />
                ))}
              </div>
            )}
          />
          <div className="w-full lg:w-[380px] mt-8 md:mt-16 lg:mt-0 lg:ml-6">
            <MediaAssetInner
              type="image"
              image={image}
              className="relative lg:left-[5%] lg:mx-0 mx-auto w-[140%] lg:w-[200%] max-w-none"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export const ListGridWithImageItem = ({ title, content, i, controls, isLast }) => (
  <InViewAnimatorBasicItem
    key={`leadership-item-${i}`}
    controls={controls}
    delay={i / 6}
    duration={1.5}
    prop="x"
    className={`space-y-1.5 md:space-y-3.5 ${
      isLast ? '' : 'border-b border-b-[#ddd]'
    } md:border-b-0 pb-7 pt-2 md:pb-0 md:pt-0`}
  >
    <h5 className="t-24">{title}</h5>
    <p className="t-17 opacity-80">{content}</p>
  </InViewAnimatorBasicItem>
);
