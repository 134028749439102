import React, { useState } from 'react';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { MaybeLink, ArrowLink } from '~/components/elements/Link';
import contactBackground from '~/assets/icons/contact-background.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { HubspotForm } from '~/components/elements/Hubspot';

export const Contact = (page) => {
  const [activeTab, setActiveTab] = useState(0);

  console.log(activeTab);

  return (
    <>
      <ContactHeader {...page.contact} activeTab={activeTab} setActiveTab={setActiveTab} />
      <ContactMain {...page.contact} activeTab={activeTab} setActiveTab={setActiveTab} />
    </>
  );
};

export const ContactHeader = ({ heading, subheading, tabs, activeTab, setActiveTab }) => (
  <InViewAnimator
    className="overflow-hidden bg-lightGrey section-header-padding"
    inner={(controls) => (
      <div className="container relative pt-8 pb-6 lg:pt-40 lg:pb-12">
        <img className="hidden lg:block absolute right-[-280px] bottom-0" src={contactBackground} alt="" />
        <InViewAnimatorBasicItem controls={controls} delay={0} prop="y" start={50} className="relative">
          <h1 className="t-subheading-plain">{subheading}</h1>
          <h2 className="pt-3 t-52 lg:pt-5">{heading}</h2>
          <div className="grid hidden grid-cols-2 gap-3 py-8 pt-8 text-center lg:pt-20 md:gap-0 md:flex md:space-x-4 lg:space-x-7 lg:py-16">
            {tabs.map((tab, i) => (
              <InViewAnimatorBasicItem key={`tab-${i}`} controls={controls} delay={0.3 + i / 6} prop="y" start={-50}>
                <div
                  onClick={() => {
                    setActiveTab(i);
                  }}
                  className={`${
                    activeTab === i ? 'border-primaryBlue text-white bg-primaryBlue' : 'hover:bg-white'
                  } cursor-pointer cursor-pointer t-17 font-medium border border-strokeGrey transition-colors duration-500 md:w-[145px] px-1 py-3`}
                >
                  {tab.tabName}
                </div>
              </InViewAnimatorBasicItem>
            ))}
          </div>
          <div className="w-full py-8 md:hidden">
            <div className="relative">
              <div className="absolute top-[21px] right-[16px]">
                <Chevron />
              </div>

              <select
                onChange={(e) => setActiveTab(Number(e.target.value))}
                className="flex flex-auto w-full h-[49px] border border-strokeDark px-4 appearance-none"
                name=""
                id=""
              >
                {tabs.map((tab, i) => (
                  <option key={i} value={i}>
                    {tab?.tabName}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </InViewAnimatorBasicItem>
      </div>
    )}
  />
);

export const ContactMain = ({ connectSection, contactBlock, tabs, formId, activeTab }) => (
  <div className="container py-12 space-y-12 lg:py-32 lg:flex lg:space-y-0 lg:space-x-12 xl:space-x-20">
    <div className="lg:w-[50%]">
      <AnimatePresence>
        {tabs.map((tab, i) => {
          if (i !== activeTab) return null;
          return (
            <motion.div
              initial={{ y: 40, opacity: 1 }}
              animate={{ y: 0, opacity: 1 }}
              key={`tab-${i}`}
              className="space-y-4"
            >
              <h3 className="t-36">{tab.heading}</h3>
              <div
                className="pb-0 prose blue-child-links t-20 lg:pb-6"
                dangerouslySetInnerHTML={{ __html: tab.description }}
              />
              <HubspotForm formId={tab.formId} />
            </motion.div>
          );
        })}
      </AnimatePresence>
    </div>
    <div className="flex-1">
      <MaybeLink link={contactBlock.link} className="border-default-grid bg-fadedBlue default">
        <div className="relative p-6 overflow-hidden md:p-12">
          <MediaAssetInner
            className="absolute max-h-full right-[-20%] xs:right-[-5%] top-[10%] md:right-0 md:top-0 lg:right-[-25%] xl:right-[-12%]"
            type="image"
            image={contactBlock.image}
          />
          <div className="relative space-y-5">
            <h3 className="t-24">{contactBlock.heading}</h3>
            <p className="whitespace-pre t-20">{contactBlock.content}</p>
            <ArrowLink link={contactBlock.link} />
          </div>
        </div>
      </MaybeLink>
      <h6 className="pt-8 pb-4 t-20 opacity-60">{connectSection.heading}</h6>
      <div className="grid grid-cols-2 gap-3 t-17 md:gap-0 md:flex md:space-x-4 lg:space-x-10">
        {connectSection.links.map((link, i) => (
          <a target="_blank" href={link.link.url} key={i} className="flex items-center space-x-2 group xl:space-x-4">
            <span className="w-[25px] md:w-auto">
              <MediaAssetInner type="image" image={link.icon} />
            </span>
            <span className="transition-opacity duration-500 opacity-50 group-hover:opacity-100">
              {link.link.title}
            </span>
          </a>
        ))}
      </div>
    </div>
  </div>
);

const Chevron = ({}) => (
  <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.33634 0.63003C1.13201 0.444277 0.815783 0.459335 0.63003 0.663664C0.444277 0.867992 0.459335 1.18422 0.663664 1.36997L1.33634 0.63003ZM6.5 6L6.16366 6.36997C6.35437 6.54334 6.64563 6.54334 6.83634 6.36997L6.5 6ZM12.3363 1.36997C12.5407 1.18422 12.5557 0.867992 12.37 0.663664C12.1842 0.459335 11.868 0.444277 11.6637 0.63003L12.3363 1.36997ZM0.663664 1.36997L6.16366 6.36997L6.83634 5.63003L1.33634 0.63003L0.663664 1.36997ZM6.83634 6.36997L12.3363 1.36997L11.6637 0.63003L6.16366 5.63003L6.83634 6.36997Z"
      fill="#9FA0AD"
    />
  </svg>
);
