import React from 'react';
import { MaybeLink, ArrowLink, Button } from '~/components/elements/Link';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import ebookMedia from '~/assets/images/ebook-media.png';
import logoSimple from '~/assets/images/logo-simple.svg';
import featuredNewsMedia from '~/assets/images/featured-news-media.png';

export const ResourcesGrid = ({ items }) => {
  return (
    <InViewAnimator
      className="container grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-9"
      inner={(controls) => (
        <>
          <MaybeLink
            link={{ external: items[0].postTypePost.externalLink, url: items[0].link }}
            className="row-span-2 resource-grid-hover xl:col-span-5 bg-tertiaryGrey"
          >
            {items[0].postTypePost.previewSize === 'full' ? (
              <div className={`overflow-hidden`}>
                <MediaAssetInner className="w-full" type="image" image={items[0].postTypePost.previewImage} />
              </div>
            ) : (
              <div className={'overflow-hidden p-[20px] md:p-[35px] ' + getBgClass(items[0])}>
                <InViewAnimatorBasicItem controls={controls} delay={0.3} prop="x" start={50}>
                  <MediaAssetInner
                    className="max-w-[200px] md:max-w-[250px] mx-auto"
                    type="image"
                    image={items[0].postTypePost.previewImage}
                  />
                </InViewAnimatorBasicItem>
              </div>
            )}
            <ResourcesGridInnerItem {...items[0]} size="grid" />
          </MaybeLink>
          <MaybeLink
            link={{ external: items[1].postTypePost.externalLink, url: items[1].link }}
            className="resource-grid-hover xl:col-span-4 bg-tertiaryGrey"
          >
            <div className={`overflow-hidden p-1 ${getBgClass(items[1])}`}>
              <InViewAnimatorBasicItem controls={controls} delay={0.4} prop="y" start={50}>
                <MediaAssetInner
                  className="h-[180px] mx-auto"
                  type="image"
                  image={items[1].postTypePost.previewImage}
                />
              </InViewAnimatorBasicItem>
            </div>
            <ResourcesGridInnerItem {...items[1]} size="grid-small" />
          </MaybeLink>
          <MaybeLink
            link={{ external: items[2].postTypePost.externalLink, url: items[2].link }}
            className="flex items-center resource-grid-hover xl:col-span-4 bg-tertiaryGrey"
          >
            <div
              className={`overflow-hidden w-[40%] xl:w-[49%] py-6 px-2 md:px-4 flex flex-col justify-center ${getBgClass(
                items[2]
              )}`}
            >
              {' '}
              <InViewAnimatorBasicItem controls={controls} delay={0.55} prop="y" start={50}>
                <MediaAssetInner type="image" image={items[2].postTypePost.previewImage} />
              </InViewAnimatorBasicItem>
            </div>
            <ResourcesGridInnerItem {...items[2]} size="grid-small" />
          </MaybeLink>
        </>
      )}
    />
  );
};

export const ResourcesThreeColumn = ({ items, limit = 6 }) => {
  return (
    <InViewAnimator
      className="container grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 grid gap-y-[34px] sm:gap-x-6 lg:gap-x-12"
      inner={(controls) => (
        <>
          {items?.slice(0, limit).map((item, i) => (
            <ResourcesGridItem controls={controls} item={item} delay={(i + 1) * 0.3} />
          ))}
        </>
      )}
    />
  );
};

export const ResourcesThreeColumnFeatured = ({ items }) => {
  return (
    <InViewAnimator
      className="container grid grid-cols-1 md:grid-cols-3 gap-y-8 sm:gap-x-6 lg:gap-x-12"
      inner={(controls) => (
        <>
          <MaybeLink
            link={{ external: items[0].postTypePost.externalLink, url: items[0].link }}
            className={`md:col-span-3 space-y-9 md:space-y-0 md:grid grid-cols-10 items-center`}
          >
            <div className={`col-span-4 ${getBgClass(items[0])}`}>
              {/* <InViewAnimatorBasicItem controls={controls} delay={0.3} prop="x" start={50} className="aspect-w-3 aspect-h-2">
								<img src={featuredNewsMedia} alt="" className="object-cover w-full h-full" />
							</InViewAnimatorBasicItem> */}
              <div className="overflow-hidden aspect-w-3 aspect-h-2">
                <ResourceGridImage item={items[0]} controls={controls} media={featuredNewsMedia} />
              </div>
            </div>
            <div className="col-span-6 space-y-6 md:pl-16">
              <div className="space-y-3">
                <div className="space-x-3">
                  <Category categories={items[0].categories} />
                </div>
                <h4 className="t-36">{items[0].title}</h4>
              </div>
              {false && (
                <div className="flex items-center space-x-6">
                  <div className="bg-fadedBlue w-[35px] h-[35px]"></div>
                  <div className=" t-14">
                    <div className="font-medium">Bijan Moallemi</div>
                    <div className="">CEO</div>
                  </div>
                </div>
              )}
            </div>
          </MaybeLink>
          {items?.slice(1, 4).map((item, i) => (
            <ResourcesGridItem key={i} controls={controls} item={item} delay={(i + 1) * 0.3} />
          ))}
        </>
      )}
    />
  );
};

export const ResourcesTwoColumn = ({ items, related = false, limit = 4 }) => {
  const itemsSliced = related ? items.slice(0, 2) : items.slice(0, limit);

  return (
    <InViewAnimator
      className={`container grid-cols-1 sm:grid-cols-2 grid gap-y-10  md:gap-y-16 ${
        related ? 'sm:gap-x-[76px]' : 'sm:gap-x-6'
      }`}
      inner={(controls) => (
        <>
          {itemsSliced.map((item, i) => (
            <MaybeLink
              key={i}
              link={{ external: item.postTypePost.externalLink, url: item.link }}
              className="space-y-8"
            >
              <div className={`overflow-hidden aspect-w-16 aspect-h-11 ${getBgClass(item)}`}>
                <ResourceGridImage
                  controls={controls}
                  item={item}
                  media={item.postTypePost.previewImage}
                  delay={(i + 1) * 0.3}
                />
              </div>
              <ResourcesGridInnerItem {...item} size="two-col" />
            </MaybeLink>
          ))}
        </>
      )}
    />
  );
};

export const ResourcesFeatured = ({ items }) => {
  return (
    <InViewAnimator
      className="container grid grid-cols-1 gap-6 md:grid-cols-2"
      inner={(controls) => (
        <>
          <div className="">
            <MaybeLink
              link={{ external: items[0].postTypePost.externalLink, url: items[0].link }}
              className="flex flex-col h-full space-y-6 xl:col-span-5 md:space-y-8"
            >
              <div className={`h-full overflow-hidden ${getBgClass(items[0])}`}>
                <div className={`h-full xl:max-w-[510px]`}>
                  <ResourceGridImage
                    item={items[0]}
                    controls={controls}
                    media={items[0].postTypePost.previewImage}
                    className="h-full aspect-w-510 aspect-h-471 xl:max-w-[510px]"
                  />
                </div>
              </div>
              <ResourcesGridInnerItem {...items[0]} size="featured" />
            </MaybeLink>
          </div>
          <div className="space-y-8">
            <ResourcesGridItemTwoCol item={items[1]} controls={controls} delay={0.6} />
            <MaybeLink
              link={{ external: items[1].postTypePost.externalLink, url: items[2].link }}
              className="resource-grid-hover bg-fadedYellow p-5 md:p-[34px] flex "
            >
              <ResourcesGridInnerItem {...items[2]} size="text-only-yellow" />
            </MaybeLink>
            <ResourcesGridItemTwoCol item={items[3]} controls={controls} delay={0.9} />
          </div>
        </>
      )}
    />
  );
};

export const ResourcesFeaturedTwoColumn = ({ items }) => {
  return (
    <InViewAnimator
      className="container grid grid-cols-1 gap-6 md:grid-cols-2"
      inner={(controls) => (
        <>
          {items.slice(0, 2).map((item, i) => (
            <div key={i}>
              <MaybeLink
                link={{ external: item.postTypePost.externalLink, url: item.link }}
                className="flex flex-col h-full space-y-6 xl:col-span-5 md:space-y-8"
              >
                <div className={`aspect-h-3 aspect-w-4 overflow-hidden ${getBgClass(item)}`}>
                  <ResourceGridImage
                    controls={controls}
                    item={item}
                    media={item.postTypePost.previewImage}
                    className="h-full"
                  />
                </div>
                <ResourcesGridInnerItem {...item} size="two-col" />
              </MaybeLink>
            </div>
          ))}
        </>
      )}
    />
  );
};

export const ResourcesVideos = ({ items }) => {
  return (
    <InViewAnimator
      className="container space-y-12"
      inner={(controls) => (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6">
            {items.slice(0, 2).map((item, i) => (
              <div key={i}>
                <MaybeLink
                  link={{ external: item.postTypePost.externalLink, url: item.link }}
                  className="flex flex-col h-full space-y-6 xl:col-span-5 md:space-y-10"
                >
                  <div className={`aspect-h-3 aspect-w-4 overflow-hidden ${getBgClass(item)}`}>
                    <ResourceGridImage
                      controls={controls}
                      item={item}
                      media={item.postTypePost.previewImage}
                      className="h-full"
                    />
                  </div>
                  <ResourcesGridInnerItem {...item} size="two-col" />
                </MaybeLink>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-12">
            {items.slice(2, 5).map((item, i) => (
              <ResourcesGridItem key={i} controls={controls} item={item} />
            ))}
          </div>
        </>
      )}
    />
  );
};

export const ResourcesGridItemTwoCol = ({ item, controls, delay }) => {
  return (
    <MaybeLink
      link={{ external: item.postTypePost.externalLink, url: item.link }}
      className="flex items-center space-x-7 "
    >
      <div className={`overflow-hidden w-[39%] flex flex-col justify-center`}>
        <div className={`aspect-w-1 aspect-h-1 ${getBgClass(item)}`}>
          <ResourceGridImage controls={controls} item={item} media={item.postTypePost.previewImage} delay={delay} />
        </div>
      </div>
      <ResourcesGridInnerItem {...item} size="small" />
    </MaybeLink>
  );
};

export const ResourcesInsights = ({ items }) => {
  return (
    <InViewAnimator
      className="container grid grid-cols-1 gap-6 md:grid-cols-2"
      inner={(controls) => (
        <>
          <div className="">
            <MaybeLink
              link={{ external: items[0].postTypePost.externalLink, url: items[0].link }}
              className={`xl:col-span-5 flex flex-col h-full bg-fadedBlue resource-grid-hover px-7 pt-8 md:pt-[53px] md:px-9 space-y-8 ${getBgClass(
                items[0]
              )}`}
            >
              <div className="space-y-5 text-center">
                <div className="space-y-3">
                  <div className="t-18-heading">eBook</div>
                  <h4 className="t-36">Compare plan to actuals</h4>
                  <p className="t-17">
                    Unlike legacy finance tools, which require heavy implementation and professional services, Mosaic
                    connects with your existing.
                  </p>
                </div>
                <Button link={{ title: 'Download ebook' }} size="header" buttonStyle="primary" />
              </div>
              <div className={`mt-auto h-full ${getBgClass(items[0])}`}>
                <div className={`h-full`}>
                  <InViewAnimatorBasicItem
                    controls={controls}
                    delay={0.3}
                    prop="x"
                    start={50}
                    className="max-w-[321px] w-full mx-auto h-full"
                  >
                    <img src={ebookMedia} alt="" className="object-contain object-bottom w-full h-full" />
                  </InViewAnimatorBasicItem>
                </div>
              </div>
            </MaybeLink>
          </div>
          <div className="space-y-8">
            {items.slice(1, 4).map((item, i) => (
              <ResourcesGridItemTwoCol key={i} item={item} controls={controls} delay={(i + 2) * 0.3} />
            ))}
          </div>
        </>
      )}
    />
  );
};

export const getBgClass = (item) => {
  const background = item?.postTypePost?.previewBackground;
  if (background === 'green') {
    return 'bg-fadedGreen';
  } else if (background === 'blue') {
    return 'bg-fadedBlue';
  } else if (background === 'purple') {
    return 'bg-fadedPurple';
  } else if (background === 'yellow') {
    return 'bg-fadedYellow';
  }
};

export const ResourcesGridItem = ({ controls, item, delay = 0.3 }) => {
  return (
    <MaybeLink link={{ external: item.postTypePost.externalLink, url: item.link }} className="space-y-8 group">
      <div className={`overflow-hidden ${getBgClass(item)}`}>
        <div className="aspect-w-3 aspect-h-2">
          <ResourceGridImage controls={controls} item={item} media={item.postTypePost.previewImage} delay={delay} />
        </div>
      </div>
      <ResourcesGridInnerItem {...item} size="three-col" />
    </MaybeLink>
  );
};

export const ResourceGridImage = ({ controls, item, delay = 0.3, media, className = '' }) => {
  if (!media) {
    return (
      <div
        className={
          'flex flex-col justify-center w-full h-full transition-colors duration-500 border border-lightGrey group-hover:border-strokeGrey bg-lightGrey + text-sm' +
          className
        }
      >
        <img className="w-[80px] mx-auto" src={logoSimple} alt="" />
      </div>
    );
  }
  return item.postTypePost.previewSize === 'full' ? (
    <div
      className={'border border-lightGrey group-hover:border-strokeGrey transition-colors duration-500 ' + className}
    >
      <MediaAssetInner type="image" image={media} className="object-cover w-full h-full" />
    </div>
  ) : (
    <div
      className={
        'border border-lightGrey group-hover:border-strokeGrey transition-colors duration-500 pt-6 px-6 ' + className
      }
    >
      <MediaAssetInner type="image" image={media} />
    </div>
  );
};

export const ResourcesGridInnerItem = ({ title, size, postTypePost, postImported, categories, tags }) => {
  let { readLabel, sidebarDescription } = postTypePost;
  if (!sidebarDescription) sidebarDescription = postImported.postSummary;
  const classes = ['flex-1 space-y-4'];
  const headingClasses = ['max-w-[450px] '];
  const categoryClasses = [' '];
  const tagClasses = [' '];

  if (size === 'small') {
    headingClasses.push('t-20-heading');
  } else if (size === 'three-col') {
    headingClasses.push('t-20-heading');
    classes.push('px-0 lg:px-0');
  } else if (size === 'two-col') {
    headingClasses.push('t-24');
    classes.push('px-0 lg:px-0');
  } else if (size === 'featured') {
    headingClasses.push('t-36');
    classes.push('px-0 lg:px-0');
  } else if (size === 'grid') {
    headingClasses.push('t-24 ');
    classes.push('py-[20px] px-[20px] lg:px-[32px] lg:py-[42px]');
  } else if (size === 'grid-small') {
    headingClasses.push('t-20-heading');
    classes.push('py-[20px] px-[20px] lg:px-[32px]');
  } else if (size === 'text-only-yellow') {
    tagClasses.push('!bg-white');
    headingClasses.push('t-20-heading');
  } else {
    headingClasses.push('t-24');
    classes.push('');
  }

  return (
    <div className={classes.join(' ')}>
      <div className="space-x-3">
        <Category categories={categories} className={categoryClasses.join(' ')} />
      </div>
      <h4 className={headingClasses.join(' ')}>{title}</h4>
      {size === 'two-col' && sidebarDescription && <p className="t-17">{sidebarDescription}</p>}
      {(size === 'grid' || size === 'grid-small') && <ArrowLink size="small" link={{ title: readLabel }} />}
    </div>
  );
};

export const Category = ({ categories, className = '', size }) => {
  let category = false;
  let sizeClass = 'is-small';
  if (categories && categories.nodes?.length) category = categories.nodes[0].name;
  if (category === 'Uncategorized' || !category) category = 'Article';
  if (size === 'large') {
    sizeClass = 't-15';
  }

  return <>{category && <span className={`t-subheading ${sizeClass} ${className}`}>{category}</span>}</>;
};

export const Tag = ({ tags, className = '', size }) => {
  let tag = false;
  let sizeClass = 'is-small';
  if (tags && tags.nodes?.length) tag = tags.nodes[0].name;
  if (!tag) tag = 'Latest';

  if (size === 'large') {
    sizeClass = 't-15';
  }

  return (
    <>{tag && <span className={`hidden lg:inline-block t-subheading ${sizeClass} is-grey ${className}`}>{tag}</span>}</>
  );
};
