import React from 'react';
import { MaybeLink, UnderlineLink, ArrowLink } from '~/components/elements/Link';
import { TextContent } from '~/components/flexible/TextContent';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { divide } from 'lodash';

export const IconGrid = (props) => {
  const { layout } = props;

  if (layout === 'sidebar-with-image') {
    return <IconGridSidebarImage {...props} />;
  } else if (layout === 'sidebar') {
    return <IconGridSidebar {...props} />;
  } else if (layout === 'cards') {
    return <IconGridCard {...props} />;
  } else {
    return <IconGridDefault {...props} />;
  }
  return null;
};
export const IconGridCard = ({ items, headingSize }) => {
  const itemClasses = {
    item: [
      'h-full space-y-3 lg:space-y-5 border-2 border-fadedBlue p-[20px] py-[30px] md:p-[30px] lg:p-[35px] lg:pr-[25px]',
    ],
    icon: ['fixed-height-icon h-[65px] lg:w-auto pb-4 md:pb-6'],
    heading: ['t-24 font-medium'],
    content: ['opacity-80 t-17'],
  };
  const gridClasses = ['text-center md:text-left grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'];

  return (
    <InViewAnimator
      className="container"
      inner={(controls) => (
        <div className={gridClasses.join(' ')}>
          {items.map((item, i) => {
            return (
              <InViewAnimatorBasicItem key={`icon-grid-${i}`} controls={controls} delay={i / 6} duration={1.5}>
                <IconGridItem
                  key={i}
                  {...item.main}
                  icon={item.icon}
                  highLight={item.highlight !== null}
                  itemClasses={itemClasses}
                  linkType="arrow"
                />
              </InViewAnimatorBasicItem>
            );
          })}
        </div>
      )}
    />
  );
};

export const IconGridDefault = ({ heading, items, headingSize, iconHeight }) => {
  const itemClasses = {
    item: ['space-y-3 lg:space-y-5 !flex flex-col h-full'],
    icon: ['fixed-height-icon pb-2'],
    heading: [],
    content: ['opacity-80'],
  };
  const gridClasses = ['grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-10 gap-y-6'];
  if (items.length == 3) {
    gridClasses.push('lg:grid-cols-3 xl:gap-16');
    itemClasses.content.push('t-17');
    if (iconHeight === '35') {
      itemClasses.icon.push('sm:pb-1');
    } else {
      itemClasses.icon.push('sm:pb-5');
    }
  } else if (items.length == 2) {
    itemClasses.icon.push('absolute top-0 left-0 h-[30px] md:w-auto');
    itemClasses.content.push('t-17 max-w-[430px]');
    itemClasses.item.push('relative pl-10 md:pl-12');
    itemClasses.heading.push('!mt-1');
  } else {
    gridClasses.push('lg:grid-cols-4 lg:gap-16 xl:gap-16');
    itemClasses.content.push('t-15');
    itemClasses.icon.push('sm:pb-4');
  }
  if (items.length !== 2) {
    itemClasses.icon.push('h-[80px] md:w-auto mx-auto md:mx-0');
    itemClasses.item.push('text-center md:text-left');
  }
  if (headingSize === '24') {
    itemClasses.heading.push('t-24');
  } else {
    itemClasses.heading.push('t-20-heading');
  }
  if (items && items[0].icon?.type === 'lottie') {
    itemClasses.icon.push('w-[45px] lg:w-[60px] relative md:left-[-8px] mb-[-8px]');
  } else {
    if (items.length !== 2) {
      if (iconHeight === '35') {
        itemClasses.icon.push('md:h-[35px]');
      } else if (iconHeight === '110') {
        itemClasses.icon.push('md:h-[110px]');
      } else if (iconHeight === '80') {
        itemClasses.icon.push('md:h-[80px]');
      } else if (iconHeight === '60') {
        itemClasses.icon.push('md:h-[65px]');
      } else {
        itemClasses.icon.push('md:h-[90px]');
      }
    }
  }
  if (items && !items[0].main?.heading) {
    itemClasses.content.push('font-medium');
  }
  if (heading) {
    gridClasses.push('border-t border-b py-12 pt-20 md:py-24 -mt-4 md:-mt-8 mb-4 md:mb-8 border-[#eee]');
  }
  return (
    <InViewAnimator
      className="container"
      inner={(controls) => (
        <>
          {heading && (
            <h3 className="t-42 relative top-[0.2em] md:top-[-0.2em] text-center md:text-left">
              <span className="inline-block pl-4 pr-4 bg-white md:pl-0 xl:pr-12">{heading}</span>
            </h3>
          )}
          <div className={gridClasses.join(' ')}>
            {items.map((item, i) => {
              const classes = [];
              if (items.length == 3 && i == 2) {
                classes.push('md:col-span-2 lg:col-span-1');
              }
              return (
                <InViewAnimatorBasicItem
                  className={classes.join(' ')}
                  key={`icon-grid-${i}`}
                  controls={controls}
                  delay={i / 6}
                  duration={1.5}
                >
                  <IconGridItem {...item.main} icon={item.icon} itemClasses={itemClasses} linkType="arrow" />
                </InViewAnimatorBasicItem>
              );
            })}
          </div>
        </>
      )}
    />
  );
};

export const IconGridSidebar = ({ items, header }) => {
  const gridClasses = ['lg:flex'];
  const itemClasses = {
    item: ['relative pb-2 lg:pb-4 space-y-2 md:space-y-4'],
    icon: ['absolute'],
    heading: ['t-24'],
    content: ['t-17'],
  };

  if (items && items[0].icon?.type === 'lottie') {
    itemClasses.icon.push(
      'w-[40px] md:w-[45px] lg:w-[60px] top-[-3px] md:top-[-6px] left-[-6px] lg:left-[-15px] xl:left-[-10px]'
    );
  } else {
    itemClasses.icon.push('top-0 lg:top-[2px] left-0');
  }
  let overflowGrid = true;
  if (items && !items[0].icon?.image) {
    itemClasses.item.push('');
    overflowGrid = false;
  } else {
    itemClasses.item.push('pl-10 md:pl-12 xl:pl-[75px]');
  }
  return (
    <InViewAnimator
      className="container"
      inner={(controls) => (
        <div className={gridClasses.join(' ')}>
          <div className="relative lg:w-[40%] md:mr-8 xl:mr-24 mb-8 lg:mb-0">
            <div className="md:sticky md:top-[90px] xl:top-[100px]">
              {header && <TextContent innerOnly={true} centered={false} {...header} />}
            </div>
          </div>
          <div className={`flex-1 space-y-8 lg:space-y-8 ${overflowGrid ? 'overflow-broken-grid' : ''}`}>
            {items.map((item, i) => (
              <InViewAnimatorBasicItem
                key={`icon-grid-${i}`}
                controls={controls}
                delay={0.3 + i / 6}
                duration={1.5}
                key={i}
                prop="x"
                className="bg-tertiaryGrey border border-strokeGreyAlt pt-[10px] py-[15px] px-[20px] xl:py-[25px] xl:px-[42px]"
              >
                <IconGridItem
                  {...item.main}
                  icon={item.icon}
                  itemClasses={itemClasses}
                  linkType="arrow"
                  linkSize="default"
                />
              </InViewAnimatorBasicItem>
            ))}
          </div>
        </div>
      )}
    />
  );
};

export const IconGridSidebarImage = ({
  items,
  alternateSpacing,
  image,
  imagePosition,
  imageSize,
  headingSize,
  reduceIconSpacing,
  header,
}) => {
  const hasDescriptions = items && items[0].main.content;
  const gridClasses = ['md:flex'];
  const imageClasses = [];
  const itemClasses = {
    item: ['relative space-y-2 lg:space-y-3'],
    icon: ['left-0'],
    heading: ['relative'],
    content: ['relative t-17 opacity-80'],
  };
  if (imagePosition === 'right') {
    gridClasses.push('md:flex-row-reverse');
    if (imageSize === '58') {
      imageClasses.push('md:ml-8 xl:ml-10');
    } else {
      imageClasses.push('md:ml-10 xl:ml-16');
    }
  } else {
    if (imageSize === '58') {
      imageClasses.push('md:mr-8 xl:mr-10');
    } else {
      imageClasses.push('md:mr-10 xl:mr-16');
    }
  }
  if (headingSize === '24') {
    itemClasses.heading.push('t-24');
  } else if (headingSize === '18') {
    itemClasses.heading.push('t-18-heading');
  } else {
    itemClasses.heading.push('t-20-heading');
  }
  if (items && items[0].icon?.type === 'lottie') {
    itemClasses.icon.push('left-[-6px] md:left-0');

    if (imageSize === '58') {
      itemClasses.item.push('pl-12 lg:pl-16');
      itemClasses.icon.push('w-[45px] lg:w-[52px]');
      if (hasDescriptions) {
        itemClasses.icon.push('top-[-8px]');
      } else {
        itemClasses.icon.push('top-[-12px] md:top-[-15px] lg:left-[-6px]');
      }
    } else {
      itemClasses.item.push('pl-12 lg:pl-16 xl:pl-20');
      itemClasses.icon.push('top-[-10px] w-[50px] lg:w-[60px]');
    }
  } else {
    if (reduceIconSpacing) {
      itemClasses.item.push('pl-12');
    } else {
      itemClasses.item.push('pl-12 lg:pl-16');
    }
    itemClasses.icon.push('top-[-4px] md:top-[-1px] w-[40px]');
  }

  if (imageSize === '54') {
    imageClasses.push('md:w-[45%] lg:w-[49%] xl:w-[54%]');
  } else if (imageSize === '58') {
    imageClasses.push('md:w-[45%] lg:w-[49%] xl:w-[60%]');
  } else {
    imageClasses.push('md:w-[45%] lg:w-[49%]');
  }

  const mainContentClasses = ['flex-1 xl:mt-[-2px]'];
  let wrappingInnerClasses = '';

  if (hasDescriptions) {
    wrappingInnerClasses += 'pt-6';
    itemClasses.icon.push('absolute');
    itemClasses.heading.push('pt-0.5 lg:pt-1.5');
  } else {
    itemClasses.icon.push('inline-block md:absolute md:block');
    itemClasses.item.push('-ml-12 md:ml-0 text-center md:text-left');
    if (items && items[0].icon?.type === 'lottie') {
      itemClasses.heading.push('relative -top-3 md:top-0');
    }
  }
  if (alternateSpacing === 'narrow') {
    mainContentClasses.push('xl:flex xl:flex-col');
    if (hasDescriptions) {
      mainContentClasses.push('pb-12');
    }
    wrappingInnerClasses += ' xl:max-w-[485px]';
    if (imagePosition === 'left') {
      wrappingInnerClasses += ' w-full xl:ml-auto';
    }
  } else {
    gridClasses.push('xl:items-center');
  }

  if (header && header.headingSize == '36') {
    header.subheadingStyle = 'light';
  }

  let headerClasses = '';
  if (hasDescriptions) {
    headerClasses += ' mb-auto';
  } else {
    headerClasses += ' mb-8';
    imageClasses.push('mb-4 md:mb-0');
    gridClasses.push('xl:items-center');
  }

  return (
    <InViewAnimator
      className="container"
      inner={(controls) => (
        <>
          {header && header.heading && (
            <div className="block pb-8 lg:hidden">
              <TextContent innerOnly={true} centered={false} {...header} />
            </div>
          )}
          <div className={gridClasses.join(' ')}>
            <InViewAnimatorBasicItem className={imageClasses.join(' ')} controls={controls} duration={1.5} prop="y">
              <MediaAssetInner className="w-full" {...image} />
            </InViewAnimatorBasicItem>
            <div className={mainContentClasses.join(' ')}>
              {header && header.heading && (
                <div className={`hidden lg:block ${headerClasses} ${wrappingInnerClasses}`}>
                  <TextContent innerOnly={true} centered={false} {...header} />
                </div>
              )}

              {items?.map((item, i) => (
                <InViewAnimatorBasicItem
                  className={wrappingInnerClasses}
                  key={`icon-grid-${i}`}
                  controls={controls}
                  delay={i / 6}
                  duration={1.5}
                  prop="x"
                >
                  <IconGridItem key={i} {...item.main} icon={item.icon} itemClasses={itemClasses} />
                </InViewAnimatorBasicItem>
              ))}
            </div>
          </div>
        </>
      )}
    />
  );
};

export const IconGridItem = ({
  icon,
  heading,
  content,
  link,
  linkType,
  linkSize = 'small',
  itemClasses,
  highLight,
  subheading,
}) => {
  const itemClassesClone = itemClasses.item.slice(0);
  if (highLight) {
    itemClassesClone.push('relative bg-fadedBlue border-secondaryBlue shadow-cardHighlight');
  }
  return (
    <MaybeLink {...link} className={itemClassesClone.join(' ')}>
      {highLight && (
        <div className={`border-dashed-article is-alt absolute bottom-[-15px] left-[-2px] right-[-2px]`}></div>
      )}
      <div className={itemClasses.icon.join(' ')}>
        <MediaAssetInner className="mx-auto md:mx-0" {...icon} />
      </div>
      {subheading && <h3 className="t-24">{subheading}</h3>}
      {heading && <h4 className={`${itemClasses.heading.join(' ')} ${subheading && '!mb-[-10px]'}`}>{heading}</h4>}
      <p className={itemClasses.content.join(' ')}>{content}</p>
      {link && link.link && (
        <div className="pt-3 lg:pt-4 !mt-auto">
          {linkType === 'arrow' ? <ArrowLink size={linkSize} {...link} /> : <UnderlineLink {...link} />}
        </div>
      )}
    </MaybeLink>
  );
};
