import React from 'react';
import { MaybeLink, Button } from '~/components/elements/Link';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';

export default function Cta({ link, heading, image }) {
  return (
    <section className="section">
      <MaybeLink
        link={link}
        className="px-5 pt-10 text-center space-y-10 md:space-y-0 md:text-left md:pt-0 md:pl-[60px] md:pr-[78px] md:grid grid-cols-12 bg-darkNavy text-white items-center"
      >
        <div className="col-span-6 space-y-5 md:py-5">
          <h3 className="t-36">{heading}</h3>
          <div className="inline-block">
            <Button link={link} buttonStyle={'primary'} />
          </div>
        </div>
        <div className="col-span-5 col-start-8 md:pt-[60px]">
          <MediaAssetInner type="image" image={image} />
        </div>
      </MaybeLink>
    </section>
  );
}
