import React from 'react';
import { MaybeLink, ArrowLink } from '~/components/elements/Link';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'gatsby';
import { Button } from '../elements/Link';
import { useWindowSize } from '../../hooks/useWindowSize';

export const ProductsTable = (props) => {
  const [activeTable, setActiveTable] = useState(0);

  const tableHandler = (i) => {
    setActiveTable(i);
  };

  const { productTable, links } = props;
  return (
    <div className="container">
      <div className="border-[1.6px] border-[#E5EAF0] md:overflow-hidden ">
        {productTable?.map((table, i, arr) => {
          return (
            <Table
              table={table}
              active={activeTable === i}
              onClick={() => tableHandler(activeTable === i ? null : i)}
              i={i}
              arr={arr}
            />
          );
        })}
      </div>

      <div className=" justify-end w-full mt-7 lg:pr-[28px] hidden sm:flex">
        <div className="sm:flex w-full space-y-4 sm:space-y-0 sm:space-x-4 md:space-x-[30px] justify-end">
          {links?.map((link, i) => (
            <div
              key={`key${i}`}
              className="border text-center border-[#E5EAF0] w-full  sm:max-w-[233px] bg-tertiaryGrey p-4 md:p-7 flex flex-col justify-center items-center"
            >
              <div className="font-semibold t-18 ">{link?.heading}</div>
              <div className=" mt-2 sm:mt-[22px]">
                <Link to={link?.link?.url}>
                  <Button size="fullWidth" link={link?.link} buttonStyle="primary" />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="sm:hidden mt-7">
        <div className="border text-center border-[#E5EAF0] w-full  bg-tertiaryGrey p-4  flex flex-col justify-center items-center">
          <Link className="w-full" to={'/request-a-demo'}>
            <Button size="fullWidth" link={{ title: 'Request a demo' }} buttonStyle="primary" />
          </Link>
        </div>
      </div>
    </div>
  );
};

const Table = ({ table, active, onClick, i, arr }) => {
  const { width } = useWindowSize();

  const [openTable, setOpenTable] = useState(i === 0 ? true : false);

  let activeType = width < 768 ? openTable : active;

  return (
    <div key={`table${i}`} className={``}>
      <div
        onClick={width > 767 ? onClick : () => setOpenTable(!openTable)}
        className={`flex items-center justify-between py-[16px] px-[23px] cursor-pointer    ${
          (i !== arr.length - 1 || activeType) && 'border-b border-[#D2D6DC]'
        } `}
      >
        <div className="font-semibold ">{table?.title}</div>
        <DropdownIcon active={activeType} />
      </div>
      <AnimatePresence exitBeforeEnter initial={false}>
        {activeType && (
          <motion.div
            key={`table${i}`}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            className="relative t-16-table "
            variants={{
              open: { height: 'auto', opacity: 1, transition: { opacity: { delay: 0.2 } } },
              collapsed: { height: 0, opacity: 0, transition: { opacity: { delay: 0 } } },
            }}
            transition={{ height: { ease: [0.04, 0.62, 0.23, 0.98] } }}
          >
            <div className="sticky md:static top-[4.6rem]">
              <div
                className={`flex bg-white   justify-between w-full pr-[7px] md:pr-[24px] py-[15px] border-b border-dashed border-[#D2D6DC]   `}
              >
                <div className="md:flex hidden  items-center w-full max-w-[413px] invisible">
                  {table?.features[0].feature}
                </div>

                <div className="flex items-center w-full space-x-2 md:space-x-[30px] t-14 font-medium">
                  {table?.products?.map((head, i) => (
                    <div key={`head${i}`} className="flex items-center justify-center flex-1 text-center">
                      {head?.heading}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className={`w-full hidden md:block ${i !== arr.length - 1 && 'border-b border-[#D2D6DC]'} `}>
              {table?.features?.map((feature, i, arr) => (
                <div
                  key={`feat${i}`}
                  className={`flex justify-between w-full ${
                    i !== arr.length - 1 && 'border-b border-dashed border-[#D2D6DC]'
                  }  pl-[23px] pr-[7px] md:pr-[24px] `}
                >
                  <div className="flex items-center w-full max-w-[392px] py-[12px]">
                    <div className="t-14 text-[#5D5D5D] w-full max-w-[392px]">{feature?.feature}</div>
                  </div>

                  <div className="flex items-center w-full space-x-2 md:space-x-[30px]">
                    {feature?.truefalse?.map((feat, i) => (
                      <div
                        key={`featCheck${i}`}
                        className="  border-l border-r border-[#E5EAF0] flex-col h-full flex flex-1 justify-center items-center"
                      >
                        <div className={`${feat?.check !== true && 'invisible'} `}>
                          <CheckMark />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>

            <div className={`block  ${i !== arr.length - 1 && 'border-b border-[#D2D6DC]'} w-full md:hidden `}>
              {table?.features?.map((feature, i, arr) => (
                <div
                  className={`${i !== arr.length - 1 && 'border-b border-dashed border-[#D2D6DC]'} py-6   `}
                  key={`mobileFeat${i}`}
                >
                  <div className="px-2 text-center sm:px-0">{feature?.feature}</div>

                  <div className="flex justify-between mt-3">
                    {feature?.truefalse?.map((feat, i) => (
                      <div key={`featCheck${i}`} className="flex items-center justify-center flex-1 ">
                        <div className={`${feat?.check !== true && 'invisible'} `}>
                          <CheckMark />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const DropdownIcon = ({ active }) => {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13.8203" cy="13.0469" r="13" fill="#C0D9FF" />
      <path
        d="M14.8203 12.0469H18.8203C19.0855 12.0469 19.3399 12.1522 19.5274 12.3398C19.715 12.5273 19.8203 12.7817 19.8203 13.0469C19.8203 13.3121 19.715 13.5664 19.5274 13.754C19.3399 13.9415 19.0855 14.0469 18.8203 14.0469H14.8203H8.82031C8.5551 14.0469 8.30074 13.9415 8.11321 13.754C7.92567 13.5664 7.82031 13.3121 7.82031 13.0469C7.82031 12.7817 7.92567 12.5273 8.11321 12.3398C8.30074 12.1522 8.5551 12.0469 8.82031 12.0469H14.8203Z"
        fill="#0368FE"
      />
      <motion.path
        animate={{ pathLength: !active ? 1 : 0, y: !active ? 0 : -5 }}
        d="M14.3203 14.0469L14.3203 18.0469C14.3203 18.1795 14.2676 18.3067 14.1739 18.4004C14.0801 18.4942 13.9529 18.5469 13.8203 18.5469C13.6877 18.5469 13.5605 18.4942 13.4668 18.4004C13.373 18.3067 13.3203 18.1795 13.3203 18.0469L13.3203 14.0469L13.3203 8.04687C13.3203 7.91427 13.373 7.78709 13.4668 7.69332C13.5605 7.59955 13.6877 7.54687 13.8203 7.54687C13.9529 7.54687 14.0801 7.59955 14.1739 7.69332C14.2676 7.78709 14.3203 7.91427 14.3203 8.04687L14.3203 14.0469Z"
        stroke="#0368FE"
      />
    </svg>
  );
};

const CheckMark = ({}) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13" cy="13" r="13" fill="#C0D9FF" />
      <path
        d="M18.0188 8.31234L10.6619 15.3143L7.98122 12.763C7.76073 12.5603 7.46543 12.4481 7.1589 12.4507C6.85238 12.4532 6.55916 12.5702 6.34241 12.7765C6.12566 12.9828 6.00271 13.2619 6.00004 13.5536C5.99738 13.8454 6.11522 14.1264 6.32817 14.3363L9.83535 17.6742C10.0546 17.8828 10.3519 18 10.6619 18C10.9719 18 11.2692 17.8828 11.4884 17.6742L19.6718 9.88563C19.8848 9.67578 20.0026 9.39472 20 9.10299C19.9973 8.81125 19.8743 8.53219 19.6576 8.32589C19.4408 8.11959 19.1476 8.00258 18.8411 8.00004C18.5346 7.99751 18.2393 8.10966 18.0188 8.31234Z"
        fill="#0368FE"
      />
    </svg>
  );
};
