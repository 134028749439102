import React from 'react';

export const Legal = (page) => {
	const { subheading, heading, content } = page.legal;
	return (
		<>
			<div className="overflow-hidden bg-lightGrey section-header-padding">
				<div className="container pt-10 pb-16 xl:pt-20 xl:pb-24">
					<div className="max-w-[840px] mx-auto">
						<h2 className="t-subheading">{subheading}</h2>
						<h1 className="t-56 !leading-[1.15em] mt-6 mx-auto">{heading}</h1>
					</div>
				</div>
			</div>
			<div className="container py-12 xl:py-24 blue-child-links">
				<div className="prose prose-large mx-auto" dangerouslySetInnerHTML={{ __html: content }} />
			</div>
		</>
	);
};
