import React, { useContext, useEffect, useRef } from 'react';
import { TextContent } from '~/components/flexible/TextContent';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

import { OptionsContext } from '~/templates/Layout';
export const MastheadTeams = (props) => {
	const { asset, backgroundColour, layout } = props;

	const containerClasses = ['md:mt-6'];
	if (layout === 'workflow') {
	} else {
		containerClasses.push('border-default-grid');
		addColorClass(containerClasses, backgroundColour);
	}
	return (
		<InViewAnimator
			className={`${layout === 'workflow' ? '' : 'header-container'} section-header-padding pb-2 lg:pb-12`}
			inner={(controls) => (
				<>
					{layout === 'workflow' && <WorkflowMenu backgroundColour={backgroundColour} />}
					<div className={containerClasses.join(' ')}>
						<div className="overflow-hidden">
							<div className={`container md:flex md:flex-row-reverse ${layout === 'workflow' ? 'items-center' : ''}`}>
								<InViewAnimatorBasicItem
									controls={controls}
									delay={0.45}
									prop="x"
									start={50}
									className={`${
										layout === 'workflow' ? 'xl:w-[54%]' : 'right-asset-margin-fix xl:w-[60%]'
									} md:pt-0 md:w-[40%] lg:w-[50%] md:pl-12`}
								>
									<div
										className={`md:w-auto mx-auto md:mx-0 ${
											layout === 'workflow' ? 'flex pb-8 md:pb-0' : 'w-[70%] lg:mr-[-20%]'
										} xl:mr-0`}
									>
										<MediaAssetInner className={`${layout === 'workflow' ? 'ml-auto' : ''}`} {...asset} />
									</div>
								</InViewAnimatorBasicItem>
								<div
									className={`flex-1 pt-1 pb-8 ${
										layout === 'workflow' ? 'md:py-6 lg:py-8 xl:py-16' : 'md:py-12 xl:py-32 xl:pt-[180px] xl:pb-[140px]'
									}`}
								>
									<InViewAnimatorBasicItem
										controls={controls}
										delay={0.2}
										prop="y"
										start={50}
										className="max-w-[520px] mx-auto md:mx-0"
									>
										<TextContent
											innerOnly={true}
											headingSize="54"
											centered={false}
											isMasthead={true}
											linkStyle="button"
											subheadingStyle="plain"
											contentOpacity={100}
											{...props}
										/>
									</InViewAnimatorBasicItem>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		/>
	);
};

const addColorClass = (array, backgroundColour) => {
	if (backgroundColour === 'fadedYellow') {
		array.push('bg-fadedYellow');
	} else if (backgroundColour === 'fadedBlue') {
		array.push('bg-headerBlue');
	} else if (backgroundColour === 'fadedGreen') {
		array.push('bg-headerGreen');
	} else if (backgroundColour === 'fadedPurple') {
		array.push('bg-headerPurple');
	} else if (backgroundColour === 'fadedStone') {
		array.push('bg-headerStone');
	} else if (backgroundColour === 'ice') {
		array.push('bg-[#F6FBFF]');
	} else if (backgroundColour === 'purple') {
		array.push('bg-[#D0E3FF]');
	}
	return array;
};

export const WorkflowMenu = ({ backgroundColour }) => {
	const { options, page } = useContext(OptionsContext);
	const workFlowMenu = options.header.headerOptions.workflowsMenu;
	const classes = ['w-[1100px] lg:w-auto md:text-[10px] lg:text-[13px] xl:text-[16px] flex justify-between'];
	const outerClasses = ['border border-primaryNavy p-4 overflow-x-scroll lg:overflow-x-hidden'];
	addColorClass(outerClasses, backgroundColour);
	const ref = useRef();
	useEffect(() => {
		if (typeof document === 'undefined') return;
		if (ref.current) {
			ref.current.scrollLeft = 50;
			const active = document.querySelector('.active-link');
			if (active) {
				ref.current.scrollLeft = active.offsetLeft - 50;
			}
		}
	}, [ref]);
	return (
		<div className="hidden lg:block container pb-8 md:py-8">
			<div ref={ref} className={outerClasses.join(' ')}>
				<div className={classes.join(' ')}>
					{workFlowMenu.map(({ menuItem }, i) => {
						const active = menuItem.url === page.link;
						return (
							<a
								key={i}
								className={`inline-block px-1 ${
									active ? 'active-link bg-black text-white' : 'hover:bg-white'
								} transition-colors duration-400`}
								dangerouslySetInnerHTML={{ __html: menuItem.title }}
								href={menuItem.url}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};
