import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { MaybeLink, ArrowLink } from '~/components/elements/Link';

export const Submenus = ({ menu, openMenu, mobile = false }) => (
  <AnimatePresence>
    {menu.map(({ submenu, showSubmenu }, i) => {
      if (!showSubmenu) return null;
      if (openMenu !== i) return null;
      const { useExtraColumnGrid } = submenu;
      return (
        <SubmenuContainer mobile={mobile} key={i} useExtraColumnGrid={useExtraColumnGrid}>
          {submenu.columns.map((column, q) => {
            if (column.type === 'feature-large') {
              return <SubmenuFeatureLarge key={q} {...column} />;
            } else if (column.type === 'features-small') {
              return <SubmenuFeaturesSmall key={q} {...column} />;
            } else if (column.type === 'standard') {
              return <SubmenuColumnStandard key={q} delay={q / 5} {...column} />;
            } else if (column.type === 'single-item') {
              return <SubmenuColumnSingleItem key={q} {...column} />;
            } else if (column.type === 'page-grid') {
              return <SubmenuColumnPageGrid key={q} {...column} />;
            }
          })}
        </SubmenuContainer>
      );
    })}
  </AnimatePresence>
);

export const SubmenuContainer = ({ children, useExtraColumnGrid, mobile }) => {
  if (mobile) return <div className="submenu-color space-y-7">{children}</div>;
  return (
    <motion.div
      initial={{ opacity: 0, y: 0 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 0, duration: 0.4 }}
      transition={{ duration: 0.35, ease: 'easeInOut' }}
      className="submenu-color bg-white absolute top-[100%] left-0 right-0 z-10 border-t border-b border-t-lightGrey border-b-lightGrey"
    >
      <div className={`header-container py-12 xl:py-12 xl:pb-16 flex space-x-6`}>{children}</div>
    </motion.div>
  );
};

export const SubmenuFeatureLarge = ({ features }) => {
  const { link, image, description } = features[0];
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 25 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.35, ease: 'easeInOut' }}
        className="h-auto lg:w-[40%] -mb-4 lg:mb-0"
      >
        <MaybeLink
          link={link}
          className="h-full px-4 lg:px-6 xl:px-8 py-4 border-submenu-grid bg-lightGrey flex space-x-2 xl:space-x-4 my-auto items-center"
        >
          <div className="pr-1 lg:pr-6">
            <div className="flex flex-col justify-center mx-auto w-[80px] lg:w-[130px] xl:w-[160px]">
              <MediaAssetInner type="image" image={image} />
            </div>
          </div>
          <div className="space-y-1 lg:space-y-4 xl:space-y-5">
            <h6 className="t-20-heading">{link.title}</h6>
            <p className="t-14">{description}</p>
            <div>
              <ArrowLink link={{ title: 'Learn more' }} />
            </div>
          </div>
        </MaybeLink>
      </motion.div>
    </AnimatePresence>
  );
};

export const SubmenuFeaturesSmall = ({ features }) => (
  <div className="lg:w-[300px] xl:w-[390px] space-y-3 lg:space-y-6 flex flex-col justify-between">
    <AnimatePresence>
      {features.map((feature, i) => (
        <motion.div
          key={i}
          initial={{ opacity: 0, y: 25 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.35, ease: 'easeInOut', delay: 0.12 + i / 12 }}
        >
          <SubmenuFeaturesSmallItem {...feature} />
        </motion.div>
      ))}
    </AnimatePresence>
  </div>
);

export const SubmenuFeaturesSmallItem = ({ image, link, description }) => (
  <MaybeLink
    link={link}
    className="border-submenu-grid bg-fadedBlue flex space-x-2 lg:space-x-4 my-auto items-center py-4 px-4 xl:py-6"
  >
    <div className="pr-1 lg:pr-0">
      <div className="flex flex-col justify-center mx-auto w-[80px] h-[70px]">
        <MediaAssetInner className="mx-auto max-h-full max-w-full" type="image" image={image} />
      </div>
    </div>
    <div className="space-y-1.5">
      <ArrowLink link={{ title: link.title, size: 'x-large' }} />
      <p className="t-14">{description}</p>
    </div>
  </MaybeLink>
);

export const SubmenuColumnStandard = ({ delay, twoColumns, size, heading, standardItems }) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 25 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.35, ease: 'easeInOut', delay }}
        className={`default-link-hovers flex-1 ${size === 'large' ? 'xl:!ml-8 t-20' : 't-16'} ${
          twoColumns ? 'lg:min-w-[44%]' : ''
        }`}
      >
        <h6
          className={`t-15 tracking-[0.015em] uppercase font-semibold pb-1 ${
            size === 'large' ? 'lg:pt-2 xl:pt-4 lg:pb-2' : 'lg:pb-4'
          }`}
        >
          {heading}
        </h6>
        <ul
          className={`grid ${twoColumns ? 'grid-cols-1 lg:grid-cols-2' : 'grid-cols-1'} gap-1 lg:gap-3 ${
            size === 'large' ? 'lg:gap-y-1' : 'lg:gap-y-5'
          }`}
        >
          {standardItems.map((item, i) => (
            <SubmenuColumnStandardItem key={i} {...item} />
          ))}
        </ul>
      </motion.div>
    </AnimatePresence>
  );
};

export const SubmenuColumnStandardItem = ({ link, asset }) => {
  asset.activation = 'hover';
  useEffect(() => {
    if (typeof window == 'undefined') return;
    let timeout;
    if (hover) {
      timeout = window.setTimeout(() => {
        setHover(false);
      }, 6000);
    }
    return () => {
      window.clearTimeout(timeout);
    };
  }, [hover]);
  const [hover, setHover] = useState(false);
  const mediaClasses = [''];
  if (asset && asset.type === 'lottie') {
    mediaClasses.push('w-[35px] mr-[5px] relative top-[-1px]');
  } else {
    mediaClasses.push('w-[40px] ml-[-10px] my-[-10px]');
  }
  return (
    <li className="pt-2 lg:font-medium">
      <MaybeLink
        onMouseEnter={() => {
          setHover(true);
        }}
        link={link}
        className="flex space-x-3"
        href="#"
      >
        <span className={mediaClasses.join(' ')}>
          <MediaAssetInner hovered={hover} {...asset} />
        </span>
        <span>{link.title}</span>
      </MaybeLink>
    </li>
  );
};

export const SubmenuColumnSingleItem = ({ singleItem }) => {
  const { image, link } = singleItem;
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 25 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.35, ease: 'easeInOut' }}
        className="flex-1 text-center space-y-4"
      >
        <MaybeLink link={link}>
          <div className="mt-2 mx-auto w-[160px] rounded-[100%] overflow-hidden">
            <MediaAssetInner type="image" image={image} />
          </div>
          <h6 className="t-20-heading pb-3 pt-5">{link.title}</h6>
          <div>
            <ArrowLink link={{ title: 'Learn more' }} />
          </div>
        </MaybeLink>
      </motion.div>
    </AnimatePresence>
  );
};

export const SubmenuColumnPageGrid = ({ pages }) => {
  return (
    <div className="flex-1 grid lg:grid-cols-4 gap-3 lg:gap-6">
      <AnimatePresence>
        {pages.map((page, i) => (
          <motion.div
            initial={{ opacity: 0, y: 25 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.35, ease: 'easeInOut', delay: i / 8 }}
            key={i}
          >
            <SubmenuColumnPageGridItem key={i} {...page} />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

export const SubmenuColumnPageGridItem = ({ image, link }) => (
  <MaybeLink link={link} className="h-full text-center pt-2 lg:pt-4 pb-3 lg:pb-8 bg-tertiaryGrey border-submenu-grid">
    <div className="h-[70px] lg:h-[135px] w-[140px] flex flex-col justify-center mx-auto pb-3 lg:pb-1">
      <MediaAssetInner className="max-h-full max-w-full mx-auto" type="image" image={image} />
    </div>
    <h6 className="t-20-heading text-black">{link.title}</h6>
  </MaybeLink>
);

export const TempIcon = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.9996 5.8C14.3251 5.8 15.3996 4.72548 15.3996 3.4C15.3996 2.07452 14.3251 1 12.9996 1C11.6741 1 10.5996 2.07452 10.5996 3.4C10.5996 4.72548 11.6741 5.8 12.9996 5.8Z"
      fill="#88E8FF"
      stroke="#88E8FF"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M3.4 9.8C4.72548 9.8 5.8 8.72548 5.8 7.4C5.8 6.07452 4.72548 5 3.4 5C2.07452 5 1 6.07452 1 7.4C1 8.72548 2.07452 9.8 3.4 9.8Z"
      fill="#88E8FF"
      stroke="#88E8FF"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M22.6002 9.8C23.9257 9.8 25.0002 8.72548 25.0002 7.4C25.0002 6.07452 23.9257 5 22.6002 5C21.2747 5 20.2002 6.07452 20.2002 7.4C20.2002 8.72548 21.2747 9.8 22.6002 9.8Z"
      fill="#88E8FF"
      stroke="#88E8FF"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M13 17.7998C15.2091 17.7998 17 16.0089 17 13.7998C17 11.5907 15.2091 9.7998 13 9.7998C10.7909 9.7998 9 11.5907 9 13.7998C9 16.0089 10.7909 17.7998 13 17.7998Z"
      stroke="#181A3D"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M5 23.685C5 22.065 6.2056 20.7074 7.8136 20.5074C9.136 20.3426 10.8896 20.2002 13 20.2002C15.1104 20.2002 16.864 20.3426 18.1864 20.5074C19.7944 20.7074 21 22.065 21 23.685V25.0002H5V23.685Z"
      stroke="#181A3D"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
