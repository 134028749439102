import React from 'react';
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';
import useSiteInfoAssets from '~/hooks/useSiteInfoAssets';
import Footer from '~/components/global/Footer';
import Header from '~/components/global/Header';
export const OptionsContext = React.createContext();

export default function Layout({ wp, children, headerColor, showTopBar, hideFooter, headerType, page }) {
  const seo = useSiteInfoAssets();
  return (
    <OptionsContext.Provider value={{ options: wp, page }}>
      <SEOContext.Provider value={{ global: seo }}>
        <Header
          page={page}
          data={wp?.header}
          headerColor={headerColor}
          showTopBar={showTopBar}
          headerType={headerType}
        />
        <main className={`${headerType !== 'hidden' ? 'section-header-margin' : ''}`}>{children}</main>
        {!hideFooter && <Footer data={wp?.footer} />}
      </SEOContext.Provider>
    </OptionsContext.Provider>
  );
}
