import React from 'react';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { MaybeLink } from '~/components/elements/Link';
import { TextContent } from '~/components/flexible/TextContent';
import background from '~/assets/icons/f0f-background.svg';

export const FourOhFour = (page) => {
	const { subheading, heading, content, link, image } = page.fourOhFour;

	return (
		<InViewAnimator
			className="overflow-hidden"
			inner={(controls) => (
				<div className="relative container section-header-padding pb-8 md:pb-12 lg:pb-32">
					<img
						className="hidden lg:block absolute md:bottom-[-480px] xl:bottom-[-410px] left-[-386px]"
						src={background}
						alt=""
					/>
					<div className="relative md:flex items-center">
						<InViewAnimatorBasicItem
							controls={controls}
							delay={0.2}
							prop="y"
							start={-50}
							className="py-8 md:w-[60%] lg:w-[42%]"
						>
							<TextContent isMasthead={true} centered={false} linkStyle="button" {...page.fourOhFour} />
						</InViewAnimatorBasicItem>
						<InViewAnimatorBasicItem
							controls={controls}
							delay={0.5}
							prop="x"
							start={150}
							className="flex-1 md:ml-12 lg:ml-28 pt-8 xl:pt-12"
						>
							<img
								width="1073px"
								height="679px"
								className="max-w-none w-[165%]"
								src={image?.localFile?.publicURL}
								alt=""
								type="image"
							/>
						</InViewAnimatorBasicItem>
					</div>
				</div>
			)}
		/>
	);
};
