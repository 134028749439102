import React, { useState } from 'react';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { MaybeLink, ArrowLink } from '~/components/elements/Link';
import Marquee from 'react-fast-marquee';
import { AnimatePresence, motion } from 'framer-motion';

export const FeaturedIntegrations = ({ subheading, heading, content, link, items }) => {
	const categories = [];
	items.map((item) => {
		item.integrationCategories.nodes.map((cat) => {
			if (!categories.includes(cat.name)) {
				categories.push(cat.name);
			}
		});
	});
	const [activeCategory, set_activeCategory] = useState(categories[0]);

	items = items.concat(items);
	items = items.concat(items);

	return (
		<div className="overflow-hidden">
			<div className="text-center md:text-left container max-w-[1044px]">
				<h6 className="t-subheading mb-6">{subheading}</h6>
				<div className="md:flex space-y-4 md:space-y-0">
					<h3 className="t-42 md:pr-8 xl:pr-24 md:w-[50%] xl:w-[54%]">{heading}</h3>
					<div className="flex-1">
						<p className="t-20 mb-6">{content}</p>
						<MaybeLink link={link}>
							<ArrowLink inverted={true} link={link} />
						</MaybeLink>
					</div>
				</div>
			</div>
			<div className="pt-12 pb-8 md:pt-20 md:pb-12 lg:pt-36 lg:pb-16 space-y-4 md:space-y-6">
				<FeaturedIntegrationsMarquee items={items} activeCategory={activeCategory} direction="right" />
				<FeaturedIntegrationsMarquee items={items} activeCategory={activeCategory} direction="left" />
			</div>
			<FeaturedIntegrationsCategorySelector
				categories={categories}
				activeCategory={activeCategory}
				set_activeCategory={set_activeCategory}
			/>
		</div>
	);
};

export const FeaturedIntegrationsMarquee = ({ items, activeCategory, direction }) => (
	<Marquee gradient={false} speed={40} direction={direction}>
		{items.map((integration, i) => {
			const { logo } = integration.postTypeIntegration;
			const classes = [
				'w-[160px] md:w-[250px] mx-2 md:mx-3 border py-3 px-6 md:py-6 md:px-12 transtition-color duration-500 flex flex-col justify-center',
			];
			let active = false;
			integration.integrationCategories.nodes.map((cat) => {
				if (activeCategory === cat.name) {
					active = true;
				}
			});
			if (active) {
				classes.push('bg-torqouise invert-inner-image border-torqouise');
			} else {
				classes.push('border-white');
			}
			return (
				<div key={i} className={classes.join(' ')}>
					<MediaAssetInner className="mx-auto" type="image" image={logo} />
				</div>
			);
		})}
	</Marquee>
);

export const FeaturedIntegrationsCategorySelector = ({ categories, activeCategory, set_activeCategory }) => {
	return (
		<ul className="container text-center t-18 grid grid-cols-2 gap-2 md:block md:space-x-6 xl:space-x-8">
			{categories.map((category, i) => {
				const active = activeCategory === category;
				const classes = ['relative px-[6px] leading-[1em] py-[5.5px] cursor-pointer inline-block font-medium'];
				if (active) {
					classes.push('text-primaryText');
				} else {
					classes.push('opacity-50 hover:opacity-100 transition-opacity duration-500');
				}
				return (
					<li onClick={() => set_activeCategory(category)} className={classes.join(' ')} key={i}>
						<AnimatePresence>
							{active && (
								<motion.div
									initial={{ width: 0, opacity: 0 }}
									animate={{ width: '100%', opacity: 1 }}
									exit={{ width: 0, opacity: 0 }}
									transition={{ duration: 0.2 }}
									className="absolute left-0 top-0 bottom-0 bg-torqouise cursor-select"
								></motion.div>
							)}
						</AnimatePresence>
						<span className="relative">{category}</span>
					</li>
				);
			})}
		</ul>
	);
};
