import React, { useState, useEffect } from 'react';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { CarouselArrowButton } from '~/components/elements/Link';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

export const Testimonials = ({ heading, items }) => {
	const [active, set_active] = useState(0);

	return (
		<div className="container">
			<h5 className="t-20-heading pb-4 lg:pb-10">{heading}</h5>
			<div className="relative md:flex">
				<div className="md:w-[77%] flex-shrink border-small-grid">
					<div className="h-full overflow-hidden">
						<TestimonialsSwiper items={items} active={active} set_active={set_active} />
					</div>
				</div>
				<div className="md:ml-[5%] lg:ml-[8%] flex-1 pt-4 md:pt-0">
					<TestimonialsLogoNav items={items} active={active} set_active={set_active} />
				</div>
			</div>
		</div>
	);
};

export const TestimonialsSwiper = ({ items, active, set_active }) => {
	const [swiper, set_swiper] = useState({});

	useEffect(() => {
		if (swiper.on) {
			swiper.on('slideChange', function () {
				set_active(swiper.activeIndex);
			});
		}
	}, [swiper]);

	useEffect(() => {
		if (swiper.slideTo && swiper.activeIndex !== active) {
			swiper.slideTo(active);
		}
	}, [swiper, active]);

	return (
		<Swiper
			className="h-full"
			onInit={(ev) => {
				set_swiper(ev);
			}}
			spaceBetween={0}
			slidesPerView={1}
		>
			{items.map((item, i) => (
				<SwiperSlide key={i}>
					<TestimonialsSwiperItem {...item} active={active === i} />
				</SwiperSlide>
			))}
		</Swiper>
	);
};

export const TestimonialsSwiperItem = ({ active, content, name, byLine, icon }) => (
	<div className={`bg-secondaryBlue h-full flex flex-col justify-center p-[20px] lg:p-[45px] lg:py-[48px]`}>
		<div className="relative">
			<h2 className="max-w-[665px] t-26-carousel font-medium">{content}</h2>
			<div className="t-15 py-3 lg:pt-8 lg:pb-5">
				<strong>{name}</strong>
				<span className="block t-12 mt-[-2px]">{byLine}</span>
			</div>
			<img src={icon} alt="" />
		</div>
	</div>
);

export const TestimonialsLogoNav = ({ items, active, set_active }) => {
	return (
		<div className="border border-strokeGrey h-full flex md:flex-col">
			{items.map(({ icon }, i) => {
				const isActive = i === active;
				const classes = [
					'group w-full flex-1 flex flex-col py-3 px-2 md:py-6 md:px-6 md:py-6 justify-center transition duration-500 cursor-pointer',
				];
				if (isActive) {
					classes.push('bg-secondaryBlue');
				}
				if (i !== 0) {
					classes.push('border-l md:border-l-0 md:border-t border-strokeGrey');
				}
				return (
					<div key={i} onClick={() => set_active(i)} className={classes.join(' ')}>
						<img
							className={`mx-auto ${isActive ? '' : 'opacity-20 group-hover:opacity-80 transition duration-500'}`}
							src={icon}
							alt=""
						/>
					</div>
				);
			})}
		</div>
	);
};
