import React from 'react';
import { StagingIndex } from '~/components/landing/StagingIndex';
import { Metrics } from '~/components/landing/Metrics';
import { Integrations } from '~/components/landing/Integrations';
import { LatestJobs } from '~/components/landing/LatestJobs';
import { Newsroom } from '~/components/landing/Newsroom';
import { FourOhFour } from '~/components/landing/FourOhFour';
import { Contact } from '~/components/landing/Contact';
import { RequestDemo } from '~/components/landing/RequestDemo';
import { Resources } from '~/components/landing/Resources';
import { Customers } from '~/components/landing/Customers';
import { Legal } from '~/components/landing/Legal';

export const LandingPage = (page) => {
  const { layout } = page;

  if (layout === 'staging_index') {
    return <StagingIndex {...page} />;
  } else if (layout === 'integrations') {
    return <Integrations {...page} />;
  } else if (layout === 'metrics') {
    return <Metrics {...page} />;
  } else if (layout === 'newsroom') {
    return <Newsroom {...page} />;
  } else if (layout === 'latest-jobs') {
    return <LatestJobs {...page} />;
  } else if (layout === 'fourOhFour') {
    return <FourOhFour {...page} />;
  } else if (layout === 'contact') {
    return <Contact {...page} />;
  } else if (layout === 'demo-request') {
    return <RequestDemo {...page} />;
  } else if (layout === 'resources') {
    return <Resources {...page} />;
  } else if (layout === 'customer-stories') {
    return <Customers {...page} />;
  } else if (layout === 'legal') {
    return <Legal {...page} />;
  } else {
    console.log('IMPLEMENT', page);
    return null;
  }
};
