import React from 'react';
import { TextContent } from '~/components/flexible/TextContent';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const TwoColumnPullout = ({ columnA, columnB }) => {
  return (
    <InViewAnimator
      className="container"
      inner={(controls) => (
        <div className="pullout-borders py-8 lg:py-20">
          <div className="max-w-[940px] mx-auto md:flex md:space-x-4 items-center">
            <InViewAnimatorBasicItem className="flex-1 pb-6 md:pb-0" controls={controls} delay={0} prop="x" start={50}>
              <MediaAssetInner className="mx-auto md:mx-0 max-w-[120px] md:max-w-[185px]" {...columnB} />
            </InViewAnimatorBasicItem>
            <InViewAnimatorBasicItem
              className="md:w-[70%] lg:w-[65%]"
              controls={controls}
              delay={0.35}
              prop="x"
              start={-50}
            >
              <TextContent innerOnly={true} {...columnA} centered={false} headingSize="36" />
            </InViewAnimatorBasicItem>
          </div>
        </div>
      )}
    />
  );
};
