import React from 'react';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import Marquee from 'react-fast-marquee';

export const Logos = (props) => {
  const { layout } = props;

  if (layout === 'grid') {
    return <LogoGrid {...props} />;
  } else if (layout === 'divider') {
    return <LogoGridDivider {...props} />;
  } else if (layout === 'carousel') {
    return <LogoGridCarousel {...props} />;
  } else {
    return <LogoGridStandard {...props} />;
  }
};

export const LogoGridDivider = ({ heading, layout, items }) => {
  if (!items) items = [];
  return (
    <div className="container">
      <h3 className="mb-8 text-center t-42 md:mb-20 lg:mb-28">{heading}</h3>
      <LogoGroup size="small" baseDelay={0} logos={items.slice(0, 4)} />
      <div className="py-8 md:py-10">
        <div className="w-full h-px opacity-50 bg-strokeGrey" />
        <div className="h-px w-full bg-strokeGrey opacity-50 mt-2.5" />
      </div>
      <LogoGroup size="small" baseDelay={0.5} logos={items.slice(4)} />
    </div>
  );
};

export const LogoGridStandard = ({ heading, layout, items, rowAmount }) => {
  if (!items) items = [];
  return (
    <div className="container xl:my-[-1%]">
      <h3 className="mb-8 text-center t-42 md:mb-12 lg:mb-20">{heading}</h3>
      <LogoGroup logos={items} baseDelay={0} rowAmount={rowAmount} />
    </div>
  );
};

export const LogoGroup = ({ logos, size, baseDelay, rowAmount }) => {
  let rowClasses = '';

  if (rowAmount === '7') {
    rowClasses = 'md:grid-cols-3 lg:grid-cols-7 md:gap-4 ';
  } else if (rowAmount === '6') {
    rowClasses = 'lg:grid-cols-6 md:gap-4 ';
  } else if (rowAmount === '5') {
    rowClasses = 'lg:grid-cols-5 md:gap-4 ';
  } else {
    rowClasses = 'md:gap-8 lg:grid-cols-4';
  }
  return (
    <InViewAnimator
      className={`grid grid-cols-2 gap-4  gap-y-8 md:gap-y-16  ${rowClasses} `}
      inner={(controls) => (
        <>
          {logos?.map((item, i) => (
            <LogoItem baseDelay={baseDelay} controls={controls} i={i} size={size} {...item} key={i} />
          ))}
        </>
      )}
    />
  );
};

export const LogoItem = ({ logo, size, controls, baseDelay, i }) => {
  return (
    <InViewAnimatorBasicItem
      key={`logo-item-${i}`}
      controls={controls}
      delay={baseDelay + i / 6}
      duration={1.5}
      prop="y"
      className={`w-full flex flex-col justify-center ${size === 'small' ? 'px-4 md:px-6' : 'px-2'}`}
    >
      <MediaAssetInner className="mx-auto" type="image" image={logo} />
    </InViewAnimatorBasicItem>
  );
};

export const LogoGrid = ({ heading, layout, items }) => {
  if (!items) items = [];
  return (
    <InViewAnimator
      className="container"
      inner={(controls) => (
        <>
          <h3 className="relative mb-12 text-center md:text-left t-42 lg:mb-24">
            <div className="absolute left-0 right-0 hidden w-full h-px opacity-50 lg:block top-1/2 bg-strokeGrey" />
            <span className="relative inline-block bg-white lg:pr-16">{heading}</span>
          </h3>
          <div className="relative">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 gap-y-8 md:gap-y-16">
              {items.map((item, i) => (
                <LogoGridItem controls={controls} i={i} key={i} {...item} isLastCol={(i + 1) % 4 === 0} />
              ))}
            </div>
          </div>
        </>
      )}
    />
  );
};

export const LogoGridItem = ({ logo, name, jobTitle, isLastCol, controls, i }) => (
  <InViewAnimatorBasicItem
    key={`logo-grid-item-${i}`}
    controls={controls}
    delay={i / 6}
    duration={1.5}
    prop="x"
    className="relative text-center md:text-left"
  >
    <div
      className={`border-dashed-article is-alt absolute top-0 left-full right-0 ${
        !isLastCol ? 'lg:right-[-26px]' : 'xl:right-0'
      }`}
    ></div>
    <div
      className={`border-dashed-article is-alt absolute bottom-0 left-0 right-0 right-0 ${
        !isLastCol ? 'lg:right-[-26px]' : 'xl:right-0'
      }`}
    ></div>
    <div className="relative border-default-grid p-[20px] xl:p-[40px]">
      <div className="border border-secondaryBlue h-[75px] flex flex-col justify-center py-4 px-8">
        <MediaAssetInner className="mx-auto" type="image" image={logo} />
      </div>
      <div
        className={`border-dashed-article is-alt absolute bottom-[-1px] left-full ${
          !isLastCol ? 'lg:right-[-26px]' : 'xl:right-0'
        }`}
      ></div>
    </div>
    <div className="relative px-6 py-4 md:py-5">
      <div className="absolute top-0 bottom-0 left-0 border-dashed-article-vertical is-alt"></div>
      <div className="absolute top-0 bottom-0 right-0 border-dashed-article-vertical is-alt"></div>
      <strong className="block font-medium text-black t-20-heading">{name}</strong>
      <span className="block t-17">{jobTitle}</span>
    </div>
  </InViewAnimatorBasicItem>
);

export const LogoGridCarousel = ({ heading, layout, items, rowAmount, headingSize, hideGradient }) => {
  if (!items) items = [];

  let headingSizeClasses;

  if (headingSize === '36') {
    headingSizeClasses = 't-36 md:pb-14';
  } else {
    headingSizeClasses = 't-20-heading md:pb-6';
  }

  return (
    <div className="container text-center mt-[-50px] mb-0 md:my-[-60px] md:mb-[-30px]">
      <h6 className={`pb-3 ${headingSizeClasses}   `}>{heading}</h6>
      <div className={`relative pt-3 ${hideGradient === false && 'marquee-gradient'} `}>
        <Marquee gradient={false} speed={40}>
          {items.map(({ logo }, i) => (
            <div
              key={i}
              className="w-[150px] md:w-[185px] mx-[-8px] md:mx-0 py-0 px-0 md:py-3 md:px-2 flex flex-col justify-center"
            >
              <MediaAssetInner className="max-w-[150px] mx-6 md:mx-8" type="image" image={logo} />
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
};
