import React from 'react';
import exampleMetric from '~/assets/images/example-metric.svg';
import Marquee from 'react-fast-marquee';
import metricsBackground from '~/assets/icons/metrics-background.svg';
import { MediaAssetInner } from './MediaAsset';

export const MetricsMarquee = (props) => {
  const { images } = props;

  const metrics = [1, 2, 3, 4, 5, 6];
  return (
    <div className="relative">
      <Marquee gradient={false} speed={40} className="!items-center metrics-masthead-marquee">
        {images.map((image, i) => (
          <div key={i} className="w-[280px] md:w-auto">
            <MediaAssetInner className="w-full max-w-[463px] " type="image" image={image?.image} />
          </div>
        ))}
      </Marquee>
      <img className="hidden md:block absolute top-[20%] left-[60%]" src={metricsBackground} alt="" />
    </div>
  );
};
