import React, { useRef, useState, useEffect } from 'react';
import { useCustomerStories } from '~/hooks/useCustomerStories';
import { MaybeLink, ArrowLink, Button } from '~/components/elements/Link';
import { ResourceGridImage } from '~/components/flexible/ResourcesGrid';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import customerMedia from '~/assets/images/customer-media.jpg';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { CustomerStoryGrid } from '~/components/elements/Icon';
import Marquee from 'react-fast-marquee';
import g2Logo from '~/assets/icons/g2-logo.svg';

export const Customers = (page) => {
	const customerStories = useCustomerStories();
	return (
		<div className="overflow-hidden">
			<FeaturedCaseStudies items={page.customers.featuredCustomers} />
			<All items={customerStories} />
			<Reviews {...page.customers.reviews} />
		</div>
	);
};

export const FeaturedCaseStudies = ({ items }) => {
	return (
		<InViewAnimator
			className=""
			inner={(controls) => (
				<>
					<div className="container py-8 lg:py-16" key="main">
						{items?.slice(0, 2).map((item, i) => (
							<FeaturedCaseStudy key={i} item={item} controls={controls} flip={(i + 1) % 2 !== 0} />
						))}
					</div>
					{items?.slice(2, 3).map((item, i) => (
						<FeaturedCaseStudyAlt key={i} item={item} controls={controls} />
					))}
				</>
			)}
		/>
	);
};

export const FeaturedCaseStudy = ({ item, controls, flip = false }) => {
	const imageClasses = ['w-full md:w-[43%] mb-8 md:mb-0'];
	const imageWrapperClasses = ['md:w-[85%] '];
	const containerClasses = ['max-w-[1117px]'];
	const gridClasses = ['hidden md:block absolute -top-10 w-[50%] '];
	const bgSquareClasses = ['hidden md:block w-[85%] h-[55%] absolute -bottom-10'];

	if (flip) {
		imageClasses.push('md:ml-auto md:order-[1]');
		containerClasses.push('mr-auto ml-0');
		gridClasses.push('right-0');
		imageWrapperClasses.push('');
		bgSquareClasses.push('right-0');
	} else {
		imageClasses.push('md:mr-auto ');
		containerClasses.push('ml-auto mr-0');
		gridClasses.push('left-0');
		imageWrapperClasses.push('ml-auto');
		bgSquareClasses.push('left-0');
	}

	return (
		<section className="section">
			<div className={containerClasses.join(' ')}>
				<div className="flex flex-col md:flex-row items-center">
					<div className={imageClasses.join(' ')}>
						<div className="relative">
							<div className={`${gridClasses.join(' ')} ${getGridColourClass(item)}`}>
								<CustomerStoryGrid />
							</div>
							<div className={`${getFadedBgClass(item)} ${bgSquareClasses.join(' ')} `} />
							<div className={imageWrapperClasses.join(' ')}>
								<div className={`aspect-w-1 aspect-h-1 ${getBgClass(item)} border-dark-grid`}>
									<InViewAnimatorBasicItem
										controls={controls}
										delay={0.3}
										prop="x"
										start={50}
										className="flex items-center justify-center"
									>
										<MediaAssetInner
											className="w-full h-full max-w-[80%] max-h-[80%] mx-auto object-contain"
											type="image"
											image={item?.postTypeCustomerStory?.image}
										/>
									</InViewAnimatorBasicItem>
								</div>
							</div>
						</div>
					</div>
					<div className="md:w-[45%]">
						<div className="space-y-5">
							{item?.postTypeCustomerStory?.logo && (
								<div className=" w-[150px] h-[70px] flex items-center justify-center bg-white border-small-grid relative">
									<MediaAssetInner
										className="max-w-[75%] max-h-[75%] object-contain object-center"
										type="image"
										image={item?.postTypeCustomerStory?.logo}
									/>
								</div>
							)}
							<h3 className="t-36">{item?.postTypeCustomerStory?.title}</h3>
							<div className="t-20">{item?.postTypeCustomerStory?.summary} </div>
							<MaybeLink link={{ url: item?.uri }}>
								<ArrowLink link={{ title: 'Read the story' }} />
							</MaybeLink>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export const FeaturedCaseStudyAlt = ({ item, controls, flip = false }) => {
	return (
		<section className="section section-top section-bot bg-fadedGreen text-center md:text-left">
			<div className="container">
				<MaybeLink link={{ url: item?.uri }} className="max-w-[1046px] mx-auto">
					<div className="flex flex-col-reverse md:flex-row items-start md:space-x-[40px] xl:space-x-[146px]">
						<div className="md:w-[68%] bg-white border-default-grid p-6 md:p-12">
							<div className="space-y-3 md:space-y-5">
								<h3 className="t-36">{item?.postTypeCustomerStory?.title}</h3>
								<div className="t-20" dangerouslySetInnerHTML={{ __html: item?.postTypeCustomerStory?.summary }} />
								<ArrowLink link={{ title: 'Read the story' }} />
							</div>
						</div>
						<div className="md:flex-1 space-y-6 md:space-y-9 mx-auto md:mr-0 md:ml-auto mb-8 md:mb-0">
							<MediaAssetInner
								className="mx-auto md:mx-0 object-contain object-center"
								type="image"
								image={item?.postTypeCustomerStory?.profilePicture}
							/>

							<div className="space-y-1 md:space-y-4">
								<MediaAssetInner
									className="mx-auto md:mx-0 w-[106px]"
									type="image"
									image={item?.postTypeCustomerStory?.logo}
								/>
								<div className="t-14">
									<span className="font-medium">{item?.postTypeCustomerStory?.name} </span>
									<span>{item?.postTypeCustomerStory?.jobTitle}</span>
								</div>
							</div>
						</div>
					</div>
				</MaybeLink>
			</div>
		</section>
	);
};

export const All = ({ items }) => {
	const link = { title: 'View all', url: '/' };

	return (
		<section className="section section-top section-bot">
			<div className="container space-y-12">
				<div className="flex flex-col md:flex-row space-y-6 md:space-y-0 items-start md:items-center">
					<h4 className="t-36">All Customer Stories</h4>
					{false && (
						<div className="font-medium sm:ml-auto">
							<MaybeLink
								link={link}
								className="t-20 transition-colors duration-200 ease-in-out hover:text-primaryBlue border-b leading-tight"
							>
								{link.title}
							</MaybeLink>
						</div>
					)}
				</div>
				<InViewAnimator
					className="grid grid-cols-1 md:grid-cols-3 gap-y-12 lg:gap-y-20 gap-x-6"
					inner={(controls) => (
						<>
							{items?.slice(0, 12).map((item, i) => (
								<MaybeLink key={i} link={{ url: item.uri }} className="space-y-6 md:space-y-10 h-full flex flex-col">
									<div className={`border border-strokeGrey aspect-w-10 aspect-h-7 ${getBgClass(item)}`}>
										<InViewAnimatorBasicItem
											controls={controls}
											delay={(i + 1) * 0.3}
											prop="x"
											start={50}
											className="flex items-center justify-center"
										>
											<MediaAssetInner
												className="w-full h-full max-w-[80%] max-h-[80%] mx-auto object-contain"
												type="image"
												image={item?.postTypeCustomerStory?.image}
											/>
										</InViewAnimatorBasicItem>
										{/* <ResourceGridImage controls={controls} media={item?.postTypeCustomerStory?.image} delay={(i + 1) * 0.3} /> */}
									</div>
									<div className="space-y-3 xl:pr-10">
										{item?.postTypeCustomerStory?.logo && (
											<div className=" w-[143px] h-[46px] flex items-center justify-center bg-white border-small-grid relative">
												<MediaAssetInner
													className="max-w-[62%] max-h-[52%] object-contain object-center"
													type="image"
													image={item?.postTypeCustomerStory?.logo}
												/>
											</div>
										)}
										<h5 className="t-24 pt-3">{item?.postTypeCustomerStory?.title}</h5>
										<div className="t-15" dangerouslySetInnerHTML={{ __html: item?.postTypeCustomerStory?.summary }} />
									</div>
									<div className="pt-3 md:pt-5 !mt-auto">
										<ArrowLink link={{ title: 'Read the story' }} />
									</div>
								</MaybeLink>
							))}
						</>
					)}
				/>
			</div>
		</section>
	);
};

export const Reviews = ({ heading, link, items, badges }) => (
	<section className="overflow-hidden text-center section section-top section-bot bg-lightGrey">
		<div className="container space-y-6 max-w-[700px] mx-auto mb-8 lg:mb-16">
			<h3 className="t-42">{heading}</h3>
			<MaybeLink link={link}>
				<ArrowLink link={link} />
			</MaybeLink>
		</div>
		<Marquee gradient={false} speed={40}>
			{items.map(({ quote, name, jobTitle }, i) => (
				<div className="text-left mx-3 w-[300px] md:w-[430px]" key={i}>
					<div className="h-full t-18 md:w-auto relative bg-white border border-strokeGrey p-5 lg:p-10">
						<div>{quote}</div>
						<h6 className="pt-4 mr-6">
							<strong className="font-medium">{name}</strong> {jobTitle}
						</h6>
						<img className="absolute bottom-4 right-4 md:bottom-8 md:right-8" src={g2Logo} alt="G2 LOGO" />
					</div>
				</div>
			))}
		</Marquee>
		<div className="container mt-8 lg:mt-16">
			{badges.map(({ image }, i) => (
				<div key={i} className="inline-block mx-2 md:mx-4">
					<MediaAssetInner className="w-[80px] md:w-[105px]" type="image" image={image} />
				</div>
			))}
		</div>
	</section>
);

export const getBgClass = (item) => {
	const background = item?.postTypeCustomerStory?.previewBackground;
	if (background === 'green') {
		return 'bg-secondaryBlue';
	} else if (background === 'blue') {
		return 'bg-secondaryBlue';
	} else if (background === 'purple') {
		return 'bg-fadedPurple';
	} else if (background === 'yellow') {
		return 'bg-[#FFECCB]';
	} else if (background === 'white') {
		return 'bg-white';
	}
};

export const getFadedBgClass = (item) => {
	const background = item?.postTypeCustomerStory?.previewBackground;
	if (background === 'green') {
		return 'bg-fadedGreen';
	} else if (background === 'blue') {
		return 'bg-fadedBlue';
	} else if (background === 'purple') {
		return 'bg-fadedPurple';
	} else if (background === 'yellow') {
		return 'bg-fadedYellow';
	} else if (background === 'white') {
		return 'bg-[#EBF4FB]';
	}
};

export const getGridColourClass = (item) => {
	const background = item?.postTypeCustomerStory?.previewBackground;
	if (background === 'green') {
		return 'text-fadedGreen';
	} else if (background === 'blue') {
		return 'text-secondaryBlue';
	} else if (background === 'purple') {
		return 'text-fadedPurple';
	} else if (background === 'yellow') {
		return 'text-[#FFECCB]';
	} else if (background === 'white') {
		return 'text-[#EBF4FB]';
	}
};
