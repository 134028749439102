import React from 'react';
import {
  ResourcesGrid,
  ResourcesThreeColumn,
  ResourcesThreeColumnFeatured,
  ResourcesFeaturedTwoColumn,
  ResourcesVideos,
  ResourcesTwoColumn,
  ResourcesFeatured,
  ResourcesInsights,
} from '~/components/flexible/ResourcesGrid';
import resourceMedia from '~/assets/images/resource-media.png';
import { MaybeLink, UnderlineLink, ArrowLink } from '~/components/elements/Link';

export const ResourcesListing = ({
  heading,
  items,
  link,
  style,
  related = false,
  category = false,
  last = false,
  relatedCustomers = false,
  limit = 4,
}) => {
  if (!items) return null;
  return (
    <section
      className={`section section-tight ${related ? 'space-y-16' : 'space-y-10'} ${
        style === 'featured' && '!pt-0 -mt-3'
      }`}
    >
      <div
        className={`flex flex-col sm:flex-row space-y-4 sm:space-y-0 items-start sm:items-center ${
          related && 'border-b border-b-strokeGrey pb-5 '
        }`}
      >
        {heading && <h3 className={category ? 't-24' : 't-36'}>{heading}</h3>}
        {link && !category && (
          <div className="font-medium sm:ml-auto">
            <MaybeLink
              link={link}
              className="leading-tight transition-colors duration-200 ease-in-out border-b t-20 hover:text-primaryBlue"
            >
              {link.title}
            </MaybeLink>
          </div>
        )}
      </div>

      {style === 'two-col' ? (
        <ResourcesTwoColumn items={items} related={related} relatedCustomers={relatedCustomers} limit={limit} />
      ) : style === 'featured-two-col' ? (
        <ResourcesFeaturedTwoColumn items={items} related={related} limit={limit} />
      ) : style === 'videos' ? (
        <ResourcesVideos items={items} related={related} limit={limit} />
      ) : style === 'featured' ? (
        <ResourcesFeatured items={items} limit={limit} />
      ) : style === 'insights' ? (
        <ResourcesInsights items={items} limit={limit} />
      ) : style === 'three-col-featured' ? (
        <ResourcesThreeColumnFeatured items={items} limit={limit} />
      ) : (
        <ResourcesThreeColumn items={items} limit={limit} />
      )}

      {category && link && (
        <div className={`${!last && 'border-b border-strokeGrey border-opacity-50'} pt-10 pb-12`}>
          {link && (
            <div className="font-medium sm:ml-auto">
              <MaybeLink link={link} className="transition-colors duration-200 ease-in-out t-20 hover:text-primaryBlue">
                <ArrowLink link={link} />
              </MaybeLink>
            </div>
          )}
        </div>
      )}
    </section>
  );
};
