import React, { useEffect, useState } from 'react';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { AnimatePresence, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useInViewStart } from '~/hooks/useInViewStart';
import { useAutoProgressTimeout } from '~/hooks/useAutoProgressTimeout';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

export const Carousel = ({ items }) => {
	const [ref, started] = useInViewStart();
	const frameLength = 5;
	const [active, set_active] = useAutoProgressTimeout(started, frameLength, items.length - 1);

	return (
		<div ref={ref} className="container md:flex items-center xl:items-stretch space-y-8 md:space-y-0">
			<div className="md:w-[50%] xl:w-[60%] md:mr-10 xl:mr-16">
				<CarouselSwiper items={items} active={active} set_active={set_active} />
			</div>
			<div className="flex-1 space-y-4 flex-col justify-between flex">
				{items.map((item, i) => {
					return (
						<CarouselItem
							key={i}
							onClick={() => {
								set_active(i);
							}}
							isActive={i === active}
							item={item}
							frameLength={frameLength}
						/>
					);
				})}
			</div>
		</div>
	);
};

export const CarouselSwiper = ({ items, active, set_active }) => {
	const [swiper, set_swiper] = useState({});

	useEffect(() => {
		if (swiper.on) {
			swiper.on('slideChange', function () {
				set_active(swiper.activeIndex);
			});
		}
	}, [swiper]);

	useEffect(() => {
		if (swiper.slideTo && swiper.activeIndex !== active) {
			swiper.slideTo(active);
		}
	}, [swiper, active]);

	return (
		<div className="relative overflow-hidden">
			<Swiper
				onInit={(ev) => {
					set_swiper(ev);
				}}
				spaceBetween={0}
				slidesPerView={1}
			>
				{items.map((item, i) => (
					<SwiperSlide key={i}>
						<MediaAssetInner adjustment={{ width: '100%' }} {...item.asset} />
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export const CarouselItem = ({ item, isActive, onClick, frameLength }) => {
	let progressColorClass = 'bg-primaryBlue';
	if (item.progressColor === 'green') {
		progressColorClass = 'bg-progressGreen';
	} else if (item.progressColor === 'brown') {
		progressColorClass = 'bg-progressBrown';
	}
	return (
		<div
			className="pl-8 relative cursor-pointer group hover:opacity-70 transition-opacity duration-500"
			onClick={onClick}
		>
			<div className="bg-lightGrey w-[4px] absolute top-1 bottom-1 left-0">
				<AnimatePresence>
					{isActive && (
						<motion.div
							initial={{ height: 0, opacity: 1 }}
							animate={{ height: '100%', opacity: 1 }}
							exit={{ opacity: 0, duration: 0.5 }}
							transition={{
								height: {
									duration: frameLength,
								},
								opacity: {
									duration: 1,
								},
							}}
							className={`${progressColorClass} w-full top-0 left-0 right-0`}
						></motion.div>
					)}
				</AnimatePresence>
			</div>
			<h4 className="t-20-heading mb-1 transition-color duration-500">{item.heading}</h4>
			<p className="t-18 opacity-80">{item.content}</p>
		</div>
	);
};
