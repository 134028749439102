import { all } from 'core-js/fn/promise';
import { Link } from 'gatsby';
import React, { useState, useContext } from 'react';
import { ResourcesListing } from '~/components/flexible/Resources';
import Newsletter from '~/components/flexible/Newsletter';
import Cta from '~/components/flexible/Cta';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ResourcesIcon } from '~/components/elements/Icon';
import { MaybeLink, UnderlineLink, ArrowLink } from '~/components/elements/Link';
import { OptionsContext } from '~/templates/Layout';

export const Resources = (page) => {
	const { sections, cta } = page.resources;
	const viewAllLink = { title: 'View all resources', url: '/' };

	const videos = [];

	return (
		<ResourcesLayout>
			{sections.map((section, i) => {
				if (section.type === 'newsletter') {
					return <Newsletter key={i} grid />;
				} else {
					return (
						<ResourcesListing
							key={i}
							heading={section.heading}
							style={section.style}
							items={section.items}
							link={section.link}
						/>
					);
				}
			})}
			{false && <ViewAll link={viewAllLink} />}
			<Cta {...cta} />
		</ResourcesLayout>
	);
};

export const ResourcesLayout = ({ children }) => {
	return (
		<section className="section-header-padding">
			<div className="container container-wide pt-4 lg:pt-12">
				<div className="lg:grid lg:grid-cols-12 space-y-4 lg:space-y-0">
					<div className="w-full col-span-2 md:grid grid-cols-2 space-y-4 md:space-y-0 md:space-x-8 lg:space-x-0 lg:flex lg:flex-col lg:space-y-14 pb-6 lg:pb-16">
						<Filter />
					</div>
					<div className="col-span-10 lg:pl-[40px] xl:pl-[52px] relative">
						<div className="hidden lg:block border-dashed-resources absolute top-0 bottom-0 left-0" />
						{children}
					</div>
				</div>
			</div>
		</section>
	);
};

export const Filter = () => {
	const { options, page } = useContext(OptionsContext);
	const menus = options.header.headerOptions.resourceOptions.categorySidebar;
	const activeLink = page.link;
	return (
		<>
			{menus.map((sidebar, i) => (
				<FilterItem activeLink={activeLink} key={i} heading={sidebar.heading} items={sidebar.links} />
			))}
		</>
	);
};

export const ViewAll = ({ link }) => {
	return (
		<div className="flex justify-center section section-article-el">
			<div className="font-medium">
				<MaybeLink
					link={link}
					className="t-24 space-x-5 flex items-center border-b border-b-strokeGrey py-4 transition-opacity duration-200 ease-in-out hover:opacity-50"
				>
					<div className="flex items-center w-4 fw-icon">
						<ResourcesIcon />
					</div>
					<span>{link.title}</span>
				</MaybeLink>
			</div>
		</div>
	);
};

export const FilterItem = ({ heading, items, activeLink }) => {
	return (
		<div>
			<div className="space-y-8 hidden lg:block">
				<h4 className="t-24">{heading}</h4>
				<ul className="space-y-2.5">
					{items?.map(({ link }, i) => {
						const active = link.url.replace(/\//g, '') === activeLink.replace(/\//g, '');
						return (
							<li key={`filteritem${i}`} className={`border-l-2 ${active ? 'border-l-primaryBlue' : 'border-l-white'}`}>
								<Link
									to={link?.url}
									activeClassName="border-l-primaryBlue"
									className="py-1 pl-4 hover:text-primaryBlue transition-colors duration-200 ease-in-out hover:border-l-primaryBlue text-secondaryText t-15 font-medium"
								>
									{link?.title}
								</Link>
							</li>
						);
					})}
				</ul>
			</div>
			<div className=" lg:hidden">
				<FilterButton title={heading} items={items} />
			</div>
		</div>
	);
};

export const FilterButton = ({ title, items, tags = false }) => {
	const [expanded, setExpanded] = useState(false);
	return (
		<div className="relative w-full">
			<button
				className=" w-full border border-strokeGrey flex items-center px-3 py-2"
				onClick={() => setExpanded(!!!expanded)}
			>
				<div className="">{title}</div>
				<div className="flex items-center justify-center w-6 h-6 ml-auto">
					<div
						className={`w-[10px] fw-icon transition-transform duration-300 ease-in-out transform ${
							expanded ? 'rotate-180' : 'rotate-0'
						}`}
					>
						<ChevronDown />
					</div>
				</div>
			</button>
			<AnimatePresence>
				{expanded && (
					<motion.ul
						className="bg-white text-black  px-3 py-1 border border-strokeGrey absolute top-[calc(100%+12px)] z-20 left-0 right-0 overflow-hidden"
						initial="collapsed"
						animate="open"
						exit="collapsed"
						variants={{
							open: { opacity: 1, height: 'auto' },
							collapsed: { opacity: 0, height: 0 },
						}}
						transition={{ duration: 0.3 }}
					>
						{items?.map(({ link }, i) => (
							<li key={`filterButton${i}`}>
								<Link
									to={link.url}
									className={`px-3 py-2 flex items-center hover:bg-beige transition-colors duration-200 ease-in-out group rounded-[3px]`}
									activeClassName="hidden"
								>
									<span className="capitalize transition-opacity duration-200 ease-in-out">{link.title}</span>
								</Link>
							</li>
						))}
					</motion.ul>
				)}
			</AnimatePresence>
		</div>
	);
};
