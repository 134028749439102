import React from 'react';
import { TextContent } from '~/components/flexible/TextContent';

import Image from '~/components/elements/Image';

import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { MediaAssetInner } from './MediaAsset';
import { Button, MaybeLink } from '../elements/Link';
import { Link } from 'gatsby';

import plansGrid from '~/assets/icons/plans-grid.svg';

export const MastheadPlans = (props) => {
  const { awards, featuredImage, products } = props;
  return (
    <InViewAnimator
      className="relative section-header-padding"
      inner={(controls) => (
        <>
          <div className="absolute bottom-0 right-0 left-0 bg-white h-[262px]">
            <div className="container relative ">
              <div className="absolute top-[-206px] left-0 right-0 hidden xl:block ">
                <InViewAnimatorBasicItem controls={controls} delay={0.4} prop="y" start={75}>
                  <img src={plansGrid} className="w-full "></img>
                </InViewAnimatorBasicItem>
              </div>
            </div>
          </div>
          <div className="container relative">
            <div className="relative ">
              <InViewAnimatorBasicItem controls={controls} delay={0.2} prop="y" start={75}>
                <TextContent
                  visualControls={{ restrictWidth: { main: '700' } }}
                  isMasthead={true}
                  {...props}
                  link={null}
                  headingSize="60"
                  contentOpacity={100}
                />
              </InViewAnimatorBasicItem>
            </div>
            <div className="flex justify-center space-x-[26px] mt-[53px] relative">
              {awards?.map((award, i) => (
                <InViewAnimatorBasicItem
                  key={`logo-item-${i}`}
                  controls={controls}
                  delay={i / 6 + 0.5}
                  duration={1.5}
                  prop="y"
                  className="w-full max-w-[87px]"
                >
                  <Image
                    key={`award${i}`}
                    image={award?.image}
                    className="w-full max-w-[70px]  md:max-w-[87px]"
                  ></Image>
                </InViewAnimatorBasicItem>
              ))}
            </div>

            <div className="relative">
              <InViewAnimatorBasicItem controls={controls} delay={0.6} prop="y" start={75}>
                <div className="bg-white relative mt-[57px]  text-black border-dark-grid flex flex-col   xl:flex-row items-center py-[50px] pl-[24px] xl:pl-[72px] pr-[24px] justify-between">
                  <MediaAssetInner
                    className="w-full max-w-[178px] md:max-w-[247px]"
                    type="image"
                    image={featuredImage}
                  />

                  <div className="flex space-y-12 md:space-y-0 md:space-x-[15px] lg:space-x-[40px]  mt-12 xl:mt-0 flex-wrap md:flex-nowrap">
                    {products?.map((product, i) => (
                      <div key={`product${i}`} className="w-full text-center lg:text-left md:max-w-[234px]">
                        <h3 className="t-20  !text-primaryText font-medium">{product?.heading}</h3>

                        <div className="mt-[9px] md:max-w-[221px]  text-[0.875rem] leading-[1.57]">
                          {product?.content}
                        </div>

                        <div className="mt-3 md:space-y-3 ">
                          {product?.features?.map((feature, i) => (
                            <div className="space-x-[8px] whitespace-pre">
                              <span className="inline-block mt-[1px]">
                                <svg
                                  width="14"
                                  height="10"
                                  viewBox="0 0 14 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.8095 0.312336L5.05654 7.31428L2.59593 4.76296C2.39354 4.56028 2.12248 4.44813 1.84111 4.45067C1.55975 4.4532 1.29061 4.57022 1.09165 4.77652C0.892686 4.98281 0.779829 5.26188 0.777384 5.55362C0.774939 5.84535 0.883101 6.12641 1.07857 6.33626L4.29786 9.67423C4.49909 9.88282 4.77199 10 5.05654 10C5.34108 10 5.61398 9.88282 5.81522 9.67423L13.3269 1.88563C13.5224 1.67578 13.6305 1.39472 13.6281 1.10299C13.6256 0.811253 13.5128 0.532185 13.3138 0.32589C13.1148 0.119594 12.8457 0.00257716 12.5643 4.20614e-05C12.283 -0.00249304 12.0119 0.109656 11.8095 0.312336Z"
                                    fill="#56C175"
                                  />
                                </svg>
                              </span>
                              <span className="inline-block  text-[0.875rem] leading-[1.57]">{feature?.feature}</span>
                            </div>
                          ))}
                        </div>

                        <div className="mt-4 md:mt-8">
                          <Link to={product?.link?.url}>
                            <Button size="fullWidth" link={product?.link} buttonStyle="primary" />
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </InViewAnimatorBasicItem>
            </div>
          </div>
        </>
      )}
    />
  );
};
