import React, { useState, useEffect } from 'react';
import { MaybeLink, ArrowLink } from '~/components/elements/Link';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const PagesCta = ({ items }) => {
	return (
		<InViewAnimator
			className="container lg:py-6"
			inner={(controls) => (
				<div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-6">
					{items.map((item, i) => (
						<InViewAnimatorBasicItem key={`pages-cta-${i}`} controls={controls} delay={i / 5}>
							<PagesCtaItem key={i} {...item} />
						</InViewAnimatorBasicItem>
					))}
				</div>
			)}
		/>
	);
};

export const PagesCtaItem = ({ main, icon }) => {
	const { heading, content, link } = main;
	const [hover, setHover] = useState(false);
	const imageClasses = ['mx-auto md:mx-0 pb-4 lg:pb-8 lg:pb-4'];
	if (icon?.type === 'lottie') {
		imageClasses.push('w-[120px] md:w-[120px] my-[-4%] relative');
		if (heading === 'Collaboration') {
			imageClasses.push('md:left-[-15px]');
		} else {
			imageClasses.push('md:left-[-28px]');
		}
	} else {
		imageClasses.push('w-[40px] lg:w-[62px] lg:h-[95px]');
	}
	useEffect(() => {
		if (typeof window == 'undefined') return;
		let timeout;
		if (hover) {
			timeout = window.setTimeout(() => {
				setHover(false);
			}, 6000);
		}
		return () => {
			window.clearTimeout(timeout);
		};
	}, [hover]);
	return (
		<MaybeLink
			onMouseEnter={() => {
				setHover(true);
			}}
			link={link}
			className="flex flex-col h-full text-center md:text-left bg-darkNavy hover:bg-black transition-color duration-500 text-white p-[20px] py-[35px] lg:p-[55px] lg:pt-[75px]"
		>
			<div className={imageClasses.join(' ')}>
				<MediaAssetInner hovered={hover} className="w-full" {...icon} />
			</div>
			<h3 className="t-24">{heading}</h3>
			<p className="t-17 md:max-w-[410px] xl:min-h-[150px] py-3 lg:py-6">{content}</p>
			{link && (
				<div className="mt-auto">
					<ArrowLink inverted={true} link={link} />
				</div>
			)}
		</MaybeLink>
	);
};
