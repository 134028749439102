import React from 'react';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { useMetrics } from '~/hooks/useMetrics';
import { MaybeLink } from '~/components/elements/Link';
import metricSmall from '~/assets/icons/metric-small.svg';
import Marquee from 'react-fast-marquee';
import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

export const Metrics = (page) => {
  const { metrics, categories } = useMetrics();
  const {
    metrics: { metricsGrid, metricsMarquee, header },
  } = page;

  const [categoryFilter, setCategoryFilter] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  let showCategories;
  if (searchQuery.length < 1) {
    showCategories = categories;
  } else {
    showCategories = categories?.filter((cat) =>
      cat.metrics.some((metric) => metric.title.toLowerCase().includes(searchQuery))
    );
  }

  return (
    <>
      <MetricsHeader {...header} metricsMarquee={metricsMarquee} />
      <MetricsArchive
        categories={showCategories}
        metricsGrid={metricsGrid}
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        setCategoryFilter={setCategoryFilter}
        categoryFilter={categoryFilter}
      />
    </>
  );
};

export const MetricsHeader = ({ heading, subheading, content, metricsMarquee }) => (
  <section className="bg-lightGrey section section-top">
    <div className="container space-y-4 text-center section-header-padding">
      <h1 className="t-subheading-plain">{subheading}</h1>
      <h2 className="t-54 max-w-[540px] mx-auto">{heading}</h2>
      <p className="t-20 max-w-[505px] mx-auto">{content}</p>
    </div>
    <Marquee gradient={false} speed={40} className="!items-center metrics-masthead-marquee mt-16 pb-12">
      {metricsMarquee.map((image, i) => (
        <div key={i} className=" max-w-[327px] w-full mx-3">
          <MediaAssetInner className="w-full" type="image" image={image} />
        </div>
      ))}
    </Marquee>
  </section>
);

export const MetricsArchive = ({
  categories,
  metricsGrid,
  setSearchQuery,
  searchQuery,
  setCategoryFilter,
  categoryFilter,
}) => (
  <div className="container pt-12 md:flex">
    <MetricsSidebar categories={categories} setCategoryFilter={setCategoryFilter} categoryFilter={categoryFilter} />

    <MetricsContent
      categories={categories}
      metricsGrid={metricsGrid}
      setSearchQuery={setSearchQuery}
      searchQuery={searchQuery}
      categoryFilter={categoryFilter}
    />
  </div>
);

export const MetricsSidebar = ({ categories, setCategoryFilter, categoryFilter }) => (
  <div className="md:w-[250px] mb-6 md:mb-0 md:mr-8 lg:mr-12 pt-8 relative md:pb-24">
    <div className="sticky top-[10rem]">
      <h3 className="mb-6 t-24">Categories</h3>
      <ul className="hidden space-y-3 font-medium t-15 md:block">
        <motion.li
          className="relative cursor-pointer"
          whileHover="hover"
          initial="rest"
          onClick={() => setCategoryFilter(null)}
        >
          <CategoryLine active={categoryFilter === null} />

          <div>All</div>
        </motion.li>
        {categories.map((category, i) => (
          <motion.li key={i} className="relative" whileHover="hover" initial="rest">
            <CategoryLine i={i + 1} active={categoryFilter === category.slug} />
            <motion.a
              href={`#${category.slug}`}
              className="cursor-pointer "
              onClick={() => setCategoryFilter(category.slug)}
            >
              {category.tabTitle}
            </motion.a>
          </motion.li>
        ))}
      </ul>

      <select
        onChange={(e) =>
          e.target.value === null
            ? () => setCategoryFilter(null)
            : document.getElementById(e.target.value)?.scrollIntoView({
                behavior: 'smooth',
              })
        }
        // onChange={(e) => setCategoryFilter(e.target.value)}
        className="w-full flex flex-auto h-[72px] border border-strokeGrey px-5 t-20 md:hidden "
        name=""
        id=""
      >
        <option value={null}>All</option>

        {categories.map((category, i) => (
          <option key={`option${i}`} value={category?.slug}>
            {category.tabTitle}
          </option>
        ))}
      </select>
    </div>
  </div>
);

export const CategoryLine = ({ active, i }) => {
  return (
    <motion.div
      variants={{
        rest: { opacity: 0 },
        hover: { opacity: 1 },
      }}
      animate={{ opacity: active ? 1 : 0 }}
      transition={{ duration: 0.2 }}
      className="absolute left-[-15px] top-0 bottom-0 w-[2px] bg-primaryBlue h-full"
    ></motion.div>
  );
};

export const MetricsContent = ({ categories, metricsGrid, setSearchQuery, searchQuery, categoryFilter }) => {
  let categoryPage;
  if (categoryFilter !== null) {
    categoryPage = categories?.filter((cat) => cat.slug === categoryFilter);
  } else {
    categoryPage = categories;
  }

  return (
    <div className="flex-1 mb-32 space-y-12 md:space-y-24">
      <div>
        <SearchBar setSearchQuery={setSearchQuery} />
        <AnimatePresence initial={false}>
          {searchQuery < 1 && (
            <motion.div
              key="grid"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="gap-6 mt-12 space-y-6 sm:space-y-0 sm:grid md:grid-cols-2"
            >
              <div className="col-span-2 sm:pl-8 sm:pr-10 bg-fadedYellow sm:pb-11 p-8 sm:pt-[50px]">
                <div className="flex flex-col items-center justify-between sm:flex-row sm:space-x-6 sm:items-start">
                  <div className="order-1 mt-6 text-center sm:order-none sm:mt-0 sm:text-left">
                    <h3 className="t-20-heading ">{metricsGrid[0]?.heading}</h3>
                    <p className="t-17 max-w-[505px] mt-[15px] text-secondaryText">{metricsGrid[0]?.content}</p>
                  </div>
                  <MediaAssetInner
                    className="order-0 sm:order-none max-w-[180px] h-[110px] md:h-auto"
                    type="image"
                    image={metricsGrid[0]?.image}
                  />
                </div>
              </div>
              <div className="w-full p-8 text-center bg-fadedBlue sm:text-left">
                <MediaAssetInner
                  className="h-[110px] md:h-auto mx-auto sm:mx-0"
                  type="image"
                  image={metricsGrid[1]?.image}
                />
                <h3 className="mt-6 t-20-heading ">{metricsGrid[1]?.heading}</h3>
                <p className="t-17 mt-[15px] text-secondaryText max-w-[373px] mx-auto sm:mx-0">
                  {metricsGrid[1]?.content}
                </p>
              </div>
              <div className="w-full p-8 text-center bg-fadedGreen sm:text-left">
                <MediaAssetInner
                  className="h-[110px] md:h-auto mx-auto sm:mx-0"
                  type="image"
                  image={metricsGrid[2]?.image}
                />
                <h3 className="mt-6 t-20-heading">{metricsGrid[2]?.heading}</h3>
                <p className="t-17 mt-[15px] text-secondaryText max-w-[373px] mx-auto sm:mx-0">
                  {metricsGrid[2]?.content}
                </p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {categoryPage.length > 0 ? (
        categoryPage.map((category, i) => (
          <MetricsContentItem key={i} {...category} searchQuery={searchQuery} categoryFilter={categoryFilter} />
        ))
      ) : (
        <div className="t-20">No results found</div>
      )}
    </div>
  );
};

export const SearchBar = ({ setSearchQuery }) => {
  return (
    <div className="relative flex-auto w-full md:mt-[-87px] hidden md:flex">
      <div className="absolute left-[19px] top-[24px]">
        <SearchIcon />
      </div>
      <input
        className="input border border-strokeGrey h-[72px] w-full flex-auto t-20 pl-[62px] placeholder-black focus:placeholder-strokeGrey"
        placeholder="Search for a metric"
        onChange={(e) => setSearchQuery(e.target.value)}
      ></input>
    </div>
  );
};

export const MetricsContentItem = ({
  tabTitle,
  pageTitle,
  pageDescription,
  longDescription,
  metrics,
  searchQuery,
  categoryFilter,
  slug,
}) => {
  let metricsFilter;

  if (searchQuery.length > 0) {
    metricsFilter = metrics.filter((metric) => metric.title.toLowerCase().includes(searchQuery));
  } else {
    metricsFilter = metrics;
  }
  return (
    <section style={{ scrollMarginTop: '10rem' }} id={slug} className="">
      <h3 className="t-42">{pageTitle}</h3>
      <p
        className="t-20 max-w-[792px] mt-2 md:mt-4  mb-4 md:mb-16"
        dangerouslySetInnerHTML={{ __html: categoryFilter === null ? pageDescription : longDescription }}
      />
      <MetricsGrid metrics={metricsFilter} />
    </section>
  );
};

export const MetricsGrid = ({ metrics }) => {
  return (
    <InViewAnimator
      className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3"
      inner={(controls) => (
        <>
          {metrics.map((integration, i) => (
            <InViewAnimatorBasicItem
              key={`metrics-${i}`}
              controls={controls}
              delay={i / 12}
              prop="x"
              start={50}
              duration={1.5}
            >
              <MetricsGridItem {...integration} />
            </InViewAnimatorBasicItem>
          ))}
        </>
      )}
    />
  );
};

export const MetricsGridItem = ({ financialMetricsImported, link, uri }) => {
  const { name, description } = financialMetricsImported;

  return (
    <div className="relative h-full px-5 py-8 transition-shadow duration-300 bg-white border-opacity-50 hover:shadow-metric border-thin-grid-faded ">
      <MaybeLink link={{ url: uri }}>
        <div className="max-w-[80px]">
          <img src={metricSmall} alt="" />
        </div>
        <h4 className="pt-4 pb-3 t-20-heading">{name}</h4>
        <p className="t-15 opacity-80">{description}</p>
      </MaybeLink>
    </div>
  );
};

export const SearchIcon = ({}) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.2422 16.2432L24.0003 24.0012" stroke="#1A2024" strokeMiterlimit="10" strokeLinecap="round" />
      <path
        d="M9.77375 18.8292C14.7725 18.8292 18.8248 14.7769 18.8248 9.77814C18.8248 4.77936 14.7725 0.727051 9.77375 0.727051C4.77497 0.727051 0.722656 4.77936 0.722656 9.77814C0.722656 14.7769 4.77497 18.8292 9.77375 18.8292Z"
        stroke="#1A2024"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M3.61447 6.38759C1.88577 9.3818 2.91166 13.2105 5.90586 14.9392L12.1661 4.0962C9.17185 2.3675 5.34318 3.39339 3.61447 6.38759Z"
        fill="#88E8FF"
        stroke="#88E8FF"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};
