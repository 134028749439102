import React, { useState, useEffect } from 'react';
import { MaybeLink, ArrowLink } from '~/components/elements/Link';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';

export const StatementsCarousel = ({ items, link }) => {
	const [active, set_active] = useState(1);

	return (
		<div className="overflow-hidden">
			<div className="flex flex-col-reverse md:flex-col">
				<div className="mb-12 md:mb-0 container text-center grid md:grid-cols-3 gap-6 gap-y-3">
					{items.map((item, i) => (
						<StatementsCarouselItem key={i} active={i === active} onClick={() => set_active(i)} {...item} />
					))}
				</div>
				<div className="statements-swiper mb-12 md:mt-16 md:mb-16">
					<StatementsSwiper items={items} active={active} set_active={set_active} />
				</div>
			</div>
			{link && (
				<div className="container text-center">
					<MaybeLink link={link}>
						<ArrowLink link={link} />
					</MaybeLink>
				</div>
			)}
		</div>
	);
};

export const StatementsCarouselItem = ({ heading, active, onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`flex flex-col justify-center cursor-pointer t-20-heading py-4 px-4 xl:px-8 bg-white border-small-grid duration-500 transition-opacity ${
				active ? '' : 'opacity-50 hover:opacity-80'
			}`}
		>
			{heading}
		</div>
	);
};

export const StatementsSwiper = ({ items, active, set_active }) => {
	const [swiper, set_swiper] = useState({});

	useEffect(() => {
		if (swiper.on) {
			swiper.on('slideChange', function () {
				set_active(swiper.activeIndex);
			});
		}
	}, [swiper]);

	useEffect(() => {
		if (swiper.slideTo && swiper.activeIndex !== active) {
			swiper.slideTo(active);
		}
	}, [swiper, active]);

	return (
		<Swiper
			onInit={(ev) => {
				set_swiper(ev);
			}}
			spaceBetween={0}
			centeredSlides={true}
			breakpoints={{
				0: {
					slidesPerView: 1.25,
				},
				768: {
					slidesPerView: 2,
				},
				1900: {
					slidesPerView: 3,
				},
			}}
		>
			{items.map((item, i) => {
				const classes = ['transition-colors duration-500 rounded-[5px] shadow-statementShadow'];
				if (i === active) {
					classes.push('bg-white border-transparent');
				} else {
					classes.push('border border-[#b9b9c4]');
				}
				return (
					<SwiperSlide key={i} className="text-center px-4 md:px-8 xl:px-12">
						<div className="flex flex-col justify-center h-full">
							<div className={classes.join(' ')}>
								<MediaAssetInner {...item.image} />
							</div>
						</div>
					</SwiperSlide>
				);
			})}
		</Swiper>
	);
};
