import React from 'react';

export const ArrowRight = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="14" height="14" rx="7" fill="#EF4E2E" />
    <path d="M6 5L8 7L6 9" stroke="white" strokeMiterlimit="10" strokeLinecap="square" />
  </svg>
);

export const PaginationArrowRight = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.125 7.32129L13 7.32129"
      stroke="white"
      strokeWidth="1.46154"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.0625 13.2495L13 7.32202L7.0625 1.39453"
      stroke="white"
      strokeWidth="1.46154"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PaginationArrowUp = () => (
  <svg width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.043 8.613V.986M1.234 4.8 5.04.986 8.848 4.8"
      stroke="#1A2024"
      strokeWidth={1.462}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SmallArrowRight = () => (
  <svg width={9} height={9} fill="none">
    <path
      d="M1.024 4.86h7.003M4.526 8.357 8.027 4.86 4.526 1.365"
      stroke="#fff"
      strokeWidth={0.862}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ArrowLinkIcon = ({ inverted, className }) => (
  <svg className={className} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.8125 13L16.9375 13"
      stroke={inverted ? 'black' : 'white'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.875 17.0625L16.9375 13L12.875 8.9375"
      stroke={inverted ? 'black' : 'white'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PlayIcon = ({ color }) => {
  const classes = ['relative top-[-1px]'];
  if (color === 'blue') {
    classes.push('fill-[#0368FE] group-hover:fill-[#181A3D]');
  } else {
    classes.push('fill-[#fff] group-hover:fill-[#88E8FF]');
  }
  return (
    <svg className={classes.join(' ')} width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.5 0V11L10.5 5.5L0.5 0Z" fill="inherit" />
    </svg>
  );
};

export const SmallPlayIcon = () => (
  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 1.64103L6.13977 5.66943L0.5 9.69784V1.64103Z" fill="#0368FE" stroke="#0368FE" />
  </svg>
);

export const Quote = () => (
  <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.88 10.484H3.616L4.336 5.012V0.799998H0.16V5.012L0.88 10.484ZM7.684 10.484H10.384L11.14 5.012V0.799998H6.964V5.012L7.684 10.484Z"
      fill="#0368FE"
    />
  </svg>
);

export const ChevronDown = () => (
  <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 0.999756L6.5 5.99475L12 0.999756"
      stroke="#091A2A"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LogoInverted = () => (
  <svg width="137" height="22" viewBox="0 0 137 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2729_70795)">
      <path
        d="M56.0051 19.5359V12.2518C56.0051 10.2889 54.8531 8.64872 52.8509 8.64872C50.8486 8.64872 49.5869 10.2889 49.5869 12.2518V19.5359H46.7617V6.25564H49.3949L49.5869 8.03028C50.3549 6.5783 51.9457 6.04053 53.372 6.04053C55.1 6.04053 56.8554 6.73963 57.6234 8.7025C58.7206 6.73963 60.4211 6.06742 62.2588 6.06742C65.9864 6.06742 67.9887 8.37984 67.9887 12.198V19.5359H65.1361V12.198C65.1361 10.2351 64.1487 8.67561 62.1464 8.67561C60.1441 8.67561 58.855 10.2889 58.855 12.2518V19.5359H56.0051Z"
        fill="white"
      />
      <path
        d="M84.7788 12.924C84.7788 16.8471 82.0085 19.8048 77.7598 19.8048C73.5111 19.8048 70.793 16.8471 70.793 12.924C70.793 9.001 73.5632 6.01367 77.7598 6.01367C81.9564 6.01367 84.7788 9.0252 84.7788 12.924ZM73.6181 12.924C73.6181 15.2902 75.1815 17.2262 77.7598 17.2262C80.3381 17.2262 81.9262 15.2929 81.9262 12.924C81.9262 10.5552 80.2284 8.56809 77.7598 8.56809C75.1815 8.56809 73.6181 10.5847 73.6181 12.924Z"
        fill="white"
      />
      <path
        d="M96.4062 9.53569C95.3365 8.5677 94.1845 8.29881 92.8405 8.29881C91.1125 8.27193 89.9632 8.83659 89.9632 9.88524C89.9879 10.9877 91.1673 11.4179 92.8953 11.5792C95.3913 11.7943 98.6553 12.3321 98.6279 15.7443C98.6005 18.218 96.3787 19.9389 92.8679 19.9389C90.6736 19.9389 88.4793 19.4549 86.6992 17.492L88.1804 15.5318C89.4393 16.9005 91.3319 17.4651 92.9228 17.492C94.2667 17.492 95.7753 17.008 95.8027 15.7712C95.8302 14.5907 94.6782 14.1336 92.6759 13.9454C90.125 13.7034 87.1902 12.8968 87.1655 9.96591C87.1408 7.03505 90.2622 5.93262 92.7856 5.93262C94.8702 5.93262 96.4885 6.38972 97.997 7.70727L96.4062 9.53569Z"
        fill="white"
      />
      <path
        d="M112.313 6.28235H115.056V19.5357H112.368L112.231 17.3847C111.326 19.0786 109.241 19.8853 107.568 19.8853C103.619 19.9122 100.629 17.4115 100.629 12.8943C100.629 8.45764 103.728 5.98389 107.623 5.98389C109.543 5.98389 111.381 6.87121 112.231 8.37697L112.313 6.28235ZM103.454 12.8969C103.454 15.6369 105.429 17.304 107.843 17.304C113.685 17.304 113.685 8.5141 107.843 8.5141C105.429 8.5141 103.454 10.1812 103.454 12.8969Z"
        fill="white"
      />
      <path
        d="M122.081 2.38328C122.081 4.66881 118.57 4.66881 118.57 2.38328C118.57 0.0977481 122.081 0.124637 122.081 2.38328ZM118.899 6.22835V19.5355H121.725V6.22835H118.899Z"
        fill="white"
      />
      <path
        d="M136.999 17.8146C136.334 18.4861 135.536 19.0163 134.654 19.3725C133.771 19.7287 132.824 19.9032 131.87 19.8851C127.975 19.8851 124.738 17.492 124.738 12.9236C124.738 8.35522 127.947 5.95945 131.87 5.95945C132.778 5.93765 133.681 6.10085 134.521 6.43872C135.361 6.77659 136.121 7.28177 136.752 7.92232L134.947 9.77763C134.126 9.01291 133.035 8.58899 131.903 8.59453C129.461 8.59453 127.569 10.2885 127.569 12.9236C127.569 15.7173 129.544 17.1962 131.875 17.1962C132.468 17.2131 133.057 17.1123 133.609 16.8997C134.16 16.6871 134.662 16.3672 135.084 15.9593L136.999 17.8146Z"
        fill="white"
      />
      <path
        d="M6.19608 10.7078L11.2562 15.6683C11.374 15.7838 11.4402 15.9404 11.4402 16.1037C11.4402 16.267 11.374 16.4237 11.2562 16.5391L6.1922 21.5035C6.07441 21.6189 5.91464 21.6838 5.74806 21.6838C5.58147 21.6838 5.42171 21.6189 5.30391 21.5035L0.14681 16.4479C0.0542211 16.3571 0.00220461 16.234 0.00220458 16.1056C0.00220456 15.9773 0.0542217 15.8542 0.146811 15.7634L5.30585 10.7059C5.36434 10.6486 5.43381 10.6031 5.51026 10.5722C5.58672 10.5412 5.66865 10.5254 5.75137 10.5256C5.83408 10.5257 5.91595 10.5419 5.99227 10.5732C6.06858 10.6045 6.13785 10.6502 6.19608 10.7078Z"
        fill="#00CEFE"
      />
      <path
        d="M18.4359 8.63893L13.3699 3.6727C13.1246 3.43224 12.7269 3.43224 12.4816 3.67271L7.41569 8.63893C7.1704 8.8794 7.1704 9.26927 7.41569 9.50973L12.4816 14.476C12.7269 14.7164 13.1246 14.7164 13.3699 14.476L18.4359 9.50973C18.6812 9.26927 18.6812 8.8794 18.4359 8.63893Z"
        fill="#00CEFE"
      />
      <path
        d="M25.6976 15.5901L20.6316 10.6239C20.3863 10.3834 19.9887 10.3834 19.7434 10.6239L14.6774 15.5901C14.4321 15.8306 14.4321 16.2204 14.6774 16.4609L19.7434 21.4271C19.9887 21.6676 20.3863 21.6676 20.6316 21.4271L25.6976 16.4609C25.9429 16.2204 25.9429 15.8306 25.6976 15.5901Z"
        fill="#0368FE"
      />
      <path
        d="M32.8734 8.55788L27.8074 3.59165C27.5621 3.35119 27.1644 3.35119 26.9191 3.59165L21.8532 8.55788C21.6079 8.79834 21.6079 9.18821 21.8532 9.42868L26.9191 14.3949C27.1644 14.6354 27.5621 14.6354 27.8074 14.3949L32.8734 9.42868C33.1187 9.18821 33.1187 8.79834 32.8734 8.55788Z"
        fill="#0368FE"
      />
      <path
        d="M40.0335 15.6135L34.9676 10.6473C34.7223 10.4068 34.3246 10.4068 34.0793 10.6473L29.0133 15.6135C28.7681 15.854 28.7681 16.2439 29.0133 16.4843L34.0793 21.4506C34.3246 21.691 34.7223 21.691 34.9676 21.4506L40.0335 16.4843C40.2788 16.2439 40.2788 15.854 40.0335 15.6135Z"
        fill="#0131D0"
      />
    </g>
    <defs>
      <clipPath id="clip0_2729_70795">
        <rect width="137" height="21" fill="white" transform="translate(0 0.689453)" />
      </clipPath>
    </defs>
  </svg>
);

export const Logo = () => (
  <svg width="137" height="22" viewBox="0 0 137 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1860_21265)">
      <path
        d="M56.0061 19.5359V12.2518C56.0061 10.2889 54.8541 8.64872 52.8518 8.64872C50.8495 8.64872 49.5878 10.2889 49.5878 12.2518V19.5359H46.7627V6.25564H49.3958L49.5878 8.03028C50.3558 6.5783 51.9467 6.04053 53.373 6.04053C55.101 6.04053 56.8564 6.73963 57.6244 8.7025C58.7215 6.73963 60.4221 6.06742 62.2598 6.06742C65.9874 6.06742 67.9896 8.37984 67.9896 12.198V19.5359H65.1371V12.198C65.1371 10.2351 64.1496 8.67561 62.1474 8.67561C60.1451 8.67561 58.8559 10.2889 58.8559 12.2518V19.5359H56.0061Z"
        fill="#141728"
      />
      <path
        d="M84.7758 12.924C84.7758 16.8471 82.0056 19.8048 77.7569 19.8048C73.5082 19.8048 70.79 16.8471 70.79 12.924C70.79 9.001 73.5603 6.01367 77.7569 6.01367C81.9534 6.01367 84.7758 9.0252 84.7758 12.924ZM73.6152 12.924C73.6152 15.2902 75.1786 17.2262 77.7569 17.2262C80.3352 17.2262 81.9233 15.2929 81.9233 12.924C81.9233 10.5552 80.2254 8.56809 77.7569 8.56809C75.1786 8.56809 73.6152 10.5847 73.6152 12.924Z"
        fill="#141728"
      />
      <path
        d="M96.4052 9.53569C95.3355 8.5677 94.1835 8.29881 92.8395 8.29881C91.1115 8.27193 89.9622 8.83659 89.9622 9.88524C89.9869 10.9877 91.1663 11.4179 92.8943 11.5792C95.3903 11.7943 98.6543 12.3321 98.6269 15.7443C98.5995 18.218 96.3778 19.9389 92.8669 19.9389C90.6726 19.9389 88.4784 19.4549 86.6982 17.492L88.1794 15.5318C89.4383 16.9005 91.3309 17.4651 92.9218 17.492C94.2658 17.492 95.7743 17.008 95.8018 15.7712C95.8292 14.5907 94.6772 14.1336 92.6749 13.9454C90.1241 13.7034 87.1892 12.8968 87.1645 9.96591C87.1398 7.03505 90.2612 5.93262 92.7846 5.93262C94.8692 5.93262 96.4875 6.38972 97.9961 7.70727L96.4052 9.53569Z"
        fill="#141728"
      />
      <path
        d="M112.314 6.28235H115.057V19.5357H112.369L112.232 17.3847C111.327 19.0786 109.242 19.8853 107.569 19.8853C103.62 19.9122 100.63 17.4115 100.63 12.8943C100.63 8.45764 103.729 5.98389 107.624 5.98389C109.544 5.98389 111.382 6.87121 112.232 8.37697L112.314 6.28235ZM103.455 12.8969C103.455 15.6369 105.43 17.304 107.844 17.304C113.686 17.304 113.686 8.5141 107.844 8.5141C105.43 8.5141 103.455 10.1812 103.455 12.8969Z"
        fill="#141728"
      />
      <path
        d="M122.078 2.38328C122.078 4.66881 118.567 4.66881 118.567 2.38328C118.567 0.0977481 122.078 0.124637 122.078 2.38328ZM118.897 6.22835V19.5355H121.722V6.22835H118.897Z"
        fill="#141728"
      />
      <path
        d="M137 17.8146C136.335 18.4861 135.537 19.0163 134.655 19.3725C133.772 19.7287 132.825 19.9032 131.871 19.8851C127.976 19.8851 124.739 17.492 124.739 12.9236C124.739 8.35522 127.948 5.95945 131.871 5.95945C132.779 5.93765 133.681 6.10085 134.522 6.43872C135.362 6.77659 136.122 7.28177 136.753 7.92232L134.948 9.77763C134.127 9.01291 133.036 8.58899 131.904 8.59453C129.462 8.59453 127.57 10.2885 127.57 12.9236C127.57 15.7173 129.545 17.1962 131.876 17.1962C132.469 17.2131 133.058 17.1123 133.61 16.8997C134.161 16.6871 134.663 16.3672 135.085 15.9593L137 17.8146Z"
        fill="#141728"
      />
      <path
        d="M6.19413 10.7078L11.2543 15.6683C11.372 15.7838 11.4382 15.9404 11.4382 16.1037C11.4382 16.267 11.372 16.4237 11.2543 16.5391L6.19025 21.5035C6.07245 21.6189 5.91269 21.6838 5.7461 21.6838C5.57952 21.6838 5.41975 21.6189 5.30196 21.5035L0.144857 16.4479C0.052268 16.3571 0.000251489 16.234 0.000251457 16.1056C0.000251439 15.9773 0.0522686 15.8542 0.144858 15.7634L5.3039 10.7059C5.36239 10.6486 5.43185 10.6031 5.50831 10.5722C5.58476 10.5412 5.6667 10.5254 5.74942 10.5256C5.83213 10.5257 5.914 10.5419 5.99031 10.5732C6.06663 10.6045 6.13589 10.6502 6.19413 10.7078Z"
        fill="#00CEFE"
      />
      <path
        d="M18.4368 8.63893L13.3709 3.6727C13.1256 3.43224 12.7279 3.43224 12.4826 3.67271L7.41667 8.63893C7.17137 8.8794 7.17137 9.26927 7.41667 9.50973L12.4826 14.476C12.7279 14.7164 13.1256 14.7164 13.3709 14.476L18.4368 9.50973C18.6821 9.26927 18.6821 8.8794 18.4368 8.63893Z"
        fill="#00CEFE"
      />
      <path
        d="M25.6976 15.5901L20.6316 10.6239C20.3863 10.3834 19.9887 10.3834 19.7434 10.6239L14.6774 15.5901C14.4321 15.8306 14.4321 16.2204 14.6774 16.4609L19.7434 21.4271C19.9887 21.6676 20.3863 21.6676 20.6316 21.4271L25.6976 16.4609C25.9429 16.2204 25.9429 15.8306 25.6976 15.5901Z"
        fill="#0368FE"
      />
      <path
        d="M32.8734 8.55788L27.8074 3.59165C27.5621 3.35119 27.1644 3.35119 26.9191 3.59165L21.8532 8.55788C21.6079 8.79834 21.6079 9.18821 21.8532 9.42868L26.9191 14.3949C27.1644 14.6354 27.5621 14.6354 27.8074 14.3949L32.8734 9.42868C33.1187 9.18821 33.1187 8.79834 32.8734 8.55788Z"
        fill="#0368FE"
      />
      <path
        d="M40.0325 15.6135L34.9666 10.6473C34.7213 10.4068 34.3236 10.4068 34.0783 10.6473L29.0124 15.6135C28.7671 15.854 28.7671 16.2439 29.0124 16.4843L34.0783 21.4506C34.3236 21.691 34.7213 21.691 34.9666 21.4506L40.0326 16.4843C40.2778 16.2439 40.2778 15.854 40.0325 15.6135Z"
        fill="#0131D0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1860_21265">
        <rect width="137" height="21" fill="white" transform="translate(0 0.689453)" />
      </clipPath>
    </defs>
  </svg>
);

export const Facebook = () => (
  <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.05534 4.92855V3.60163C5.05534 2.93723 5.15256 2.55906 6.22196 2.55906H7.58301V0H5.34699C2.62489 0 1.74992 1.23214 1.74992 3.41113V5.02333H0V7.58239H1.65271V15.1648H5.05534V7.58239H7.29135L7.58301 4.92855H5.05534Z"
      fill="currentColor"
    />
  </svg>
);

export const YouTube = () => (
  <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.15625 9.36198V3.19531L11.884 6.27865L7.15625 9.36198ZM14.5598 0.832031H3.0487C1.69203 0.842309 0.602587 1.94203 0.582031 3.2987V9.87648C0.602587 11.2229 1.69203 12.3226 3.0487 12.3431H14.5598C15.9165 12.3226 17.0059 11.2229 17.0265 9.87648V3.2987C17.0059 1.94203 15.9165 0.842309 14.5598 0.832031Z"
      fill="currentColor"
    />
  </svg>
);

export const Twitter = () => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.9264 2.82795C14.9815 2.76247 14.9859 2.6691 14.9373 2.59885C14.8886 2.52857 14.7986 2.49817 14.7164 2.52449C14.3348 2.64673 13.9374 2.73361 13.5274 2.78252C14.0163 2.49187 14.4197 2.07364 14.6891 1.57413C14.7285 1.50106 14.7155 1.41136 14.657 1.35194C14.5985 1.29248 14.5078 1.27683 14.4321 1.313C13.9564 1.54044 13.4477 1.71096 12.9144 1.81496C12.3411 1.21006 11.5251 0.832031 10.6215 0.832031C8.88725 0.832031 7.48048 2.22575 7.48048 3.94516C7.48048 4.18872 7.50835 4.42613 7.56232 4.65451C5.18815 4.53619 3.05813 3.50008 1.52679 1.89654C1.42094 1.78566 1.26288 1.73878 1.11229 1.77352C0.961667 1.80825 0.84119 1.91928 0.796709 2.06492C0.709568 2.35046 0.662565 2.65335 0.662565 2.96691C0.662565 4.0476 1.14907 5.00025 1.99171 5.5585C1.7642 5.55128 1.44664 5.48201 1.16306 5.40705C1.00487 5.36523 0.835953 5.40912 0.719563 5.52245C0.603205 5.63574 0.557287 5.80118 0.598255 5.95702C0.903648 7.11859 1.89809 8.02375 3.08917 8.26091C2.82487 8.33228 2.582 8.37015 2.29535 8.37015C2.29525 8.37015 2.29516 8.37015 2.29506 8.37015C2.16663 8.37015 2.04782 8.4372 1.98316 8.54623C1.91849 8.65528 1.91789 8.78972 1.98114 8.89956C2.51427 9.82574 3.51769 10.4557 4.66396 10.4771C3.58889 11.3117 2.23842 11.809 0.767396 11.809C0.766502 11.809 0.765576 11.809 0.764682 11.809C0.68083 11.8089 0.607739 11.865 0.587462 11.945C0.567186 12.025 0.604993 12.1081 0.679042 12.1468C1.92057 12.7953 3.33513 13.1644 4.83595 13.1644C10.6131 13.1644 13.7731 8.42004 13.7731 4.30638C13.7731 4.1709 13.7701 4.03765 13.7641 3.90365C14.1949 3.59487 14.5861 3.23262 14.9264 2.82795Z"
      fill="currentColor"
    />
  </svg>
);

export const LinkedIn = () => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.785156 14.7579H3.94054V5.18994H0.785156V14.7579Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.5447 9.26062V14.7571H12.3893V9.56598C12.3893 8.24275 11.8804 7.42846 10.7608 7.42846C10.036 7.42134 9.3846 7.8692 9.13217 8.54811C9.04158 8.80869 9.00697 9.08656 9.03038 9.36241V14.7571H5.875V5.18916H9.03038V6.51238C9.62278 5.50063 10.7088 4.88075 11.8804 4.8838C13.9161 4.8838 15.5447 6.30881 15.5447 9.26062Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.41419 0.507118C1.49913 0.414492 0.6828 1.08221 0.590174 1.99625C0.585085 2.04308 0.583049 2.08888 0.582031 2.1357C0.583049 3.03855 1.31591 3.76836 2.21774 3.76632C2.24929 3.76632 2.28085 3.76632 2.3124 3.76429C3.22746 3.85589 4.04379 3.19021 4.13642 2.27515C4.14049 2.22833 4.14354 2.18252 4.14456 2.1357C4.17713 1.26441 3.4972 0.533582 2.62692 0.499993C2.55567 0.497957 2.48442 0.499993 2.41419 0.507118Z"
      fill="currentColor"
    />
  </svg>
);

export const Instagram = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.69584 0.207258C5.44266 0.173133 5.68087 0.165039 7.58203 0.165039C9.48319 0.165039 9.72141 0.173133 10.468 0.207039C11.6059 0.258883 12.6052 0.537789 13.4071 1.33973C14.2093 2.1421 14.488 3.14135 14.5398 4.27885C14.5739 5.02566 14.582 5.26388 14.582 7.16504C14.582 9.06619 14.5739 9.30441 14.54 10.051C14.4882 11.1889 14.2093 12.1882 13.4073 12.9901C12.605 13.7923 11.6055 14.071 10.4682 14.1228C9.72141 14.1569 9.48319 14.165 7.58203 14.165C5.68087 14.165 5.44266 14.1569 4.69606 14.123C3.55813 14.0712 2.55887 13.7923 1.75694 12.9904C0.954781 12.188 0.676094 11.1887 0.62425 10.0512C0.590125 9.30441 0.582031 9.06619 0.582031 7.16504C0.582031 5.26388 0.590125 5.02566 0.62425 4.27885C0.676094 3.14091 0.955 2.14188 1.75672 1.33995C2.55909 0.537789 3.55834 0.259102 4.69584 0.207258ZM10.4111 1.46718C9.67281 1.43349 9.45144 1.42627 7.58244 1.42627C5.71344 1.42627 5.49206 1.43327 4.754 1.46696C3.95731 1.50349 3.21816 1.66296 2.64941 2.23171C2.08044 2.80046 1.92097 3.53961 1.88466 4.3363C1.85097 5.07458 1.84375 5.29596 1.84375 7.16496C1.84375 9.03396 1.85075 9.25533 1.88444 9.99383C1.92097 10.7905 2.08044 11.5297 2.64919 12.0984C3.21794 12.6674 3.95709 12.8269 4.75378 12.8632C5.49184 12.8969 5.71322 12.9041 7.58244 12.9041C9.45166 12.9041 9.67303 12.8969 10.4111 12.8632C11.2076 12.8266 11.9469 12.6672 12.5157 12.0984C13.0847 11.5297 13.2441 10.7905 13.2804 9.99383C13.3141 9.25555 13.3213 9.03439 13.3213 7.16518C13.3213 5.29596 13.3141 5.07458 13.2804 4.33652C13.2439 3.53983 13.0844 2.80068 12.5157 2.23193C11.9469 1.66296 11.2078 1.50349 10.4111 1.46718Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.98438 7.16481C3.98438 5.17965 5.59375 3.57031 7.57894 3.57031C9.56413 3.57031 11.1735 5.17965 11.1735 7.16481C11.1735 9.14996 9.56413 10.7593 7.57894 10.7593C5.59375 10.7593 3.98438 9.14996 3.98438 7.16481ZM5.24609 7.16446C5.24609 8.45311 6.29087 9.49786 7.57957 9.49786C8.86826 9.49786 9.91305 8.45311 9.91305 7.16446C9.91305 5.8758 8.86826 4.83105 7.57957 4.83105C6.29087 4.83105 5.24609 5.8758 5.24609 7.16446Z"
      fill="currentColor"
    />
    <circle cx="11.3205" cy="3.42838" r="0.84" fill="currentColor" />
  </svg>
);

export const ResourcesIcon = () => (
  <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.3793 1H1V22H18.3793V1Z" stroke="#181A3D" strokeMiterlimit="10" strokeLinecap="square" />
    <path
      d="M15.4827 3.89655H3.89648V10.0517H15.4827V3.89655Z"
      fill="#88E8FF"
      stroke="#88E8FF"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path d="M3.89648 12.9483H15.4827" stroke="#181A3D" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M3.89648 15.8448H15.4827" stroke="#181A3D" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M3.89648 18.7414H10.0517" stroke="#181A3D" strokeMiterlimit="10" strokeLinecap="square" />
  </svg>
);

export const Email = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.8635 0.0825195H2.72045C2.2279 0.0825195 1.75552 0.278185 1.40723 0.626473C1.05895 0.97476 0.863281 1.44714 0.863281 1.93969L0.863281 2.31113L8.29197 6.48976L15.7207 2.40398V1.93969C15.7207 1.44714 15.525 0.97476 15.1767 0.626473C14.8284 0.278185 14.356 0.0825195 13.8635 0.0825195Z"
      fill="currentColor"
    />
    <path
      d="M7.82768 8.34684L0.863281 4.44678V11.2255C0.863281 11.718 1.05895 12.1904 1.40723 12.5387C1.75552 12.887 2.2279 13.0826 2.72045 13.0826H13.8635C14.356 13.0826 14.8284 12.887 15.1767 12.5387C15.525 12.1904 15.7207 11.718 15.7207 11.2255V4.44678L8.75626 8.34684C8.60992 8.41114 8.45182 8.44434 8.29197 8.44434C8.13212 8.44434 7.97402 8.41114 7.82768 8.34684Z"
      fill="currentColor"
    />
  </svg>
);

export const DownloadIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.99922 9C6.22422 9 6.37422 8.925 6.52422 8.775L10.7992 4.5L9.74922 3.45L6.74922 6.45V0H5.24922V6.45L2.24922 3.45L1.19922 4.5L5.47422 8.775C5.62422 8.925 5.77422 9 5.99922 9Z"
      fill="currentColor"
    />
    <path d="M11.25 10.5H0.75V12H11.25V10.5Z" fill="currentColor" />
  </svg>
);

export const CustomerStoryGrid = () => {
  return (
    <svg width="227" height="49" viewBox="0 0 227 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1.18555"
        y="0.882812"
        width="56.2547"
        height="22.5019"
        fill="currentColor"
        stroke="#9FA0AD"
        strokeWidth="0.787566"
      />
      <rect
        x="57.4414"
        y="0.882812"
        width="56.2547"
        height="22.5019"
        fill="currentColor"
        stroke="#9FA0AD"
        strokeWidth="0.787566"
      />
      <rect x="1.18555" y="23.3843" width="56.2547" height="22.5019" stroke="#9FA0AD" strokeWidth="0.787566" />
      <rect x="57.4414" y="23.3843" width="56.2547" height="22.5019" stroke="#9FA0AD" strokeWidth="0.787566" />
      <rect
        x="113.695"
        y="0.882812"
        width="56.2547"
        height="22.5019"
        fill="currentColor"
        stroke="#9FA0AD"
        strokeWidth="0.787566"
      />
      <rect x="169.951" y="0.882812" width="56.2547" height="22.5019" stroke="#9FA0AD" strokeWidth="0.787566" />
      <rect x="113.695" y="23.3843" width="56.2547" height="22.5019" stroke="#9FA0AD" strokeWidth="0.787566" />
      <rect x="169.951" y="23.3843" width="56.2547" height="22.5019" stroke="#9FA0AD" strokeWidth="0.787566" />
      <rect x="111.447" y="43.6357" width="4.50038" height="4.50038" fill="#9FA0AD" />
    </svg>
  );
};
