import React, { useState, Fragment } from 'react';
import { WistiaEmbed } from '~/components/elements/Video';
import { Dialog, Transition } from '@headlessui/react';

export const LinkModal = ({ open, set_open }) => (
  <Transition show={open} as={Fragment}>
    <Dialog open={open} onClose={() => set_open(false)} className="fixed z-10 inset-0 overflow-y-auto">
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-500"
        enterFrom="opacity-0"
        leave="ease-in duration-500"
        leaveTo="opacity-0"
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
      </Transition.Child>
      <Transition.Child
        as={Fragment}
        enter="transition duration-700 ease-out"
        enterFrom="transform scale-80 translate-y-4 opacity-0"
        enterTo="transform scale-100 translate-y-0 opacity-100"
        leave="transition duration-500 ease-out"
        leaveFrom="transform scale-200 translate-y-0 opacity-100"
        leaveTo="transform scale-80 translate-y-4 opacity-0"
      >
        <div className="flex items-center justify-center min-h-screen">
          <div className="relative w-[90%] max-w-[900px] min-h-[50px]">
            <WistiaEmbed hashedId="ba104jh5i9" playerColor="#54bbff" />
          </div>
        </div>
      </Transition.Child>
    </Dialog>
  </Transition>
);
