import React from 'react';
import { TextContent } from '~/components/flexible/TextContent';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import CountUp from 'react-countup';
import statsBackgroundGrid from '~/assets/icons/stats-background-grid.svg';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const MastheadStats = (props) => {
	const { asset, stats, config } = props;
	const imageClasses = ['relative md:w-[40%] md:pl-12'];
	const mainClasses = [
		'max-w-[500px] relative flex-1 mx-auto md:mx-0 pt-8 md:pt-12 lg:pt-24 lg:pb-24 xl:pt-36 xl:pb-28',
	];
	const statsBackgroundClasses = ['hidden md:block absolute top-0 right-0 xl:right-[-60px] xl:w-[660px] xl:max-w-none'];
	let contentMaxWidth = '450';
	if (config === 'planning') {
		statsBackgroundClasses.push('xl:top-[-48px]');
		imageClasses.push('lg:w-[57%]');
		contentMaxWidth = '500';
	} else {
		statsBackgroundClasses.push('xl:top-[-80px]');
		imageClasses.push('lg:w-[50%]');
	}
	return (
		<InViewAnimator
			className="section-header-padding"
			inner={(controls) => (
				<>
					<div className="container md:flex md:flex-row-reverse md:justify-between items-center">
						<div className={imageClasses.join(' ')}>
							<InViewAnimatorBasicItem controls={controls} delay={0.2} prop="x" start={50}>
								<img className={statsBackgroundClasses.join(' ')} src={statsBackgroundGrid} alt="" />{' '}
							</InViewAnimatorBasicItem>

							<InViewAnimatorBasicItem controls={controls} delay={0.4} prop="x" start={-50}>
								<MediaAssetInner className="w-[85%] mx-auto md:mx-0 md:w-auto relative" {...asset} />
							</InViewAnimatorBasicItem>
						</div>
						<div className={mainClasses.join(' ')}>
							<InViewAnimatorBasicItem controls={controls} delay={0} prop="x">
								<TextContent
									innerOnly={true}
									headingSize="52"
									centered={false}
									isMasthead={true}
									linkStyle="button"
									subheadingStyle="plain"
									centered={false}
									contentOpacity={90}
									visualControls={{
										restrictWidth: {
											content: contentMaxWidth,
										},
									}}
									{...props}
								/>
							</InViewAnimatorBasicItem>
						</div>
					</div>
					<div className="relative py-8 pt-12 lg:py-16">
						<div className="container text-center grid md:grid-cols-3 gap-6 gap-y-4 xl:gap-24">
							{stats.map((stat, i) => (
								<InViewAnimatorBasicItem key={i} controls={controls} delay={0.2 + i / 6} duration={0.5}>
									<MastheadStatsItem {...stat} duration={2.2} />
								</InViewAnimatorBasicItem>
							))}
						</div>
					</div>
				</>
			)}
		/>
	);
};

export const MastheadStatsItem = ({ number, append, description, duration }) => {
	if (!number) number = '0';
	if (!append) append = '';
	const strLen = number.length + append.length;
	return (
		<div>
			<div className="bg-white border-dark-grid t-56 px-6 py-3 md:py-6 font-space">
				<CountUp start={0} end={parseInt(number)} suffix={append} delay={0} duration={duration}>
					{({ countUpRef }) => (
						<div className="inline-block double-underline" style={{ width: `${strLen * 0.62}em` }}>
							<span ref={countUpRef} />
						</div>
					)}
				</CountUp>
			</div>
			<div className="t-18 font-medium pt-2 md:pt-4 xl:mx-[-5%]">{description}</div>
		</div>
	);
};
