import React, { useState, useEffect } from 'react';
import { Logo, LogoInverted } from '~/components/elements/Icon';
import { MaybeLink, Button } from '~/components/elements/Link';
import { Submenus } from '~/components/global/Submenus';
import { AnimatePresence, motion } from 'framer-motion';
import { MobileMenu } from '~/components/global/MobileMenu';
import { HubspotForm } from '~/components/elements/Hubspot';

export default function Header({ page, data, headerColor, showTopBar, headerType }) {
  if (!data) return null;
  if (headerType === 'hidden') return null;
  const header = data.headerOptions.header;
  const [headerScrolled, set_headerScrolled] = useState(false);
  const [openMenu, set_openMenu] = useState(null);
  const [openMobileMenu, set_openMobileMenu] = useState(false);

  useEffect(() => {
    const scrollEvent = () => {
      if (window.scrollY > 60) {
        set_headerScrolled(true);
      } else {
        set_headerScrolled(false);
      }
    };
    scrollEvent();
    window.addEventListener('scroll', scrollEvent);
    return () => window.removeEventListener('scroll', scrollEvent);
  }, []);

  let alternateHeaderClasses = '';
  if (page && page.link === '/strategic-finance/') {
    alternateHeaderClasses += ' greenHeader';
  }

  return (
    <>
      {showTopBar && <HeaderTopBar {...header.topBanner} />}
      <header
        className={`sticky top-[-1px] left-0 right-0 z-20 border-b-lightGrey ${
          headerScrolled ? ' header-scrolled' : ''
        }${headerColor === 'inverted' ? ' inverted' : ''}${alternateHeaderClasses}`}
        role="banner"
      >
        {openMobileMenu && <MobileMenu menu={header.menu} links={header.links} />}
        {openMenu !== null && (
          <div onMouseEnter={() => set_openMenu(null)} className="absolute top-36 left-0 right-0 h-[500px]"></div>
        )}
        <div
          className={`relative header-container flex items-center py-4${
            headerColor === 'inverted' ? ' text-white' : ''
          }`}
        >
          <a href="/" className="block w-[140px] xl:w-[240px]">
            {headerColor === 'inverted' ? <LogoInverted /> : <Logo />}
          </a>
          {headerType !== 'newsletter' ? (
            <>
              <HeaderMenuLinks links={header.menu} openMenu={openMenu} set_openMenu={set_openMenu} />
              <HeaderCtas links={header.links} />
            </>
          ) : (
            <div className="header-form hidden md:block ml-auto hubspot-inline">
              <HubspotForm formId="1f256645-635c-47ca-8659-d91e14c0361a" />
            </div>
          )}
          <HeaderHamburger openMobileMenu={openMobileMenu} set_openMobileMenu={set_openMobileMenu} />
        </div>
        <Submenus menu={header.menu} openMenu={openMenu} />
      </header>
    </>
  );
}

export const HeaderTopBar = ({ tag, title, link }) => (
  <a
    href={link.url}
    className="block t-13 bg-primaryBlue text-white text-center py-[8px] flex items-center space-x-3 justify-center px-6"
  >
    <span className="text-[8px] font-medium hidden md:inline-block rounded-[15px] py-[3px] px-2 bg-torqouise text-darkNavy">
      {tag}
    </span>
    <span>{title}</span>
    <span className="relative top-[1px] link-underline close font-medium" href="#">
      {link.title}
    </span>
  </a>
);

export const HeaderHamburger = ({ openMobileMenu, set_openMobileMenu }) => (
  <div
    onClick={() => {
      set_openMobileMenu(!openMobileMenu);
    }}
    className="w-[73px] ml-auto lg:hidden"
  >
    <MobileMenuButton openMobileMenu={openMobileMenu} />
  </div>
);

export const HeaderMenuLinks = ({ links, openMenu, set_openMenu }) => (
  <div className="default-link-hovers hidden lg:block t-16 flex-1">
    <ul className="flex space-x-6 xl:space-x-8 justify-center">
      {links.map(({ link, showSubmenu }, i) => (
        <li
          key={i}
          onMouseEnter={() => {
            if (showSubmenu) {
              set_openMenu(i);
            } else {
              set_openMenu(null);
            }
          }}
        >
          <a href={link.url}>{link.title}</a>
        </li>
      ))}
    </ul>
  </div>
);

export const HeaderCtas = ({ links }) => (
  <div className="hidden lg:flex ml-auto w-[275px] space-x-8 items-center">
    {links.map(({ link, type }, i) => {
      if (type === 'default') {
        return (
          <a href={link.url} key={i} className="t-17 font-medium ml-auto" href="#">
            {link.title}
          </a>
        );
      } else {
        return (
          <a href={link.url} key={i}>
            <Button link={{ title: link.title }} size="header" buttonStyle="primary" />
          </a>
        );
      }
    })}
  </div>
);

export const MobileMenuButton = ({ openMobileMenu }) => (
  <div className="h-[37px] bg-[#0368FE] pt-[14px] px-[12px] space-y-1">
    <div
      className={`bg-white h-[2px] transition-transform duration-500`}
      style={{ transform: openMobileMenu ? 'rotate(15deg) translateY(3px)' : '' }}
    ></div>
    <div
      className={`bg-white h-[2px] transition-transform duration-500`}
      style={{ transform: openMobileMenu ? 'rotate(-15deg) translateY(-3px)' : '' }}
    ></div>
  </div>
);
