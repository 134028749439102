import React, { useContext, useState, createContext, useEffect } from 'react';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { useIntegrationsCategories } from '~/hooks/useIntegrationsCategories';
import { MaybeLink, CarouselArrowButtonUp } from '~/components/elements/Link';
import { AnimatePresence, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-scroll';

const contextDefaultValues = {
  activeSection: '',
  handleActive: (active) => null,
};
export const GlobalContext = createContext(contextDefaultValues);
export const Integrations = (page) => {
  const [activeSection, setActiveSection] = useState('');
  const handleActive = (active: string) => {
    setActiveSection(active);
  };
  const { header } = page.integrations;
  const _categories = useIntegrationsCategories();
  // Naive resorting
  const reorder = ['ERP', 'CRM', 'HRIS', 'BILLING'];
  const categories = [];
  ['ERP', 'CRM', 'HRIS', 'BILLING'].map((cat) => {
    _categories.map((_cat) => {
      if (_cat.taxonomyIntegrationCategory.shortName === cat) {
        categories.push(_cat);
      }
    });
  });
  return (
    <GlobalContext.Provider
      value={{
        activeSection,
        handleActive,
      }}
    >
      <IntegrationsHeader categories={categories} {...header} />
      <IntegrationsArchive categories={categories} />
    </GlobalContext.Provider>
  );
};

export const IntegrationsHeader = ({ heading, subheading, asset }) => (
  <section className="container section section-top">
    <div className="relative z-10 section-header-padding text-center space-y-4">
      <h1 className="t-subheading-plain">{subheading}</h1>
      <h2 className="t-58 max-w-[750px] mx-auto">{heading}</h2>
    </div>
    <div className="max-w-[1440px]">
      <div className="mt-[-32%] mb-[-32%] mx-[-50%] min-h-[200px] lg:min-h-[1000px]">
        <MediaAssetInner {...asset} />
      </div>
    </div>
  </section>
);

export const IntegrationsArchive = ({ categories }) => (
  <div className="container relative md:flex">
    <div className="hidden xl:flex">
      <IntegrationsSidebar categories={categories} />
    </div>
    <IntegrationsContent categories={categories} />
  </div>
);

export const IntegrationsSidebar = ({ categories }) => {
  const { activeSection } = useContext(GlobalContext);
  return (
    <div className="relative md:w-[250px] mb-8 md:mb-0 md:mr-8 lg:mr-12">
      <div className="sticky top-20">
        <h3 className="t-24 mb-6">Categories</h3>
        <ul className="t-15 font-medium space-y-3">
          {categories.map((category, i) => {
            return (
              <li
                key={i}
                className={`t-15 pl-[15px] ${
                  activeSection === category.taxonomyIntegrationCategory.shortName
                    ? 'border-l-2 border-primaryBlue'
                    : null
                }`}
              >
                <Link
                  className="cursor-pointer"
                  to={category.taxonomyIntegrationCategory.shortName}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  {category.taxonomyIntegrationCategory.shortName}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export const IntegrationsContent = ({ categories }) => {
  return (
    <div className="flex-1 space-y-24">
      {categories.map((category, i) => (
        <IntegrationsContentItem
          key={i}
          {...category.taxonomyIntegrationCategory}
          integrations={category.integrations}
        />
      ))}
    </div>
  );
};

export const IntegrationsContentItem = ({ shortName, title, description, benefits, color, integrations }) => {
  const [active, set_active] = useState(0);
  const { ref, inView } = useInView();
  const { handleActive } = useContext(GlobalContext);
  let colorClass = '';
  if (color === 'blue') {
    colorClass = 'bg-fadedBlue';
  } else if (color === 'yellow') {
    colorClass = 'bg-fadedYellow';
  } else if (color === 'green') {
    colorClass = 'bg-fadedGreen';
  } else if (color === 'brown') {
    colorClass = 'bg-lightGrey';
  }
  useEffect(() => {
    if (inView) {
      handleActive(shortName);
    }
  }, [inView]);
  return (
    <section id={shortName} className="relative z-10 space-y-4">
      <h6 className={`t-subheading text-black ${colorClass}`}>{shortName}</h6>
      <h3 className="t-42">{title}</h3>
      <p className="t-20 max-w-[792px]">{description}</p>
      <div>
        <IntegrationsBenefits
          benefits={benefits}
          colorClass={colorClass}
          color={color}
          active={active}
          set_active={set_active}
        />
      </div>
      <div ref={ref}>
        <IntegrationsGrid colorClass={colorClass} integrations={integrations} category={shortName} />
      </div>
    </section>
  );
};

export const IntegrationsBenefits = ({ benefits, colorClass, color, active, set_active }) => {
  let bgColor = '';
  if (color === 'blue') {
    bgColor = 'bg-[#0368FE]';
  } else if (color === 'yellow') {
    bgColor = 'bg-[#FFCF3E]';
  } else if (color === 'green') {
    bgColor = 'bg-[#56C175]';
  } else if (color === 'brown') {
    bgColor = 'bg-[#927E60]';
  }
  return (
    <div className={`p-5 md:px-[32px] md:py-[35px] my-5 md:mt-11 md:mb-10 flex flex-row justify-between ${colorClass}`}>
      <AnimatePresence>
        {benefits.map(({ heading, content }, i) => {
          if (i != active) return null;
          return (
            <motion.div
              className="max-w-[657px] pr-5"
              key={`team-card-${i}`}
              transition={{ type: 'tween' }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0, duration: 0.75 }}
            >
              <IntegrationsBenefitsItem heading={heading} content={content} i={i} />
            </motion.div>
          );
        })}
      </AnimatePresence>
      <CarouselArrowButtonIntegrations bgColor={bgColor} active={active} set_active={set_active} items={benefits} />
    </div>
  );
};

export const IntegrationsBenefitsItem = ({ heading, content, i }) => (
  <div className="space-y-2 " key={i}>
    <h4 className="t-20-heading text-primaryText">{heading}</h4>
    <p className="t-17">{content}</p>
  </div>
);

export const CarouselArrowButtonIntegrations = ({ items, bgColor, active, set_active }) => {
  return (
    <div>
      <div>
        <CarouselArrowButtonUp
          bgColor={bgColor}
          active={active !== 0}
          onClick={() => {
            set_active(active - 1);
          }}
        />
      </div>
      <div>
        <CarouselArrowButtonUp
          bgColor={bgColor}
          flip
          active={active !== items.length - 1}
          onClick={() => {
            set_active(active + 1);
          }}
        />
      </div>
    </div>
  );
};

export const IntegrationsGrid = ({ integrations, category, colorClass }) => {
  return (
    <InViewAnimator
      className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[17.4px]"
      inner={(controls) => (
        <>
          {integrations.nodes?.map((integration, i) => (
            <InViewAnimatorBasicItem
              key={`integrations-${i}`}
              controls={controls}
              delay={i / 6}
              prop="x"
              start={50}
              duration={1.5}
            >
              <IntegrationsGridItem colorClass={colorClass} category={category} {...integration} />
            </InViewAnimatorBasicItem>
          ))}
        </>
      )}
    />
  );
};

export const IntegrationsGridItem = (props) => {
  const { link, category } = props;
  const { name, gridViewLogo, shortDescription } = props.integration;
  return (
    <MaybeLink link={{ url: link }} className="relative border-very-small-grid hover:border-darkNavy py-6 px-5 h-full">
      <h6 className={`absolute top-2 right-2 t-subheading text-primaryText is-small ${props.colorClass}`}>
        {category}
      </h6>
      <div className="max-w-[150px] h-[36.44px] flex flex-row items-center">
        <MediaAssetInner type="image" image={gridViewLogo} />
      </div>
      <h4 className="t-20-heading text-primaryText pt-4 pb-3">{name}</h4>
      <p className="t-15 opacity-80">{shortDescription}</p>
    </MaybeLink>
  );
};
