import { motion, useTransform, useViewportScroll } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';
import ProgressWindow from '../StrategicMasthead/ProgressWindow';
import { MediaAssetImage } from './MediaAsset';
import { uniq } from 'lodash';
import stratBg1 from '~/assets/icons/strat-bg-1.svg';
import stratBg2 from '~/assets/icons/strat-bg-2.svg';

const pages = [
  {
    tabLabel: 'Siloed Systems',

    textBubbles: [
      {
        content: "Today's businesses run on a host of cloud-based tools. ",
        className:
          ' top-[107px] sm:top-[37px] !w-[281px] left-[30px] sm:left-[40px] sm:!w-[757px] h-[82px] sm:!h-[77px]',
      },
      {
        content: 'Answers to critical financial questions are scattered across dozens of datasets. ',
        className: 'sm:top-[227px] !h-[109px]  sm:left-[148px] top-[242px] left-[100px] sm:!w-[1081px] sm:!h-[77px]',
      },
      {
        content: "Getting a holistic picture of your company's finances is extremely difficult and time consuming. ",
        className: 'sm:top-[417px] !h-[109px] sm:left-[40px] top-[404px] left-[30px] sm:!w-[1297px] sm:!h-[77px]',
      },
    ],
    autoImage: true,
    images: [
      {
        image: 'https://cms.mosaic.and.together.agency/wp-content/uploads/2022/02/window-1.svg',
        className: 'top-[82px] left-[39px] sm:left-[904px] max-w-[200px] sm:max-w-[383px]',
      },
      {
        image: 'https://cms.mosaic.and.together.agency/wp-content/uploads/2022/02/gusto.svg',
        className: 'top-[153px] sm:top-[108px] left-[94px] sm:left-[260px] max-w-[200px] sm:max-w-[383px]',
      },
      {
        image: 'https://cms.mosaic.and.together.agency/wp-content/uploads/2022/02/salesforce.svg',
        className: 'top-[238px] left-[145px] sm:left-[643px] max-w-[200px] sm:max-w-[383px]',
      },
      {
        image: 'https://cms.mosaic.and.together.agency/wp-content/uploads/2022/02/stripe.svg',
        className: 'top-[323px] sm:top-[273px] left-[101px] sm:left-[183px] max-w-[200px] sm:max-w-[383px]',
      },
      {
        image: 'https://cms.mosaic.and.together.agency/wp-content/uploads/2022/02/hubspot.svg',
        className: 'top-[417px] sm:top-[342px] left-[55px] sm:left-[793px] max-w-[200px] sm:max-w-[383px]',
      },
    ],
  },
  {
    tabLabel: 'Manual Consolidation',

    textBubbles: [
      {
        blockType: 'custom',
        content:
          '80% of time and energy is spent on low-value tasks...and all of that effort is needed just to understand what happened in the past. ',
        className: 'top-[80px] left-[30px] h-[163px] sm:!h-[77px]  sm:top-[37px] sm:left-[40px] sm:!w-[973px] ',

        blockClasses: {
          bodyBlockClasses: ' w-[867px] !h-[113px]',
          appendBlockClasses: ' w-[110px] ',
          whiteBlockClasses: 'w-[107px]',
        },
      },
      {
        content: 'Modeling and planning for future scenarios is even more complex. ',
        className: 'top-[296px] h-[109px] left-[100px] sm:top-[227px] sm:left-[256px] sm:h-[77px] sm:!w-[973px]',
      },
      {
        blockType: 'custom',
        content:
          'To be strategic, finance teams need to be part data analyst, part systems architect, planners, and economists.   ',
        className: 'sm:top-[417px] left-[30px] top-[458px] sm:left-[40px] sm:!w-[973px] h-[136px] sm:h-[113px] ',
        blockClasses: {
          bodyBlockClasses: ' w-[543px] !h-[113px]',
          appendBlockClasses: ' w-[436px] ',
          whiteBlockClasses: ' !right-[-1px]',
          amountToShow: 4,
        },
      },
    ],

    images: [
      {
        type: 'progressWindow',
        className: 'top-[82px] left-[52px] sm:left-[904px] w-full max-w-[383px]',
      },
      {
        type: 'image',
        image: 'https://cms.mosaic.and.together.agency/wp-content/uploads/2022/02/loading-1.svg',
        className: 'top-[228px] sm:top-[108px] left-[71px] sm:left-[260px] max-w-[383px]',
      },
    ],
  },
  {
    tabLabel: 'Outdated Tools',

    textBubbles: [
      {
        blockType: 'custom',
        content: 'Finance’s responsibilities have grown, but their toolkit has remained the same.',
        className: 'left-[30px] h-[109px] top-[107px] sm:top-[36px] sm:left-[40px] sm:!w-[865px] sm:!h-[77px]',
        blockClasses: {
          bodyBlockClasses: ' w-[327px]',
          appendBlockClasses: ' w-[540px]',
          whiteBlockClasses: ' !right-[-1px] !h-[39px]',
          amountToShow: 5,
        },
      },
      {
        content: 'While every other department has gotten software to make their lives easier...',
        className:
          'sm:top-[226px] h-[109px] sm:h-[114px] top-[269px] left-[100px] sm:left-[148px] sm:!h-[78px] sm:!w-[1081px]',
      },
      {
        blockType: 'custom',
        content: 'Finance is still performing their most strategic work in manual, disconnected spreadsheets.',
        className: 'top-[431px] h-[109px] left-[30px] sm:top-[417px] sm:left-[364px] w-[256px] sm:!w-[865px]',
        blockClasses: {
          bodyBlockClasses: ' w-[435px] !h-[113px]',
          appendBlockClasses: ' w-[436px]',
          whiteBlockContainerClasses: ' !right-[0px]',

          amountToShow: 4,
        },
      },
    ],
    images: [
      {
        type: 'image',
        image: 'https://cms.mosaic.and.together.agency/wp-content/uploads/2022/02/update.svg',
        className: 'top-[82px] left-[40px] sm:left-[904px] max-w-[330px]  w-full',
      },
    ],
  },
  {
    tabLabel: 'The Problem',
    textBubbles: [
      {
        isRef: true,
        content: 'Without a better solution...',
        className: 'sm:top-[36px] sm:left-[40px] left-[30px] top-[26px] h-[55px] sm:!h-[78px] sm:!w-[433px]',
      },
      {
        isRef: true,

        content: "Finance can't collaborate with the business or provide critical advice.",
        className: 'left-[100px] h-[109px] top-[107px] sm:top-[151px] sm:left-[148px] sm:!w-[973px] sm:!h-[77px]',
      },
      {
        isRef: true,

        content: 'Models are bespoke, fragile, and inaccurate.',
        className: 'h-[82px] sm:top-[265px] sm:left-[40px] left-[30px] top-[242px] sm:!h-[77px] sm:!w-[649px]',
      },
      {
        isRef: true,

        content: 'Teams spend hours manually pulling, updating, and distributing data.',
        className: 'h-[109px] sm:top-[379px] left-[100px] top-[350px] sm:left-[148px] sm:!h-[77px] sm:!w-[973px]',
      },
      {
        content: "Teams don't have a real-time understanding of the business.",
        className: 'h-[82px] sm:top-[493px] sm:left-[40px] left-[30px] top-[485px] sm:!h-[77px] sm:!w-[973px]',
      },
    ],
  },
  {
    textBubbles: [
      {
        content:
          'And that’s a huge problem. For high-growth companies, this could be the difference between success and failure.',
        className:
          '!w-[351px] h-[109px] sm:top-[113px] sm:left-[148px] top-[161px] left-[30px] sm:!w-[865px] sm:!h-[115px] ',
      },
    ],
  },
];

export const MastheadStrategicFinance = (props) => {
  const { textSection, bottomSection } = props;
  const { width, height } = useWindowSize();

  const { scrollY } = useViewportScroll();
  const [progress, setProgress] = useState(0);

  const overallProgressPercent = useTransform(scrollY, [800, 50000 - height], [0, 100]);

  useEffect(() => {
    scrollY.onChange(setProgress);
  }, [scrollY]);

  return (
    <div>
      <TextSection {...textSection} />

      <ApplicationWindow
        width={width}
        height={height}
        scrollY={scrollY}
        overallProgressPercent={overallProgressPercent}
      />
      <div className=" flex justify-center h-[1300px] relative bg-gradient-to-b from-[#249254] to-white ">
        <div className="sm:pt-[286px] pt-28 pb-20 sm:pb-[160px] w-full">
          <div className="sticky top-[42.5%] w-full ">
            <ProgressWindow
              overallProgressPercent={overallProgressPercent}
              progressStart={width < 728 ? 25.6 : 27.2}
              progressEnd={width < 728 ? 27.5 : 28}
              className="mx-auto"
              title="Update"
              loadingText="Strategic finance updating"
              buttonLabel="Updating..."
            />
          </div>
        </div>
      </div>

      <BottomSection {...bottomSection} />
    </div>
  );
};

function easeInQuad(x) {
  return x * x;
}

function easeOutQuad(x) {
  return 1 - (1 - x) * (1 - x);
}

export const ApplicationWindow = ({ scrollY, overallProgressPercent, width, height }) => {
  const ref = useRef(null);

  const [scroll, setScroll] = useState(0);

  if (width > 1440) {
    width = 1440;
  }

  const y = useTransform(scrollY, [0, 1000], [150, 0]);

  let scaleCalc;

  let innerHeight = width > 767 ? 853 : 716;

  //   useEffect(() => {
  //     window.addEventListener('scroll', () => {
  //       setScroll(window.scrollY);
  //   }, []);

  //   const easeStartDistance = 60;
  //   const endBeginEasingAt = 0.3;

  //   const easeEndDistance = 50;
  //   const startEndEasingAt = 0.7;

  //   const startY = 500;
  //   const endY = 800;

  //   let progress;

  //   console.log(scroll);
  //   if (scroll > endY) {
  //     progress = 1;
  //   } else if (scroll < startY) {
  //     progress = 0;
  //   } else {
  //     progress = (scroll - startY) / (endY - startY);
  //   }

  //   let stickyOffset;
  //   if (progress > startEndEasingAt) {
  //     let easeProgress = easeInQuad((progress - startEndEasingAt) / (1 - startEndEasingAt));
  //     stickyOffset = -easeEndDistance * easeProgress;
  //   } else if (progress < endBeginEasingAt) {
  //     let easeProgress = easeOutQuad(progress / endBeginEasingAt);
  //     // We add easeDistance here so the scroll starts offset when progress is 0
  //     stickyOffset = easeStartDistance + -easeStartDistance * easeProgress;
  //   }

  if (width > 639) {
    scaleCalc = width / 1346;

    if (height < 789 && width > 1346) {
      scaleCalc *= 0.725;
    }
    if (height > 1024 && width < 821) {
      scaleCalc *= 0.6;
    }
  }

  if (width > 472 && width < 640) {
    scaleCalc = width / 472;

    scaleCalc *= 1;

    if (height < 789 && width > 499) {
      scaleCalc *= 0.7;
    }
    if (height < 789 && width < 499) {
      scaleCalc *= 0.8;
    }
  }

  if (width < 391) {
    scaleCalc = width / 390;

    if (height > 700 && height < 845) {
      scaleCalc *= 0.9;
    }
    if (height < 700) {
      scaleCalc *= 0.8;
    }
  }

  return (
    <motion.div className="relative w-full h-[13000px] bg-[#249254]">
      <div
        // style={{ transform: `translateY(${stickyOffset}px)`, willChange: 'transform' }}
        // ref={ref}
        className=" top-[4.5rem] sm:top-[7rem] flex items-center justify-center w-full sticky  "
      >
        <div className="relative ">
          <div
            ref={ref}
            style={{
              marginTop: (innerHeight * (scaleCalc - 1)) / 2,
              marginBottom: (innerHeight * (scaleCalc - 1)) / 2,
              transform: `scale(${scaleCalc})`,
            }}
            className="mx-auto w-[390px] sm:w-[1346px] app-grid-container  max-h-[716px]  sm:max-h-[853px] bg-white overflow-hidden relative shadow-largeAsset"
          >
            <ApplicationWindowHeader />

            <ApplicationWindowGrid overallProgressPercent={overallProgressPercent} />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export const BottomSection = ({ content, headingStart, headingWords, headingEnd, image, subheading }) => {
  return (
    <div className=" pt-5 sm:pt-[100px] pb-4 sm:pb-16 bg-white">
      <div className="container flex flex-col items-center justify-center">
        <div className="max-w-[897px] mx-auto text-center">
          <h5 className="t-28 !font-normal ">{subheading}</h5>
          <h2 className="mt-4 t-66 ">
            <div>
              <span>{headingStart} </span>
              <AnimatedHighlight delay={0.5} color="bg-primaryBlue" startWidth="0%" variantStyle="keyframe">
                data,
              </AnimatedHighlight>
              <span className="inline-block mr-[0.25em]"> </span>
              automate
              <AnimatedHighlight
                delay={1.2}
                color="bg-primaryBlue"
                endWidth="108%"
                startWidth="0%"
                variantStyle="keyframe"
              >
                analysis,
              </AnimatedHighlight>
              <span className="inline-block mr-[0.25em]"> </span>
              accelerate
              <span className="inline-block mr-[0.25em]"> </span>
              <AnimatedHighlight delay={1.8} color="bg-primaryBlue" endWidth="107%" startWidth="0%" duration={1}>
                planning
              </AnimatedHighlight>
              {/* {headingEnd} */}
            </div>
          </h2>

          <div className="mt-10 t-16">{content}</div>
        </div>
        <div className="w-full mt-12 sm:mt-24">
          <div className="relative">
            <div className="absolute top-[-26px] left-[15px] sm:top-[-79px] sm:left-[80px]">
              <img className="max-w-[50px] sm:max-w-none" src={stratBg1} alt="" />
            </div>
            <div className="absolute right-[17px] bottom-[-22px] sm:bottom-[-74px] sm:right-[71px]">
              <img className="max-w-[77px] sm:max-w-none" src={stratBg2} alt="" />
            </div>
            <MediaAssetImage image={image} className="relative shadow-largeAsset" />
          </div>
        </div>
      </div>
    </div>
  );
};

export const TextSection = ({ headingWords, headingStart, button, imageLeft, imageRight }) => {
  const ease = [0.04, 0.62, 0.23, 0.98];

  return (
    <div className="relative bg-[#249254]">
      <div className="absolute top-[38px] sm:top-[96px] right-[0]">
        <MediaAssetImage image={imageRight} />
      </div>
      <div className="absolute bottom-0 left-[0] hidden sm:block">
        <MediaAssetImage image={imageLeft} />
      </div>
      <div className="container relative pt-32 sm:pt-[247px] sm:pb-[310px] pb-20 ">
        <div className="overflow-hidden">
          <motion.h1
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1.5, ease: ease }}
            className="mb-8 text-center text-white t-68 font-ibm"
          >
            <div>
              <span>{headingStart} </span>

              <AnimatedHighlight textEndColour="#ffffff" textStartColor="#ffffff">
                broken
              </AnimatedHighlight>
            </div>
          </motion.h1>
        </div>

        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1.5, ease: ease, delay: 0.4 }}
          className="sm:mt-[106px] mt-10 border-dark-grid bg-white px-[83px] max-w-[268px] mx-auto t-33 text-center py-[17px] "
        >
          {button}
        </motion.div>
      </div>
    </div>
  );
};

const AnimatedHighlight = ({
  children,
  className = '',
  color = 'bg-[#25AB60]',
  delay = 0.9,
  duration = 2,
  opacityDelay = delay - 0.05,
  textStartColor = '#1A2024',
  textEndColour = '#ffffff',
  startWidth = '10%',
  endWidth = '111%',
  variantStyle = 'default',
}) => {
  const keyframeVariant = {
    initial: {
      width: [startWidth, startWidth, startWidth],
      opacity: [0, 0],
    },
    animate: {
      width: [startWidth, endWidth, startWidth],
      opacity: [0, 1],
      transitionEnd: { opacity: 0 },
    },
  };

  const defaultVariant = {
    initial: {
      width: startWidth,
      opacity: 0,
    },
    animate: {
      width: endWidth,
      opacity: 1,
    },
  };

  const keyframeTextVariant = {
    initial: {
      color: [textStartColor, , textStartColor, textStartColor],
    },
    animate: {
      color: [textStartColor, textEndColour, textStartColor],
    },
  };
  const deafultTextVariant = {
    initial: {
      color: textStartColor,
    },
    animate: {
      color: textEndColour,
    },
  };

  let variantType;
  let textVariantType;

  if (variantStyle === 'keyframe') {
    variantType = keyframeVariant;
    textVariantType = keyframeTextVariant;
  } else {
    variantType = defaultVariant;
    textVariantType = deafultTextVariant;
  }

  return (
    <span className={`inline-block relative  ${className}`}>
      <motion.span
        variants={variantType}
        initial="initial"
        whileInView="animate"
        viewport={{ once: true }}
        transition={{
          duration: duration,
          ease: [0.04, 0.62, 0.23, 0.98],
          delay: delay,
          opacity: { delay: opacityDelay },
        }}
        className={`cursor-select-cta ${color}   absolute top-0 left-[-0.2em] bottom-[-0.05em] right-0 `}
      ></motion.span>
      <motion.span
        variants={textVariantType}
        initial="initial"
        whileInView="animate"
        transition={{ delay: delay, duration: 1.8 }}
        viewport={{ once: true }}
        className="relative inline-block"
      >
        {children}
      </motion.span>
      {/* <span className="inline-block relative my-[-0.75em]">&nbsp;</span> */}
    </span>
  );
};

export const ApplicationWindowGrid = ({ overallProgressPercent }) => {
  const rows = [
    '',
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
  ];
  const cols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O'];

  //this controls the fading between pages

  let opacityDuration = 0.2;

  const page1Offset = 6;
  const page2Offset = 12;
  const page3Offset = 16;
  const page4Offset = 23;

  const pageProgressPercent0 = useTransform(
    overallProgressPercent,
    [0, opacityDuration, page1Offset - opacityDuration, page1Offset],
    [0, 1, 1, 0]
  );
  const pageProgressPercent1 = useTransform(
    overallProgressPercent,
    [page1Offset, page1Offset + opacityDuration, page2Offset - opacityDuration, page2Offset],
    [0, 1, 1, 0]
  );
  const pageProgressPercent2 = useTransform(
    overallProgressPercent,
    [page2Offset, page2Offset + opacityDuration, page3Offset - opacityDuration, page3Offset],
    [0, 1, 1, 0]
  );
  const pageProgressPercent3 = useTransform(
    overallProgressPercent,
    [page3Offset, page3Offset + opacityDuration, page4Offset - opacityDuration, page4Offset],
    [0, 1, 1, 0]
  );
  const pageProgressPercent4 = useTransform(
    overallProgressPercent,
    [page4Offset, page4Offset + opacityDuration],
    [0, 1]
  );

  const containerVariants = {
    initial: {},
    animate: {
      transition: {
        when: 'beforeChildren',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const imageVariants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      opacity: 1,
      y: 0,
    },
  };

  const scrollProgress = useTransform(overallProgressPercent, [0, 24.2], [0, 449]);
  return (
    <div className="relative sm:w-[1346px] h-[680px] overflow-hidden">
      {rows.map((row, i) => {
        return (
          <div key={i} className="border-[#E5E7EB] border-b flex text-center text-[#CDCDCF] text-[12px] sm:text-[14px]">
            <div className="table-cell w-[30px] sm:w-[40px] py-1 sm:py-2">{row}</div>
            {cols.map((col, q) => (
              <div className="table-cell w-[70px] sm:w-[108px] py-1 sm:py-2 border-[#E5E7EB] border-l" key={q}>
                {i === 0 ? col : ''}
              </div>
            ))}
          </div>
        );
      })}

      {pages?.map((page, pageIndex) => {
        const pageProgress =
          pageIndex === 0
            ? pageProgressPercent0
            : pageIndex === 1
            ? pageProgressPercent1
            : pageIndex === 2
            ? pageProgressPercent2
            : pageIndex === 3
            ? pageProgressPercent3
            : pageProgressPercent4;

        return (
          <motion.div style={{ opacity: pageProgress }}>
            {page?.textBubbles?.map((text, i, arr) => {
              return (
                <TextBubble
                  key={`text${i}`}
                  blockType={text?.blockType}
                  blockClasses={text?.blockClasses}
                  text={text}
                  textIndex={i}
                  isRef={text?.isRef}
                  overallProgressPercent={overallProgressPercent}
                  progressStart={
                    pageIndex === 0
                      ? 0
                      : pageIndex === 1
                      ? page1Offset
                      : pageIndex === 2
                      ? page2Offset
                      : pageIndex === 3
                      ? page3Offset
                      : page4Offset
                  }
                  currentPage={pageIndex}
                />
              );
            })}

            {page.autoImage !== true &&
              page?.images?.map((image, imageIndex, arr) => {
                return (
                  <ImageBubble
                    key={`image${pageIndex}${imageIndex}`}
                    {...image}
                    image={image}
                    imageIndex={imageIndex}
                    pageIndex={pageIndex}
                    pageProgress={pageProgress}
                    overallProgressPercent={overallProgressPercent}
                  />
                );
              })}

            <motion.div
              className="autoimages"
              initial="initial"
              animate={
                overallProgressPercent.current > 4 && overallProgressPercent.current < 10 ? `animate` : 'initial'
              }
              variants={containerVariants}
            >
              {page.autoImage === true &&
                page?.images?.map((image, imageIndex, arr) => {
                  return (
                    <motion.img
                      key={'auto' + pageIndex + imageIndex}
                      variants={imageVariants}
                      className={`absolute ${image?.className}`}
                      src={image?.image}
                    ></motion.img>
                  );
                })}
            </motion.div>
          </motion.div>
        );
      })}
      <div className="absolute bottom-0 left-0 right-0 w-full">
        <ApplicationWindowFooter
          pages={pages}
          pageProgressPercent0={pageProgressPercent0}
          pageProgressPercent1={pageProgressPercent1}
          pageProgressPercent2={pageProgressPercent2}
          pageProgressPercent3={pageProgressPercent3}
          pageProgressPercent4={pageProgressPercent4}
          overallProgressPercent={overallProgressPercent}
        />
      </div>
      <div className="absolute hidden sm:block right-[20px] top-4 w-[27px] bg-[#EEF1F7] h-full max-h-[601px] border-[1.7px] border-[#E5E7EB] ">
        <Scrollbar scrollProgress={scrollProgress} />
      </div>
    </div>
  );
};

const Scrollbar = ({ scrollProgress }) => (
  <motion.div
    style={{ y: scrollProgress }}
    className="w-[19px] scroll-progress bg-white h-[144px] mx-auto z-[10] border border-[#E5E7EB] flex items-center "
  >
    <svg className="ml-[3px]" width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="-0.000488281" y1="0.553797" x2="9.99951" y2="0.553797" stroke="#5C6066" stroke-width="0.892405" />
      <line x1="-0.000488281" y1="3.5538" x2="9.99951" y2="3.5538" stroke="#5C6066" stroke-width="0.892405" />
      <line x1="-0.000488281" y1="6.5538" x2="9.99951" y2="6.5538" stroke="#5C6066" stroke-width="0.892405" />
    </svg>
  </motion.div>
);

export const ImageBubble = ({ pageIndex, overallProgressPercent, imageIndex, image, type }) => {
  let startOffset;
  switch (pageIndex) {
    case 0:
      startOffset = 3 + imageIndex;
      break;
    case 1:
      startOffset = 8 + imageIndex;
      break;
    case 2:
      startOffset = 14 + imageIndex;
      break;
    case 3:
      startOffset = 22 + imageIndex;
      break;
    default:
      startOffset = 8.3 + imageIndex;
  }

  const imageProgress = useTransform(overallProgressPercent, [startOffset, startOffset + 0.5], [0, 1]);

  return type === 'progressWindow' ? (
    <motion.div
      key={pageIndex + imageIndex}
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: imageProgress.current === 1 ? 1 : 0, y: imageProgress.current === 1 ? 0 : 100 }}
      className={`absolute ${image?.className}`}
    >
      <ProgressWindow
        key={`topWindow`}
        overallProgressPercent={overallProgressPercent}
        progressStart={9 + imageIndex}
        progressEnd={9 + imageIndex + 2}
      />
    </motion.div>
  ) : (
    <motion.img
      key={pageIndex + imageIndex}
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: imageProgress.current === 1 ? 1 : 0, y: imageProgress.current === 1 ? 0 : 100 }}
      className={`absolute ${image?.className}`}
      src={image?.image}
    ></motion.img>
  );
};

export const TextBubble = ({
  text,
  textIndex,
  currentPage,
  overallProgressPercent,
  progressStart,
  blockType,
  blockClasses,
  isRef,
}) => {
  let offset = textIndex;

  //   const letterProgress = useTransform(
  //     overallProgressPercent,
  //     [progressStart + offset, offset + progressStart + textIndex],
  //     [0, 1]
  //   );
  //   const refProgress = useTransform(
  //     overallProgressPercent,
  //     [progressStart + offset, offset + progressStart + 3.4],
  //     [0, text?.content?.length + 15]
  //   );

  let startOffset;
  switch (currentPage) {
    case 0:
      startOffset = 0 + textIndex;
      break;
    case 1:
      startOffset = 5 + textIndex;
      break;
    case 2:
      startOffset = 11 + textIndex;
      break;
    case 3:
      startOffset = 15 + textIndex;
      break;
    case 4:
      startOffset = 22 + textIndex;
      break;
    default:
      startOffset = 8.3 + textIndex;
  }

  const textProgress = useTransform(overallProgressPercent, [startOffset, startOffset + 1], [0, 1]);
  const bgProgress = useTransform(overallProgressPercent, [startOffset, startOffset + 2], [0, 1]);
  const refProgress = useTransform(overallProgressPercent, [startOffset, startOffset + 3], [0, 1]);

  let showBG = bgProgress.current < 1;
  let showRef = refProgress.current === 1;

  let borderClasses = showBG ? 'border-primaryBlue  ' : ' border-l-[#E5E7EB] border-[transparent]';
  let bgClasses = showBG ? 'bg-[#FAFCFF]  ' : '  bg-white';

  return (
    <motion.div
      key={currentPage + textIndex}
      initial={{ opacity: 0 }}
      animate={{ opacity: textProgress.current === 1 ? 1 : 0 }}
      transition={{ duration: 0.2 }}
    >
      <motion.div
        style={text?.style}
        className={`table-writing-cell transition  ${
          blockType !== 'custom' && showBG
            ? `bg-[#FAFCFF] border border-primaryBlue    opacity-1`
            : `bg-white border   opacity-1 ${isRef ? 'border-[#FF7557]' : 'border-[#E5E7EB]'}`
        }  ${textProgress.current === 0 ? 'hidden' : 'block'}  ${
          blockType === 'custom' && showBG && 'bg-[#FAFCFF] border border-primaryBlue sm:border-[transparent] '
        }   ${text?.className} `}
      >
        <div className="absolute inset-0">
          <div className="relative block w-full h-full sm:hidden">
            <div className={`absolute bottom-[10px] right-[10px] ${showBG ? 'opacity-1' : 'opacity-0'} `}>
              <TextTriangle />
            </div>
          </div>
        </div>
        {blockType === 'custom' && (
          <>
            <div
              className={`hidden sm:block absolute top-0 left-[-1px] bottom-[90px] border  border-r-0 ${
                showBG ? '' : ''
              }  h-[114px] ${blockClasses.bodyBlockClasses} ${bgClasses} ${borderClasses}   `}
            >
              <div className="relative inset-0 w-full h-full">
                <div className={`absolute bottom-[10px] right-[10px] ${showBG ? 'opacity-1' : 'opacity-0'} `}>
                  <TextTriangle />
                </div>
              </div>
            </div>
            <div
              className={`hidden sm:block absolute top-0 right-0  border-t border-r h-[74px] ${
                showBG ? 'border-b-[#E5E7EB]' : ''
              } ${blockClasses.appendBlockClasses}  ${bgClasses} ${borderClasses}     `}
            ></div>

            <div
              className={` hidden  right-[-1px] absolute h-[38px] top-[75px] sm:inline-block ${blockClasses.whiteBlockContainerClasses} `}
            >
              {[...Array(blockClasses.amountToShow)].map((un, i) => {
                return (
                  <div
                    key={i}
                    className={`w-[108px] bg-white inline-block border border-b-0   h-[38px] ${
                      showBG ? 'border-t-primaryBlue' : 'border-t-[#E5E7EB]'
                    } border-r-[#E5E7EB] border-t ${blockClasses.whiteBlockClasses}  ${
                      i === 0 ? borderClasses : 'border-l-0'
                    } `}
                  ></div>
                );
              })}
            </div>
          </>
        )}
        {blockType !== 'custom' && (
          <div className={`absolute  bottom-[10px] right-[10px] ${showBG ? 'opacity-1' : 'opacity-0'} `}>
            <TextTriangle />
          </div>
        )}

        {!showBG && showRef && isRef ? '#REF' : <span className="relative">{text?.content}</span>}
      </motion.div>
    </motion.div>
  );
};

export const TextTriangle = () => (
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.7163 0.833984V10.834H0.716309L10.7163 0.833984Z"
      fill="#111111"
    />
  </svg>
);

export const ApplicationWindowHeader = () => (
  <div>
    <div className="bg-[#249254] px-3 py-2 sm:p-3">
      <div className="w-[160px] sm:w-full flex justify-between">
        <ApplicationWindowOptions />
        <div className="hidden sm:block">
          <ApplicationWindowSearch />
        </div>
      </div>
      <div className="justify-between hidden pt-6 sm:flex">
        <ApplicationWindowBubblesLeft />
        <ApplicationWindowBubblesRight />
      </div>
    </div>
    <div className="border-b border-b-[#E5E7EB]">
      <ApplicationWindowA1Row />
    </div>
    <div className="hidden sm:block border-b border-b-[#E5E7EB] p-3 px-4">
      <ApplicationWindowFxRow />
    </div>
  </div>
);

export const ApplicationWindowFooter = ({
  pages,
  overallProgressPercent,
  progressStart,
  pageProgressPercent0,
  pageProgressPercent1,
  pageProgressPercent2,
  pageProgressPercent3,
  pageProgressPercent4,
}) => (
  <div className="text-[14px] sm:text-[16px] bg-white border-b border-t mt-[-1px] border-[#E5E7EB] px-3 sm:px-4 flex items-center">
    <div className="hidden px-3 sm:block">
      <LeftRightArrows />
    </div>

    {uniq(
      pages.map((page, i) => {
        const pageProgress =
          i === 0
            ? pageProgressPercent0
            : i === 1
            ? pageProgressPercent1
            : i === 2
            ? pageProgressPercent2
            : i === 3
            ? pageProgressPercent3
            : pageProgressPercent4;
        return (
          <motion.div key={`page${i}`} className="relative px-3 py-2 whitespace-pre sm:py-3 sm:px-3">
            {i !== pages.length - 1 && (
              <motion.div
                style={{ opacity: pageProgress }}
                className="absolute inset-0 w-full h-full bg-[#EEF1F7] rounded-t border border-[#E5E7EB]"
              ></motion.div>
            )}
            <span className="relative">{page.tabLabel}</span>
          </motion.div>
        );
      })
    )}
  </div>
);

export const ApplicationWindowOptions = () => (
  <svg width="266" height="30" viewBox="0 0 266 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="88.5634" y="1.05679" width="1.01983" height="27.7035" stroke="white" stroke-width="1.01983" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M228.014 19.1953L223.735 11.7065H232.293L228.014 19.1953Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M204.238 16.6075C204.726 17.1353 204.711 17.9163 204.231 18.4268L204.102 18.5485C203.545 19.0102 202.72 18.9964 202.182 18.5419L202.053 18.4191L197.536 13.5284L197.471 13.4526L197.388 13.3376L197.297 13.171C197.249 13.067 197.216 12.9588 197.196 12.8489C197.183 12.7752 197.177 12.6997 197.177 12.6226C197.177 12.5141 197.19 12.4085 197.215 12.3073L197.267 12.1458L197.365 11.945L197.426 11.8515L197.536 11.7169L202.053 6.82619C202.581 6.25448 203.498 6.19655 204.102 6.6968C204.659 7.15856 204.754 7.93452 204.35 8.50083L204.238 8.63774L201.827 11.2464L208.308 11.2471C214.272 11.2471 217.504 14.3083 217.504 19.9587C217.504 20.7183 216.854 21.3342 216.052 21.3342C215.25 21.3342 214.6 20.7183 214.6 19.9587C214.6 15.9424 212.774 14.1016 208.666 14.0024L208.308 13.9981L201.827 13.9974L204.238 16.6075Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M175.886 24.6064C177.088 24.6064 178.071 23.6174 178.071 22.4087V7.02417C178.071 5.81539 177.088 4.82639 175.886 4.82639H160.592C159.39 4.82639 158.407 5.81539 158.407 7.02417V21.3098L161.684 24.6064H175.886ZM172.608 23.5074H163.868V18.0129H172.608V23.5074ZM167.147 22.4083V19.1116H164.962V22.4083H167.147ZM173.701 14.7167H162.776C162.121 14.7167 161.684 14.2772 161.684 13.6178V8.12338C161.684 7.46404 162.121 7.02449 162.776 7.02449H173.701C174.356 7.02449 174.793 7.46404 174.793 8.12338V13.6178C174.793 14.2772 174.356 14.7167 173.701 14.7167Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M256.539 7.23876C256.217 7.37876 256.02 7.68938 256.02 8.09892C256.02 8.40967 256.133 8.66346 256.329 8.82898L258.549 11.8661C258.83 12.2429 259.485 12.3371 259.86 12.0545C260.234 11.7719 260.328 11.2068 260.047 10.7358L259.055 9.37869C261.66 10.248 263.511 12.7008 263.511 15.6342C263.511 17.4238 262.856 19.025 261.639 20.3437C260.421 21.5682 258.736 22.2275 256.957 22.2275C253.305 22.2275 250.402 19.3076 250.402 15.6342C250.402 13.8446 251.057 12.2433 252.275 10.9246C252.649 10.5479 252.649 9.98273 252.275 9.60597C251.9 9.22921 251.338 9.22921 250.964 9.60597C249.372 11.2072 248.529 13.3736 248.529 15.6342C248.529 20.3437 252.275 24.1113 256.957 24.1113C259.204 24.1113 261.358 23.2636 262.95 21.6624C264.541 20.0611 265.384 17.8948 265.384 15.6342C265.384 11.762 262.852 8.52648 259.349 7.49841L260.702 6.49753C261.077 6.21496 261.171 5.55562 260.89 5.17886C260.609 4.80209 259.953 4.7079 259.579 4.99047L256.539 7.23876Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M134.65 24.6062H123.179C122.687 24.6062 122.36 24.2766 122.36 23.7821V16.3645H119.902C119.574 16.3645 119.246 16.1997 119.164 15.87C119 15.5404 119.082 15.2107 119.328 14.9634L128.341 5.07342C128.669 4.74375 129.242 4.74375 129.57 5.07342L138.583 14.9634C138.828 15.2107 138.828 15.5404 138.746 15.87C138.583 16.1997 138.255 16.3645 137.927 16.3645H135.469V23.7821C135.469 24.2766 135.141 24.6062 134.65 24.6062ZM123.998 22.9577H133.83V15.5402C133.83 15.0457 134.158 14.716 134.649 14.716H136.042L128.914 6.88644L121.786 14.716H123.178C123.67 14.716 123.998 15.0457 123.998 15.5402V22.9577Z"
      fill="white"
    />
    <circle cx="7.81576" cy="14.4547" r="7.48885" fill="white" />
    <circle cx="29.2125" cy="14.4547" r="7.48885" fill="white" />
    <circle cx="50.6092" cy="14.4547" r="7.48885" fill="white" />
  </svg>
);

export const ApplicationWindowSearch = () => (
  <svg width="272" height="37" viewBox="0 0 272 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.566895" width="271.275" height="36.93" fill="#2B9E5D" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.0516 22.0317L31.1169 26.1202C31.4961 26.5057 31.4961 27.129 31.1128 27.5145L29.9589 28.675C29.5797 29.0605 28.9599 29.0605 28.5766 28.675L24.5113 24.5865C24.3278 24.402 24.2259 24.1518 24.2259 23.8894V23.2209C22.7865 24.3528 20.9761 25.0253 19.0067 25.0253C14.3216 25.0253 10.5254 21.2074 10.5254 16.4956C10.5254 11.7837 14.3216 7.96582 19.0067 7.96582C23.6917 7.96582 27.4879 11.7837 27.4879 16.4956C27.4879 18.4763 26.8192 20.297 25.6938 21.7446H26.3584C26.6194 21.7446 26.8681 21.8471 27.0516 22.0317ZM13.7871 16.4954C13.7871 19.3988 16.1236 21.7445 19.0064 21.7445C21.8934 21.7445 24.2257 19.3947 24.2257 16.4954C24.2257 13.5921 21.8893 11.2464 19.0064 11.2464C16.1195 11.2464 13.7871 13.5962 13.7871 16.4954Z"
      fill="white"
    />
  </svg>
);

export const ApplicationWindowBubblesLeft = () => (
  <svg width="841" height="12" viewBox="0 0 841 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.396729" y="0.757812" width="77.5071" height="10.2584" rx="4.07932" fill="#2B9E5D" />
    <rect x="96.2604" y="0.757812" width="134.618" height="10.2583" rx="4.07932" fill="#2B9E5D" />
    <rect x="265.552" y="0.757812" width="50.9915" height="10.2583" rx="4.07932" fill="#2B9E5D" />
    <rect x="761.189" y="0.757812" width="79.5467" height="10.2583" rx="4.07932" fill="#2B9E5D" />
    <rect x="361.416" y="0.757812" width="97.9037" height="10.2583" rx="4.07932" fill="#2B9E5D" />
    <rect x="489.915" y="0.757812" width="87.7054" height="10.2583" rx="4.07932" fill="#2B9E5D" />
    <rect x="602.096" y="0.757812" width="124.419" height="10.2583" rx="4.07932" fill="#2B9E5D" />
  </svg>
);

export const ApplicationWindowBubblesRight = () => (
  <svg width="99" height="12" viewBox="0 0 99 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M96.5316 6.45C95.3695 4.1123 92.0347 4.1123 90.8727 6.45V6.45C89.8286 8.55045 91.3565 11.0162 93.7021 11.0162V11.0162C96.0478 11.0162 97.5756 8.55045 96.5316 6.45V6.45Z"
      fill="white"
    />
    <rect x="0.897705" y="0.757812" width="79.5467" height="10.2583" rx="4.07932" fill="#2B9E5D" />
  </svg>
);

export const ApplicationWindowA1Row = () => (
  <svg
    className="mb-[-1px]"
    width="281"
    height="38"
    viewBox="0 0 281 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="0.773438" width="72.7489" height="36.3744" fill="white" stroke="#E5E7EB" stroke-width="1.06984" />
    <path
      d="M22.2849 13.0576H20.6822L16.833 23.3922V23.542H18.1959L19.1845 20.8011H23.7677L24.7562 23.542H26.1491V23.3922L22.2849 13.0576ZM19.6039 19.6328L21.4461 14.5104H21.506L23.3483 19.6328H19.6039ZM29.7713 23.542H31.0444V13.0576H29.7564L27.1502 15.0796V16.3677H27.3L29.7114 14.4805H29.7713V23.542Z"
      fill="#5C6066"
    />
    <path
      d="M56.7276 20.9483C57.0566 20.6945 57.5518 20.7288 57.8338 21.0249C58.1017 21.3062 58.0807 21.7225 57.7959 21.9811L57.7487 22.0205L55.0028 24.1388C54.7263 24.3521 54.326 24.3647 54.0349 24.1764L53.9817 24.1388L51.2358 22.0205C50.9068 21.7667 50.8687 21.321 51.1507 21.0249C51.4186 20.7436 51.8789 20.6986 52.2064 20.9124L52.2569 20.9483L54.4923 22.6724L56.7276 20.9483Z"
      fill="#5C6066"
    />
    <path
      d="M56.7276 15.9013C57.0566 16.1551 57.5518 16.1208 57.8338 15.8247C58.1017 15.5434 58.0807 15.1271 57.7959 14.8685L57.7487 14.8291L55.0028 12.7108C54.7263 12.4975 54.326 12.485 54.0349 12.6732L53.9817 12.7108L51.2358 14.8291C50.9068 15.0829 50.8687 15.5286 51.1507 15.8247C51.4186 16.106 51.8789 16.151 52.2064 15.9372L52.2569 15.9013L54.4923 14.1772L56.7276 15.9013Z"
      fill="#5C6066"
    />
    <rect
      x="73.7487"
      y="0.773438"
      width="66.3299"
      height="36.3744"
      fill="white"
      stroke="#E5E7EB"
      stroke-width="1.06984"
    />
    <path
      d="M89.9821 13.7905L90.0307 13.8351L94.0754 17.8796L98.12 13.8351C98.4185 13.5367 98.9023 13.5367 99.2007 13.8351C99.4835 14.1179 99.4983 14.567 99.2454 14.8672L99.2007 14.9158L95.1563 18.9605L99.2007 23.0052C99.4992 23.3036 99.4992 23.7874 99.2007 24.0859C98.918 24.3686 98.4689 24.3835 98.1687 24.1305L98.12 24.0859L94.0754 20.0414L90.0307 24.0859C89.7323 24.3843 89.2484 24.3843 88.95 24.0859C88.6673 23.8032 88.6524 23.354 88.9054 23.0538L88.95 23.0052L92.9945 18.9605L88.95 14.9158C88.6516 14.6174 88.6516 14.1336 88.95 13.8351C89.217 13.5681 89.6325 13.54 89.9307 13.7508L89.9821 13.7905Z"
      fill="#5C6066"
    />
    <path
      d="M123.725 14.9603C123.978 14.6525 124.457 14.5902 124.795 14.821C125.116 15.0403 125.193 15.4454 124.983 15.7492L124.948 15.7963L119.053 22.9616C118.807 23.2605 118.35 23.327 118.016 23.1222L117.964 23.0876L114.689 20.6992C114.359 20.4588 114.306 20.0205 114.57 19.72C114.82 19.4346 115.267 19.3764 115.593 19.5773L115.644 19.6112L118.303 21.5505L123.725 14.9603Z"
      fill="#5C6066"
    />
    <line
      x1="162.01"
      y1="19.4949"
      x2="278.622"
      y2="19.4949"
      stroke="#F3F5F7"
      stroke-width="3.20951"
      stroke-linecap="round"
    />
  </svg>
);

export const ApplicationWindowFxRow = () => (
  <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.30222 12.0127C2.94842 12.3763 2.43737 12.5581 1.76908 12.5581H0.162231L0.294907 11.2461H2.24082L2.72729 6.45502H0.855093L1.00251 5.08404H2.85997L3.15481 2.25362C3.21377 1.67378 3.42016 1.20204 3.77396 0.838413C4.12776 0.474784 4.63881 0.292969 5.3071 0.292969H7.01714L6.86972 1.60498H4.83536L4.46682 5.08404H6.69282L6.56015 6.45502H4.33414L3.92138 10.6122C3.86241 11.1822 3.65602 11.649 3.30222 12.0127ZM11.1448 7.79602L9.30208 10.5822H7.59204L10.2898 6.70513L7.66575 2.9165H9.52321L11.248 5.58476H11.2922L13.0612 2.9165H14.7713L12.1472 6.69039L14.8155 10.5822H12.958L11.189 7.79602H11.1448Z"
      fill="#5C6066"
    />
  </svg>
);

export const LeftRightArrows = () => (
  <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.58257 10.835L0.394365 6.21397C0.261732 6.08975 0.187256 5.92152 0.187256 5.74614C0.187256 5.57076 0.261732 5.40253 0.394365 5.27832L5.58257 0.657573C6.02769 0.241759 6.79118 0.536489 6.79118 1.1254L6.79119 10.3672C6.79119 10.9566 6.03241 11.25 5.58257 10.835Z"
      fill="#5C6066"
    />
    <path
      d="M16.1885 0.657204L21.3768 5.27822C21.5094 5.40243 21.5839 5.57066 21.5839 5.74605C21.5839 5.92143 21.5094 6.08966 21.3768 6.21387L16.1885 10.8346C15.7434 11.2504 14.9799 10.9557 14.9799 10.3668L14.9799 1.12503C14.9799 0.53557 15.7387 0.242215 16.1885 0.657204Z"
      fill="#5C6066"
    />
  </svg>
);
