import React from 'react';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';

export const WorkflowConnection = ({ headingA, headingB, contentA, contentB, image, mobileMedia }) => {
  return (
    <div className="container space-y-6">
      <div className=''>
        <MediaAssetInner className="hidden md:block mx-auto" type="image" image={image} />
        <MediaAssetInner className="block md:hidden" {...mobileMedia} /> 
      </div>
      <div className="md:flex md:flex-row md:justify-between space-y-8 md:space-y-0 md:space-x-8 lg:space-x-10 text-center">
        <div className='space-y-2 md:space-y-6 w-full'>
          <h2 className="t-24">{headingA}</h2>
          <p className='max-w-[498px] mx-auto t-17 opacity-80' dangerouslySetInnerHTML={{ __html: contentA }}/>
        </div>
        <div className='space-y-2 md:space-y-6 w-full'>
          <h2 className="t-24">{headingB}</h2>
          <p className='max-w-[418px] mx-auto t-17 opacity-80' dangerouslySetInnerHTML={{ __html:  contentB }}/>
        </div>
      </div>
    </div>
  );
};