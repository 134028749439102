import React, { createContext, useContext, useEffect, useState } from 'react';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { MaybeLink, Button, ArrowLink } from '~/components/elements/Link';
import { TextContent } from '~/components/flexible/TextContent';
import { SearchIcon } from './Metrics';
import latestJobsData from '~/assets/data/latest-jobs.json';
import { AnimatePresence, motion } from 'framer-motion';

const contextDefaultValues = {
  filtered: '',
  handleFilter: (filter) => null,
};

export const GlobalContext = createContext(contextDefaultValues);
export const LatestJobs = (page) => {
  const [filtered, setFilter] = useState('');
  const handleFilter = (filter: string) => {
    setFilter(filter);
  };
  return (
    <div>
      <GlobalContext.Provider
        value={{
          filtered,
          handleFilter,
        }}
      >
        <LatestJobsHeader {...page.latestJobs} />
        <LatestJobsMain />
      </GlobalContext.Provider>
    </div>
  );
};

export const LatestJobsHeader = ({ subheading, heading, content, asset }) => {
  const { filtered, handleFilter } = useContext(GlobalContext);

  return (
    <InViewAnimator
      className="relative container section-header-padding"
      inner={(controls) => (
        <div className="relative flex  lg:items-center py-4 md:py-8 lg:py-16 xl:py-20 flex-col lg:flex-row-reverse">
          <InViewAnimatorBasicItem
            controls={controls}
            delay={0.1}
            prop="y"
            start={50}
            className=" mx-auto md:mr-0 lg:w-[58%] md:ml-[52px] mt-8 md:mt-16"
          >
            <MediaAssetInner {...asset} />
          </InViewAnimatorBasicItem>
          <InViewAnimatorBasicItem controls={controls} delay={0.4} prop="y" start={50} className="flex-1 mt-5 md:mt-0">
            <TextContent
              isMasthead={true}
              headingSize="54"
              centered={false}
              subheading={subheading}
              heading={heading}
              content={content}
              visualControls={{
                restrictWidth: {
                  content: 500,
                },
              }}
            />
            <div className="relative w-full mt-5 md:mt-[54px]">
              <div className="absolute left-[19px] top-[24px]">
                <SearchIcon />
              </div>
              <input
                className="input border border-strokeGrey h-[72px] w-full flex-auto t-20 pl-[62px] placeholder-black focus:placeholder-strokeGrey"
                placeholder="Search..."
                value={filtered}
                onChange={(e) => handleFilter(e.target.value)}
              ></input>
            </div>
          </InViewAnimatorBasicItem>
        </div>
      )}
    />
  );
};

export const LatestJobsMain = () => (
  <div className="container md:flex md:flex-row-reverse">
    <div className="flex-1 lg:ml-12 xl:ml-28">
      <LatestJobsResults />
    </div>
    <div className="hidden w-[306px] lg:block">
      <LatestJobsSidebar />
    </div>
  </div>
);

export const LatestJobsSidebar = () => {
  const related = [
    { title: 'Tracking complex metrics by customer segment', url: '#' },
    { title: 'Tracking complex metrics by customer segment', url: '#' },
    { title: 'Tracking complex metrics by customer segment', url: '#' },
    { title: 'Tracking complex metrics by customer segment', url: '#' },
  ];
  const social = [
    { icon: false, link: { title: 'Facebook', url: '#' } },
    { icon: false, link: { title: 'Facebook', url: '#' } },
    { icon: false, link: { title: 'Facebook', url: '#' } },
    { icon: false, link: { title: 'Facebook', url: '#' } },
    { icon: false, link: { title: 'Facebook', url: '#' } },
  ];
  const listingCount = 14;
  return (
    <div className="bg-fadedBlue p-6">
      <h6 className="t-20-heading">New job listings:</h6>
      <div className="t-66 pt-2 pb-4">{listingCount}</div>
      <h6 className="uppercase font-medium t-12 relative">
        <div className="absolute h-[1px] top-[6px] left-0 right-0 bg-black opacity-10" />
        <span className="relative bg-fadedBlue inline-block pr-3">Related Resources</span>
      </h6>
      <ul className="t-15 pt-2">
        {related.map((related, i) => (
          <li key={i}>
            <a className="text-primaryBlue" href={related.url}>
              {related.title}
            </a>
          </li>
        ))}
      </ul>
      <h6 className="t-15 pt-8 pb-1">Share on social media</h6>
      <ul className="flex space-x-4 pb-8">
        {social.map(({ link, icon }, i) => (
          <li key={i}>
            <a className="inline-block w-[15px]" href={link.url}>
              <MediaAssetInner />
            </a>
          </li>
        ))}
      </ul>
      <Button buttonStyle="primary" link={{ title: 'Subscribe to our newsletter', url: '#' }} />
    </div>
  );
};

export const LatestJobsResults = () => {
  const allLocations = [...new Set(latestJobsData?.map((job) => job.location_name))];
  const allCompany = [...new Set(latestJobsData?.map((job) => job.organization_name))];
  const allJobTypes = [...new Set(latestJobsData?.map((job) => job.job_title))];
  const allDeadline = [...new Set(latestJobsData?.map((job) => job.date))];
  const allDatePosted = [...new Set(latestJobsData?.map((job) => job.date))];
  const [location, setLocation] = useState('');
  const [company, setCompany] = useState('');
  const [jobTypes, setJobTypes] = useState('');
  const [deadline, setDeadline] = useState('');
  const [datePosted, setDatePosted] = useState('');
  const [filteredLocations, setFilteredLocations] = useState(allLocations);
  const [filteredJobTypes, setFilteredJobTypes] = useState(allJobTypes);
  const [filteredCompany, setFilteredCompany] = useState(allCompany);
  const [filteredDeadline, setFilteredDeadline] = useState(allDeadline);
  const [filteredDatePosted, setFilteredDatePosted] = useState(allDatePosted);
  const initialLoadMore = 5;
  const [loadMore, setLoadMore] = useState(initialLoadMore);
  const [searchItems, setSearchItems] = useState(latestJobsData);

  const { filtered } = useContext(GlobalContext);
  useEffect(() => {
    if (filtered) {
      let newArray = latestJobsData?.filter((e) =>
        e.job_description?.toLowerCase().includes(filtered.toLocaleLowerCase())
      );
      setSearchItems(newArray);
    }
    if (!filtered) {
      setSearchItems(latestJobsData);
    }
  }, [filtered]);

  useEffect(() => {
    setSearchItems(
      latestJobsData?.filter((job) => {
        setLoadMore(initialLoadMore);
        if (!company && !location && !jobTypes && !deadline && !datePosted) return true;
        const cMatch = company ? job.organization_name === company : false;
        const lMatch = location ? job.location_name === location : false;
        const jMatch = jobTypes ? job.job_title === jobTypes : false;
        const dMatch = deadline ? job.date === deadline : false;
        const dateMatch = datePosted ? job.date === datePosted : false;
        if (company && location && jMatch && dMatch && dateMatch)
          return company && location && jMatch && dMatch && dateMatch;
        if (company) return cMatch;
        if (location) return lMatch;
        if (jobTypes) return jMatch;
        if (deadline) return dMatch;
        if (datePosted) return dateMatch;
      })
    );
  }, [company, location, jobTypes, deadline, datePosted]);

  return (
    <div>
      <div>
        <h3 className="t-24 text-secondaryText">Filter by:</h3>
        <div className="flex flex-row max-w-[569px] mt-6">
          <Select
            onChange={(e) => setDatePosted(e.target.value)}
            items={filteredDatePosted}
            defaultOption="Date posted"
          />
          <Select onChange={(e) => setLocation(e.target.value)} items={filteredLocations} defaultOption="Location" />
          <Select onChange={(e) => setCompany(e.target.value)} items={filteredCompany} defaultOption="Company" />
          <Select onChange={(e) => setJobTypes(e.target.value)} items={filteredJobTypes} defaultOption="Job types" />
          <Select onChange={(e) => setDeadline(e.target.value)} items={filteredDeadline} defaultOption="Deadlines" />
        </div>
      </div>
      <div className="pt-6">
        <AnimatePresence exitBeforeEnter={true}>
          {searchItems?.slice(0, initialLoadMore).map((result, i) => (
            <motion.div
              key={result.job_id + i}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { duration: 0.35, delay: (i * 0.1) % (initialLoadMore * 0.1) },
              }}
              exit={{ opacity: 0, transition: { duration: 0.2 } }}
            >
              <LatestJobsResult key={i} {...result} />
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
};

const Select = ({ items, defaultOption, ...other }) => {
  return (
    <select {...other} className="t-16 font-medium pr-1 max-w-[105px]">
      {defaultOption && <option value="">{defaultOption}</option>}
      {items.map((item, i) => (
        <option key={i}>{item}</option>
      ))}
    </select>
  );
};

export const LatestJobsResult = ({ job_title, location_name, date, job_description, isPromoted }) => (
  <MaybeLink
    link={{ url: '#' }}
    className={`py-9 pl-[30px] pr-[35px] ${
      isPromoted ? 'border-default-grid bg-tertiaryGrey' : 'border-b border-b-strokeGrey'
    }`}
  >
    {isPromoted && (
      <div className="t-11 font-semibold uppercase mb-[15px]">
        <h5 className="inline-block px-[9px] py-[7px] text-white bg-primaryText">Latest</h5>
        <h5 className="ml-4 inline-block px-[9px] py-[7px] bg-fadedGreen">Promoted</h5>
      </div>
    )}
    <div className="t-17 flex justify-between pb-6">
      <div>
        <h2 className="t-24">{job_title}</h2>
        <span className="block pt-4">{location_name}</span>
      </div>
      <div className="text-right pt-1">
        <ArrowLink link={{ title: 'Apply' }} />
        <div className="pt-5 flex flex-row">
          <div className="mr-4">
            <BriefCase />
          </div>
          <span className="mr-2">Deadline:</span> {date}
        </div>
      </div>
    </div>
    {job_description && (
      <p className="t-17 max-w-[570px]">{job_description?.split(' ').slice(0, 22).join(' ') + '...'}</p>
    )}
  </MaybeLink>
);

export const BriefCase = () => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.25 5.25V1.5h7.5v3.75" stroke="#181A3D" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M2.25 5.25h19.5a1.5 1.5 0 0 1 1.5 1.5v7.5H.75v-7.5a1.5 1.5 0 0 1 1.5-1.5Z"
      fill="#88E8FF"
      stroke="#88E8FF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.75 17.25v3a1.5 1.5 0 0 1-1.5 1.5H3.75a1.5 1.5 0 0 1-1.5-1.5v-3"
      stroke="#181A3D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
